<template>
  <div class="JournalismInfo">
    <isFixedHeader></isFixedHeader>
    <div class="body" style="    padding-bottom: 50px;    padding-top: 153px;">
      <div class="top">
        <span style="cursor: pointer;" @click="$router.push(`/home`)">首页</span> > <span style="cursor: pointer;" @click="$router.push(`/journalism`)">新闻动态</span> > <span style="cursor: pointer;" @click="$router.push(`/journalism?id=dynamic`)">企业动态</span>
      </div>
      <div class="title">{{ obj.title }}</div>
      <div class="date">{{ obj.date }}</div>
      <div class="line"></div>
      <div class="list" v-for="(item, index) in obj.list" :key="index">
        <div class="text" v-if="item.text">
          {{ item.text }}

        </div>
        <h5 v-else-if="item.tilte" style="text-align: center;margin-top: 0;font-size: 14px;font-weight: 400;    color: #707070;">
          {{ item.tilte }}

        </h5>
        <div v-else-if="item.smallTile" style="text-indent: 33px;font-size: 16px;font-weight: 700;margin-bottom: 20px;line-height: 30px;">
          {{ item.smallTile }}

        </div>
        <img :src="item.Imag" alt="" style="width: 60%;margin: 20px 230px;" v-else>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!-- smallTile -->
<script>
import infoList from '@/utils/data'
import Footer from '@/views/footer.vue'
import isFixedHeader from '@/views/isFixedHeader.vue'
export default {
  components: {
    isFixedHeader,
    Footer
  },
  data() {
    return {
      obj: {
        title: '播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行',
        date: '发布时间:2022-06-01',
        list: []
      }
    }
  },
  created() {
    this.obj = infoList[this.$route.query.id]

  },
  mounted() {
    document.documentElement.scrollTop = 0
  }
}
</script>

<style  scoped lang="scss">
.top {
  color: #2e59a7;
  margin-bottom: 40px;
  font-size: 14px;
}

.title {
  font-size: 30px;
  text-align: center;
}

.date {
  color: #707070;
  margin: 40px 0 40px 0;
  font-size: 14px;
  text-align: center;
}

.line {
  height: 1px;
  background-color: #ececec;
  margin: 20px 0 20px 0;
}

.text {
  line-height: 30px;
  font-size: 16px;
  color: #707070;
  margin-bottom: 20px;
  text-indent: 33px;
}
</style>
