let url = 'https://static.drlianzhuren.com/brandWeb/'
export default [{
    title: '碧莲盛植发技术再升级，无痛不剃发植发正式亮相',
    date: '发布时间：2023-08-28',
    list: [{
        text: '“医生，植发到底痛不痛？”“我怎么能既保住头发，又能无痛？”很多患者在浏览网页和针对植发的线上和线下咨询时，留下这一疑问。',
      },
      {
        text: '8月28日，由碧莲盛医疗集团（下称“碧莲盛”）主办，广东时代传媒集团、头部美学研究院承办的「植发技术蝶变 引领头部美学」碧莲盛医疗集团技术战略升级发布会在京举办。此次发布会，碧莲盛对技术、价格、服务三方面进行升级迭代，旨在为消费者在植发过程中遭遇的一系列痛点提供全方位的解决方案。',
      },
      {
        Imag: `${url}/JournalismInfo/14/1.png`
      },
      {
        text: '在发布会现场，碧莲盛宣布将全面推行不剃发技术，逐步淘汰传统剃发；其次全面推行疼痛管理技术，减少或避免患者的疼痛，而碧莲盛的不剃发国家发明专利以及医生团队也将保障植发手术的安全及效果。此外，在价格和服务体系上，碧莲盛亦推出了针对不同群体与需求的个性化定制方案。',
      },
      {
        text: '“创新，是企业高质量发展的动力源，也是满足人民群众对美好生活向往的基石。”碧莲盛创始人尤丽娜在发布会现场表示。',
      },
      {
        Imag: `${url}/JournalismInfo/14/2.png`
      },
      {
        text: '碧莲盛医疗集团副总经理师晓炯在发布会现场称，植发技术升级的驱动力是消费者对快速变美、长头发的追求，同时不剃发技术也代表了目前植发技术的最高水平。不剃发技术一定会全面取代剃发技术，会成为未来植发技术的发展趋势。',
      },
      {
        smallTile: '技术迭代，碧莲盛创新速度不减',
      },
      {
        text: '碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。目前，碧莲盛共拥有40余家全国直营的连锁分院、100余位执业医生和700余位执业护士，其中，主任和副主任医师10余位，为至少35万发友解决了脱发困扰。',
      },
      {
        text: '成立18年来，碧莲盛的创新从未停止。',
      },
      {
        text: '据师晓炯介绍，简单来说，植发技术可以分成两个代际，第一代为FUT，毛囊单位头皮条切取术，即伤敌800自损8000；第二代叫做FUE，即毛囊单位钻取术。而FUE又分成两个不同的技术，即传统剃发技术和不剃发技术。',
      },
      {
        text: '成立之初，碧莲盛就将在全球市场仅面世三年的FUE引进国内，全面摒弃了FUT有痕植发。',
      },
      {
        text: 'FUE在2002年由美国Rassman博士提出，这种技术无需从供体区移植皮瓣或者使用缝合技术，其术后恢复时间也较短，且避免了区域性的疤痕存在。',
      },
      {
        text: '之所以会引进这样的革新技术，尤丽娜在接受媒体采访时曾表示，以往的植发不仅仅要剃发，还要从后枕部切割一块头皮，通过在显微镜下分离的方式为单个毛囊单位移植，再种植到秃发区。这种手术意味着恢复时间长、风险大，而且还有疤痕。',
      },
      {
        text: '尤丽娜在接受多家媒体采访时也表示，随着发友们为了美的追求咨询能不能不剃发、少剃发增多，以及商务人士询问植发能不能不影响开会等因素，她下定决心要把以前最难且不可想象的不剃发植发技术攻克。',
      },
      {
        text: '2015年，不剃发技术开始萌芽。彼时，碧莲盛从最短的头发开始研究不剃发技术。从将后枕部的头发延长取出再种植至前额区域，到完全不剃发提取并种植，碧莲盛潜心钻研，历时近6年。2020年8月，该项技术被正式推出。',
      },
      {
        text: '长期的临床实践积累让碧莲盛成为国内毛发医疗行业的领军企业。截止2023年8月，碧莲盛不剃发手术台数累计突破5万台。',
      },
      {
        Imag: `${url}/JournalismInfo/14/3.png`
      },
      {
        text: '为了满足发友们日益增长的需求，碧莲盛的革新还在继续。师晓炯在发布会现场表示，碧莲盛从第一次全面提出无痕植发再到今天全面贯彻不剃发植发，一直秉持着为发友的初心，并做着难而正确的事情。',
      },
      {
        text: '“由于传统剃发技术会导致发友们出现尴尬期、恢复慢；创面大、损伤大；鸡皮疙瘩或点状疤痕；不适合艺术种植等四大缺点，我们会淘汰传统剃发技术，全面推行不剃发技术。”师晓炯解释道，同时碧莲盛将全面贯彻无痛技术。传统植发属于4级疼痛，而碧莲盛的疼痛管理技术将植发的疼痛降为基本无痛，从而解决了困扰求美者的最大痛点。',
      },
      {
        smallTile: '凸显个性化定制，服务体系全面升级',
      },
      {
        text: '碧莲盛对创新的追求不仅仅停留在技术上，在价格和服务体系上也有相应的升级。',
      },

      {
        text: '根据世界卫生组织统计，在全球超过75亿的人口中约有16亿人被脱发困扰。另据国家卫健委一项调查数据显示，2019年我国脱发人群已经超2.5亿人。也就是说，平均6人中就有1人脱发，其中男性更容易患雄激素性脱发，患病率约为21.3%，女性患病率相对较少约为6%。',
      },

      {
        text: '“与上一代人的脱发年龄相比，至少提前了20年，年轻化态势愈发明显。”《中国人头皮健康白皮书》指出。',
      },
      {
        Imag: `${url}/JournalismInfo/14/4.png`
      },
      {
        text: '庞大的脱发群体也预示着巨大的市场空间。咨询机构弗若斯特沙利文的数据显示，在中国，毛发医疗服务市场2020年的规模为184亿元，预计2030年将涨至1381亿元，规模增速高达750%。',
      },
      {
        text: '面对庞大而多元的市场需求，碧莲盛革新了价格体系，为不同群体量身定制了不同的价格方案。',
      },
      {
        text: '“我们新的植发价格体系围绕解决发友们传统剃发有尴尬期及恢复期长达一年、是否疼痛、是否有效果三大痛点应运而生。”师晓炯在现场仔细拆分新的植发价格体系时称，新的植发价格体系是按照不剃发技术的医生等级来划分。基本上分成三个等级，即常规团队不剃发、技术院长团队不剃发，以及主任副主任团队不剃发。同时，新的植发价格体系将高端的不剃发技术平民化，以基础级为例，这一级别的不剃发从40元/每毛囊单位降至12元/每毛囊单位，而为了保障术后的养护效果，碧莲盛将予以每一位不剃发手术患者额外90天的专属养护解决方案。',
      },
      {
        text: '随着“不剃发植发”手术正在全面替代“剃发植发”，与此对应的，硬件设施、术前后服务体系也在持续升级。',
      },
      {
        text: '今年5月4日，碧莲盛与中国整形美容协会培训部响应“市场监管总局等十一部门出台关于进一步加强医疗美容行业监管工作的指导意见”的要求，联合举办不剃发植发高级研修班，旨在解决目前不剃发植发医师短缺的现状。',
      },
      {
        text: '“研修班将于9月正式开班，现已面向全国从事毛发移植的医护人员开放报名。研修班由我们经验丰富的专家担任讲师，从理论授课、模拟练习、手术观摩、问题答疑等方面设置日程。”尤丽娜在上述发布会上表示。',
      },
      {
        text: '不止于此，8月18日，碧莲盛与盛美康教育科技正式签署战略合作协议，双方约定将在不剃发技术交流、学术研究、植发技术升级等方面展开深入合作，共同推动毛发医疗行业的发展。',
      },
      {
        text: '“碧莲盛专家团队还率先主编了四部国内毛发移植专著，积累了不剃发植发丰富的临床经验，为中国毛发移植领域培养了大批骨干，并着力打造不剃发、植发的培训基地，引领中国毛发移植行业规范可持续发展。”尤丽娜亦在发布会现场表示道。      ',
      },
    ]
  },
  {
    title: '碧莲盛发布三大重磅升级，引领植发业高质量发展',
    date: '发布时间：2023-08-28',
    list: [{
        text: '当前，“美好生活”成为一个热门话题。让人民生活幸福是“国之大者”，也是每个企业的使命和责任。',
      },
      {
        text: '8月28日，由碧莲盛医疗集团（下称“碧莲盛”）主办，广东时代传媒集团、头部美学研究院承办的“植发技术蝶变 引领头部美学”碧莲盛医疗集团技术战略升级发布会在京举办。',
      },
      {
        text: '在发布会现场，碧莲盛创始人尤丽娜女士发布公司三大重磅升级：碧莲盛技术创新升级、碧莲盛价格体系升级、碧莲盛服务体系升级，旨在为消费者在植发过程中遭遇的一系列痛点提供全方位的解决方案。',
      },
      {
        Imag: `${url}/JournalismInfo/15/1.png`
      },
      {
        text: '作为本次发布会的重头戏，碧莲盛宣布，将退出“剃发植发”业务，并将其自主研发的“不剃发植发”技术升级，打造“无痛不剃发植发”技术。此外，在价格和服务体系上，碧莲盛亦推出了针对不同群体与需求的个性化定制方案。',
      },
      {
        text: '碧莲盛医疗集团副总裁师晓炯先生在发布会上指出，植发技术升级的驱动力是消费者对即刻秀发、快速变美的不断追求。不剃发植发代表了植发技术的最高水平，且一定会全面取代传统剃发技术，是植发技术的必然发展趋势。',
      },
      {
        text: '“18年来碧莲盛始终致力于让生活更美好的事业，不断钻研技术，不断创新，树立了‘成为中国毛发健康服务创领者’的美好愿景。”尤丽娜表示。',
      },
      {
        Imag: `${url}/JournalismInfo/15/2.png`
      },
      {
        smallTile: '保持高质量发展，成为行业领军者',
      },
      {
        text: '“企业高质量发展”是国家提出的“高质量发展”的必然内涵，也是企业应对世界之变、时代之变、历史之变的战略选择。碧莲盛积极响应国家号召，以创新引领企业高质量发展。',
      },
      {
        text: '碧莲盛自2005年成立至今，已在全国开设43家直营机构，拥有100余位执业医生，700余位执业护士，其中，主任/副主任医师10余位，为至少35万发友解决了脱发困扰。',
      },
      {
        text: '作为行业领军者，碧莲盛已经逐渐搭建起一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。目前，碧莲盛申请10项国家发明专利，已获批5项国家发明专利。',
      },
      {
        Imag: `${url}/JournalismInfo/15/3.png`
      },
      {
        text: '截至2023年8月，碧莲盛碧莲盛不剃发植发手术量已突破5万台，平均毛囊种植数量2200单位，涉及毛囊数量已经超过6000万，不剃发植发已经成为碧莲盛的品牌名片，正在引领人们美好生活的新潮流。',
      },
      {
        text: '近年来，中国“植发”关键词网络热度始终保持在高位，平均指数在500以上。另据中国健康促进与教育协会数据显示，目前中国秃发人群总量已突破2.5亿大关，也就是说，每6个中国人就有1人在脱发。',
      },
      {
        text: '巨大的需求撑开了一个新消费蓝海。2020年，中国植发医疗服务市场规模为134亿元。预计到2025年，中国植发医疗服务的市场规模将达到人民币378亿元，复合年增长率将为23%。',
      },
      {
        text: '面对此现状，碧莲盛与中国整形美容协会培训部响应2023年5月4日“市场监管总局等十一部门出台关于进一步加强医疗美容行业监管工作的指导意见”的要求，联合举办不剃发植发高级研修班，旨在解决目前不剃发植发医师短缺的现状。',
      },
      {
        text: '尤丽娜透露：“研修班将于9月正式开班，现已面向全国从事毛发移植的医护人员开放报名。研修班由我们经验丰富的专家担任讲师，从理论授课、模拟练习、手术观摩、问题答疑等方面设置日程，让所有学员都能够学有所得，学有所获。”',
      },
      {
        text: '另外，据人民好医生客户端健康管理项目负责人卫馨女士介绍，今年5月，人民健康携手碧莲盛联合相关领域的专家，推出人民健康头皮健康高质量发展计划，计划主要包括头皮健康专科服务计划和头皮健康系列科普。',
      },
      {
        Imag: `${url}/JournalismInfo/15/4.png`
      },
      {
        text: '此前，碧莲盛专家团队率先主编四部国内毛发移植专著，积累了丰富的不剃发植发临床经验，为中国毛发移植领域培养了大批骨干人才，着力打造不剃发植发的培训基地，引领中国毛发移植行业规范可持续发展。',
      },
      {
        text: '另外，据师晓炯介绍，目前植发客户存在“尴尬、疼痛、效果”三大痛点，碧莲盛为此提供解决方案：全面推行不剃发，逐步淘汰传统剃发；疼痛管理技术，让植发轻松简单；不剃发器械国家发明专利+医生团队技术等级认证。',
      },
      {
        smallTile: '秉承医者仁心，履行品牌使命',
      },
      {
        text: '碧莲盛一直以尊医重卫守护医者仁心，秉承医者仁心，坚持医疗为本。碧莲盛深耕植发行业十几年，拥有多项创新技术专利，以切实的高标准造福了全国的发友，满足人民对美好生活的向往。',
      },
      {
        text: '尤丽娜认为，当前消费升级带动美颜经济、健康经济等发展，中国脱发保健行业形成并产生了多种毛发健康产品的巨大商业潜能。在这个需求潜力良好的消费医疗赛道，如何保证安全、取得效果、追求技术创新等方面，碧莲盛一直走在行业的最前列。',
      },
      {
        text: '在技术创新方面，历经五年潜心研发，2020年8月，碧莲盛于深圳首次发布不剃发植发技术，现场向30多家媒体机构现场直播展示6台不剃发植发技术，推进植发行业进入不剃发植发时代。',
      },
      {
        text: '在企业管理方面，碧莲盛始终践行数智化管理理念，加快ESG标准化建设。以提质增效为方向，制定统一的数智化管理标准，建立完整的毛发健康产业生态链，让美丽可持续。在数智化管理方面，碧莲盛建立了一系列较为完善的系统流程，包括分销体系、电子病历、会员体系、直播体系这些基础的系统，每日都在为碧莲盛的高速发展贡献着力量。同时，碧莲盛也着手于升级员工管理，客户标签体系，自动化信息触达的完善及提升。',
      },
      {
        text: '社会在进步，人们的消费水平日渐提高，碧莲盛在数智化层面的追求也在紧跟社会的步伐，未来期望在数据中台搭建、经营数据看板、员工端数据分析等板块有更高层次的提升，为发友提供更便捷的服务，为员工提供更高效的办公环境。',
      },
      {
        text: '在践行公益方面，碧莲盛努力践行社会责任担当，积极开展参与公益事业，自2010年启动了千万植发公益基金，已连续12年为烧烫伤病人免费植发。未来，碧莲盛将继续加大帮扶力度，与轻松筹联合发布温暖生发希望，伤烫伤病人植发公益救援行动，帮助更多的烧烫伤病人重拾希望。',
      },
      {
        text: '目前，碧莲盛已连续两年为振兴乡村教育助力，关注贫困儿童，让孩子们接触更精彩的外部视野，为他们梦想的实现提供助力。',
      },
      {
        text: '“未来，我们将秉承医者仁心，坚持医疗为本，将‘为发友的健康美好生活提供终生服务’作为品牌使命，不仅用精湛的植发技术创造美，还用科学的养发服务为发友的头部美学终生保驾护航。”尤丽娜如是说。',
      },
    ],
  },
  {
    title: '碧莲盛携手盛美康教育 共促毛发医疗行业升级',
    date: '发布时间：2023-08-21',
    list: [{
        text: '“颜值经济”快速增长,植发市场蓬勃发展,行业升级势在必行。8月18日,碧莲盛不剃发植发与盛美康教育科技正式签署战略合作协议,双方约定将在不剃发植发技术交流、学术研究、植发技术升级等方面展开深入合作,共同推动毛发医疗行业的发展。',
      },
      {
        Imag: `${url}/JournalismInfo/20/1.png`
      },
      {
        text: '国家卫健委发布的脱发人群调查最新数据显示,中国有超2.5亿人正饱受脱发困扰。此外相关数据显示,植发市场规模从2016年58亿元快速增长至2020年134亿元,年均复合增长率达23%。',
      },
      {
        text: '碧莲盛作为中国最早进入毛发医疗行业的先驱者,凭借独特的不剃发技术,个性化美学定制方案领跑市场。面对快速发展的中国市场,碧莲盛创始人尤丽娜女士认为,在当前市场环境下持续发展优势,植发技术的持续升级、专业诊疗内核服务提升、专项植发领域人才培养显得尤为重要。“快速发展的市场,也是竞争激烈的新市场,监管部门要求植发行业从价格到服务更加规范化,这是行业发展的大趋势。也需要植发行业内企业自律规范,加强管理意识。坚守医疗本质,不断提升自身医疗水平,创新和完善植发技术,满足植发市场所需。”',
      },
      {
        text: '行业升级并不是一句空话,需要在治疗方法、仪器、产品、服务等不同维度,积极探索和创新,提升医学养发的专业度和体验感。碧莲盛不剃发植发拥有先进的不剃发植发技术和丰富的临床经验。截止2023年2月,碧莲盛不剃发植发手术量已突破3万台,平均毛囊种植数量2200单位,涉及毛囊数量已经超过6000万。长期的临床实践积累让碧莲盛成为国内毛发医疗行业的领军企业。盛美康教育科技则致力于毛发医疗行业的学术研究和技术培训,是国内知名的毛发医疗教育机构。此次合作旨在发挥双方优势,共同为推动毛发医疗行业的升级和发展而努力。',
      },
      {
        text: '根据碧莲盛不剃发植发和盛美康教育科技的合作协议,双方将在技术交流、学术研究、植发技术升级和人才培养四大领域展开合作。不剃发植发技术交流旨在分享不剃发植发技术的最新进展和临床经验,国内外知名机构携手共同提高不剃发植发的技术水平。双方将定期举办技术交流会,坚持分享个性化定制的自然美学植发方案。专注技术创新的同时,也严苛追求深耕细作。把植发密度、成活率作为基础标准,同时为用户提供更先进的治疗方法、升级款技术产品、与世界同步的审美设计服务。',
      },
      {
        Imag: `${url}/JournalismInfo/20/2.png`
      },
      {
        text: '学术研究的重要性在医疗行业不言而喻。在传统的植发技术中,由于需要将头发全部剃掉,患者需要承受剃头和种植的双重痛苦。而碧莲盛是国内第一家引进FUE(毛囊单位钻取术)的企业。2002年,新技术 FUE面世并引入国内,当时成立仅3年的碧莲盛率先摒弃FUT(毛囊单位头皮条切取术)有痕植发,在连锁医疗机构中全面应用FUE技术。解决了传统植发技术需要剃光头发的痛点。这也意味着,碧莲盛将在学术研究探索领域更加严谨和精细化。与盛美康教育科技合作后,双方将共同开展不剃发植发的学术研究,探索不剃发植发技术的优势和应用前景,为不剃发植发技术的发展提供理论支持。',
      },
      {
        text: '同时,双方将不断植发技术升级,双方将共同研发新的不剃发植发技术,提高植发效果和患者体验,为毛发医疗行业的技术升级做出贡献。',
      }
    ]
  },
  {
    title: '“睡一觉，头发回来了” 碧莲盛不剃发植发是如何诞生的？',
    date: '发布时间：2023-08-11',
    list: [{
        text: '7月8日至9日，承载了无数人期待的国潮音乐嘉年华在石家庄森林河趣那公园热力来袭，一场属于年轻人的狂欢派对盛大开启，掀起国潮音乐的狂潮。中国植发行业头部品牌碧莲盛不剃发植发强势入驻，让来自全国各地的年轻乐迷们在感受火爆音乐浪潮的同时，也能深入了解自己的毛发健康，头发好才能一起嗨！',
      },
      {
        text: '“睡一觉，头发回来了。”这是在42台不剃发植发手术直播现场听到的话。',
      },
      {
        text: '　5月26日，碧莲盛医疗集团联合广东时代传媒集团召开的“2023头部美学海岛潮流大会”在三亚落幕。会议现场不乏精彩瞬间，令人印象最深刻并值得反复回味的是，碧莲盛在会议中启动了全国42家院部手术直播并现场连线，向社会公众全程直播不剃发植发的手术过程，并随机连线了7个院部，由医生对植发类型进行了详细介绍，对发友的术前、术后变化进行了展示，并由发友即时分享术后感受。',
      },
      {
        Imag: `${url}/JournalismInfo/19/1.png`
      },
      {
        tilte: '经历发际线植发手术后的韩颖',
      },
      {
        text: '如此毫无顾虑地把42台植发手术放在大家的目光之下进行，碧莲盛的举动让不少在现场和观看直播的人士感到震撼。',
      },
      {
        text: '在这次手术之前，韩颖（化名）曾经在其他机构做过2000多个毛囊的剃发植发，由于手术时长和恢复周期都比较长，韩颖当时只好向公司请了一个星期的假。“但我对（那时候）发际线的设计并不满意，所以两年后我又选择进行调整，还把发际线稀疏的地方补了一下。我已经照过镜子了，这是我期待的效果。”她评价道。',
      },
      {
        Imag: `${url}/JournalismInfo/19/2.png`
      },
      {
        Imag: `${url}/JournalismInfo/19/3.png`
      },
      {
        tilte: '42台不剃发植发手术直播现场',
      },
      {
        text: '而另一方面，韩颖的案例也透露出当代人对于植发需求的新变化，即不再单纯为了遮秃，而是为了变美。俗话说，好看的容貌千千万，美丽的头发算一半。美观的头发已经成为塑造一个人形象的重要组成部分。',
      },
      {
        text: '在颜值经济日益崛起的背景下，将目光投向不剃发植发技术的人并不在少数。作为植发技术中的新标杆，不剃发植发技术正在掀起头部美学的新潮流。',
      },
      {
        smallTile: '“不剃发帮我悄悄变美”',
      },
      {
        text: '现如今，受生活节奏的加快和不良作息的影响，正让越来越多人饱受脱发之苦。国家卫健委发布的脱发人群调查数据显示，中国有超2.5亿人正饱受脱发困扰。这意味着，平均每6个人中就有一人脱发。',
      },
      {
        text: '庞大的脱发群体也预示着巨大的市场空间。《2022年中国植发行业研究报告》显示，2021年中国植发行业市场规模达到169亿元，比2020年增长26.1%。',
      },
      {
        text: '而除了单纯的“秃头”外，越来越多人为了追求更美的自己选择植发技术。此外，个性化、定制化、隐私化的植发需求也在不断显露，头部美学经济已然崛起。',
      },
      {
        text: '碧莲盛董事长尤丽娜也敏锐地注意到了这一变化。在本次大会上，尤丽娜在演讲中提到，植发行业近几年发展迅速，并逐渐从高端消费走向普通消费，进入年轻人的日常生活，植发需求也出现了不一样的特点，逐渐从功能性向美学性升级。',
      },
      {
        text: '“当下，人们越来重视头部美学，特别是商务人士，公众人物等。良好的形象可以给自己带来满满的自信，也成为高质量生活的因素之一。”她表示。',
      },
      {
        Imag: `${url}/JournalismInfo/19/4.png`
      },
      {
        tilte: '碧莲盛董事长尤丽娜',
      },
      {
        text: '在传统的植发技术中，由于需要将头发全部剃掉，患者需要承受剃头和种植的双重痛苦。而不剃发植发技术完全解决了传统植发技术需要剃光头发的痛点，拥有痛感低、自然度高，没有尴尬期等优势。',
      },
      {
        text: '“没想到一点也不疼，中间睡了一觉，一睁眼头发回来了，很惊喜。”武汉发友张明（化名）在被现场连线时表示。',
      },
      {
        text: '作为被连线的42个院部之一的患者，“张明的植发类型同样是发际线种植，一共种植了1800单位。”武汉院部的汪贤文医生介绍道，这台手术总共做了不到6小时，速度相对适中，种植的效果不错，“毛流方向自然、密度也很适配种植区视觉密度，我对这台手术也很满意。”他表示。',
      },
      {
        Imag: `${url}/JournalismInfo/19/5.png`
      },
      {
        tilte: '术后现场连线医生和发友',
      },
      {
        text: '据介绍，碧莲盛的不剃发植发技术是“长发取，长发种”，可以实现提取并种植8厘米的原生发，手术当天就能看到种植完成后的效果，免去了过渡期的同时，还保护了植发者的个人隐私。',
      },
      {
        text: '另一位参加本次大会的发友张静（化名）是模特出身。尽管面容姣好，但张静却一直介意自己脑门大、额角区域空的问题。据她讲述，自己在进行不剃发植发手术之后，发际线变得圆润，额角区域空的问题也明显减轻。',
      },
      {
        text: '不剃发植发对隐私的保护性也在她的经历上得到印证。“基本上我不说，身边的人都看不出我植过发。大家都觉得我最近变漂亮了，但又不知道是哪里变了。”她笑着说道。',
      },
      {
        text: '诸多优势正让不剃发植发技术的热度节节攀高。《2022年中国植发行业研究报告》指出，近年来，在各项主流技术中，不剃发植发技术受到越来越多的关注。舆情数据显示，不剃发植发技术的网络讨论热度相比去年提升了76％。',
      },
      {
        smallTile: '如何看待不剃发植发技术的发展史？',
      },
      {
        text: '《2022中国植发行业研究报告》指出，不剃发植发是目前行业前沿的技术，它颠覆了植发的传统，实现了毛囊提取、种植技术的双重革新，是植发行业的创举。如此一项革新技术的诞生并非易事。对此，碧莲盛足足用了逾5年的时间。',
      },
      {
        text: '碧莲盛成立于2005年，拥有42家全国直营的连锁分院，100余位执业医生，700余位执业护士，其中，主任和副主任医师10余位，为至少35万发友解决了脱发困扰。',
      },
      {
        text: '碧莲盛是国内第一家引进FUE（毛囊单位钻取术）的企业。2002年，新技术 FUE面世并引入国内，当时成立仅3年的碧莲盛率先摒弃FUT（毛囊单位头皮条切取术）有痕植发，在连锁医疗机构中全面应用FUE技术。',
      },
      {
        text: '然而，FUE技术并不能一直满足发友们的需求。2014年，碧莲盛北京院部遇到了一位知名舞蹈家，其提出，希望将后枕部的头发延长取出，之后再种到前额区域。',
      },
      {
        text: '“这个要求对当时的我们来说是很难想象的。”回忆起当时的场景，中国整形美容协会毛发医学分会委员、副主任医师、碧莲盛名医堂核心成员尹梓贻在大会上感慨万分。',
      },
      {
        text: '从2015年起，碧莲盛开始从最短的头发研究不剃发植发。然而，由于不剃发植发意味着需要更精细的手法、更严格的消毒要求和更长的手术时间，碧莲盛的大部分医生都对此表示排斥。',
      },
      {
        text: '2018年成为了命运的转折点。这一年，尤丽娜在亚洲植发大会上看到一台韩国的植发手术设备，支持长发提取、种植的功能让尤丽娜为之一振。会后，尤丽娜开始让医生大规模研究如何完成大面积不剃发植发。',
      },
      {
        text: '“其间，我们至少要花费一年时间，才能观察出是否可以长出健康的头发。”她透露。',
      },
      {
        text: '经过不懈的苦心研究，2020年8月，碧莲盛正式推出不剃发植发技术，成为国内第一家将“不剃发”概念真正落地的企业。',
      },
      {
        text: '尹梓贻介绍，“不剃发植发技术分为剃发和种植两个部分，从开始研发到真正成功，不剃发植发技术经历了多个阶段的演变。不剃发技术是从半剃发提取到剪短头发提取，再到分层剃发提取，最后才变成现在的完全不剃发提取；而种植也是从剃发种植变为不剃发加密，最后才变成到完全不剃发的种植。”',
      },
      {
        text: '据碧莲盛披露，截知2023年2月，碧莲盛不剃发植发手术量已突破3万台，平均毛囊种植数量2200单位，涉及毛囊数量超过6000万。第三方机构的数据也显示，使用不剃发植发技术的用户中有92.8％来自碧莲盛。',
      },
      {
        smallTile: '消费日报网版权及免责声明:',
      },
      {
        text: '1. 凡本网注明“来源：消费日报网” 的所有作品，版权均属于消费日报网。如转载，须注明“来源：消费日报网”。违反上述声明者，本网将追究其相关法律责任。',
      },
      {
        text: '2. 凡本网注明 “来源：XXX（非消费日报网）” 的作品，均转载自其它媒体，转载目的在于传递更多信息，并不代表本网赞同其观点和对其真实性负责。',
      },
      {
        text: '3. 任何单位或个人认为消费日报网的内容可能涉嫌侵犯其合法权益，应及时向消费日报网书面反馈，并提供相关证明材料和理由，本网站在收到上述文件并审核后，会采取相应措施。',
      },
      {
        text: '4. 消费日报网对于任何包含、经由链接、下载或其它途径所获得的有关本网站的任何内容、信息或广告，不声明或保证其正确性或可靠性。用户自行承担使用本网站的风险。',
      },
      {
        text: '5. 基于技术和不可预见的原因而导致的服务中断，或者因用户的非法操作而造成的损失，消费日报网不负责任。',
      },
      {
        text: '6. 如因版权和其它问题需要同本网联系的，请在文章刊发后30日内进行。',
      },
      {
        text: '7. 联系邮箱：xfrbw218@163.com  电话：010-67637706',
      },
    ]
  },
  {
    title: '积极拥抱年轻消费者 碧莲盛不剃发植发跨界携手国潮音乐嘉年华',
    date: '发布时间：2023-07-12',
    list: [{
        text: '7月8日至9日，承载了无数人期待的国潮音乐嘉年华在石家庄森林河趣那公园热力来袭，一场属于年轻人的狂欢派对盛大开启，掀起国潮音乐的狂潮。中国植发行业头部品牌碧莲盛不剃发植发强势入驻，让来自全国各地的年轻乐迷们在感受火爆音乐浪潮的同时，也能深入了解自己的毛发健康，头发好才能一起嗨！',
      },
      {
        Imag: `${url}/JournalismInfo/18/1.png`
      },
      {
        text: '国潮音乐嘉年华是国潮文化2023年创新升级的优质演出与城市生活方式盛会，以亲民和互动性为内核，将中国故事融入潮流生活中，结合音乐体验开发线下交互、实体艺术等多种娱乐载体，以音乐演出、品牌跨界、创意市集、装置艺术等落地体验，打造灵活丰盈的城市生活方式新玩法，是一场真正走向生活，走向音乐的户外音乐盛宴。',
      }, {
        text: '据悉，本次演出吸引了朴树、陈楚生、二手玫瑰、马頔、苏阳、落日飞车等知名乐队及音乐人参加，涵盖了流行音乐、摇滚乐、电子音乐和民谣等众多音乐类型，突出展示中国传统音乐和民族文化的魅力，歌曲融合了传统中国元素和当代流行音乐元素，展现了国潮音乐的风采，为乐迷们带来精彩纷呈、充满朝气和能量的音乐演出。',
      },
      {
        text: '近年来，人们对美感和容貌的要求不断提高，但是现实很残酷，社会压力大、生活节奏快、不健康的生活方式等因素共同作用下，脱发群体不断壮大，脱发年轻化趋势明显，国家卫健委数据显示，我国脱发人群规模超2.5亿人，其中18-30岁脱发人群合计占比高达66.6%，年轻人已经成为脱发的“主力军”，脱发焦虑已经成为一个社会普遍的现象和问题，脱发群体的困境值得引起整个社会的关注。',
      },
      {
        text: '为此，本场国潮音乐嘉年华，碧莲盛不剃发植发向广大乐迷开展了毛发健康义诊，碧莲盛的专业医护团队面对面进行脱发疾病的防治知识普及和交流，对各种脱发情况进行了详细讲解，并针对的毛发健康情况给出了专属的建议和解决方案。值得一提的是，碧莲盛还为广大乐迷准备了养发体验券、手机充电宝、人间四季主题笔记本等精美礼品，成为广大乐迷朋友关注的焦点。',
      },
      {
        Imag: `${url}/JournalismInfo/18/2.png`
      },
      {
        text: '一名现场乐迷告诉记者：“我经常听歌到深夜，有时候也会通宵玩游戏，可能是因为这些不健康的生活习惯，我的脱发情况还是挺严重的。但是，因为平时比较忙，没有时间进行专业的检测，这次的活动是个非常好的机会，不仅深度了解了自己的头皮健康状态，也收获了很多防脱知识和技巧。”',
      },
      {
        Imag: `${url}/JournalismInfo/18/3.png`
      },
      {
        text: '每天，有2.5亿名中国人在为脱发而烦恼，拯救头发已然成为一种全民叙事。作为医疗美容细分领域之一，植发行业近几年发展迅速，并逐渐从高端消费走向普通消费。新消费时代，主流消费群体变化，年轻人正逐渐成为新消费时代的消费主力，植发需求也呈现出不一样的特点，植发正逐渐从功能性向美学性升级。',
      },
      {
        text: '“每个人都有重塑自我的权利，重塑自我，是让人掌控生活的一种方式。”碧莲盛相关负责人表示，“近年来，随着毛发健康行业的发展，人们对植发品质的追求也在不断提高。如今的消费者选择植发，除了植发效果之外，还对植发的舒适度、个性化、定制化、隐私化提出了更高的要求。基于上述要求，碧莲盛自主研发了不剃发植发技术，帮助广发发友重塑头部美学。”',
      },
      {
        text: '据悉，国潮音乐嘉年华是国内少有的集合了年轻人音乐梦想、青年文化和潮流生活的音乐节，以潮流文化与摇滚音乐的跨界组合而闻名。碧莲盛尝试通过年轻人喜闻乐见的音乐活动深入年轻客群，巩固碧莲盛的年轻化品牌形象。',
      },
      {
        Imag: `${url}/JournalismInfo/18/4.png`
      },
      {
        text: '此次碧莲盛不剃发植发打破边界，与2023石家庄国潮音乐嘉年华携手跨界，希望借助当下年轻人喜爱的音乐舞台，以音乐积极拥抱年轻消费者，以义诊直接对话年轻消费者，共同传递“不甘平凡，追求潮流”的主张态度，加深与用户的互动沟通，让头部美学更有温度和深度。',
      }
    ]
  },
  {
    title: '情暖护士节 碧莲盛礼赞白衣天使',
    date: '发布时间：2023-05-13',
    list: [{
        text: '护佑人民健康，照亮生命之光。在5月12日国际护士节来临之际，为表达对医护工作者的敬意和关爱，北京微爱公益基金会开展“医路守护，感恩有你”护士节慰问活动，携手碧莲盛医疗集团走进广州医科大学附属肿瘤医院、中南大学湘雅三医院、中南大学湘雅二医院、湖南省儿童医院、南京市第一医院慰问医护人员，在这个专属于白衣天使的节日里，为奋战在各大医院的护士们送去节日的关怀和温馨的祝福。',
      },
      {
        Imag: `${url}/JournalismInfo/17/1.png`
      },
      {
        text: '护士队伍一直是我国卫生健康战线的重要力量，护理工作是卫生健康事业的重要组成部分，医护人员是守护生命健康的天使。他们无私奉献冲锋在抵抗病毒的一线，用陪伴提升生命温度，用护理疗愈患者身心。在慰问活动中，碧莲盛为奋战在一线的白衣天使们捐赠了爱心礼包，向他们致以亲切的问候和崇高的敬意。',
      },
      {
        Imag: `${url}/JournalismInfo/17/2.png`
      },
      {
        text: '据了解，碧莲盛不剃发植发成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。成立至今已开设43家分院，拥有100余位执业医生，700余位执业护士，其中，主任/副主任医师10余位，为至少35万发友解决了脱发困扰。',
      },
      {
        text: '品牌的发展不仅依托于高品质、多元化的产品硬实力，积极履行社会责任、传递品牌文化及价值理念也必不可少。作为植发行业的领军企业，碧莲盛始终秉持“安心治疗、安全变美”的品牌使命，在追求企业高质量发展、技术不断突破创新的同时，更应与社会发展同频共振，践行企业的社会使命，蓄力集结正能量，传递企业价值理念。',
      },
      {
        Imag: `${url}/JournalismInfo/17/3.png`
      },
      {
        text: '多年来，碧莲盛一直牢记初心，积极投身于公益事业，致力于为社会奉献品牌价值。新冠疫情期间，碧莲盛第一时间响应，积极参与、支持抗疫行动，为一线抗疫工作捐赠医疗物资、支援核酸检测工作等。自2010年启动了“千万植发公益基金”，已连续12年为烧烫伤病人免费植发；今年，碧莲盛将继续加大帮扶力度，与轻松筹联合发布“温暖生发希望-烧烫伤病人植发公益救援行动”，帮助更多的烧烫伤病人重拾希望。此外，碧莲盛连续两年为振兴乡村教育助力，关注偏远地区儿童，让孩子们接触更精彩的外部世界，为他们梦想的实现提供助力。',
      },
      {
        text: '碧莲盛成立18年来，始终致力于让生活更美好的事业，树立了“成为中国毛发健康服务创领者”的美好愿景，秉承医者仁心，坚持医疗为本，将“为发友的健康美好生活提供终生服务”作为品牌使命，不仅用精湛的植发技术创造美，还用科学的养发服务为发友的头部美学保驾护航。',
      },
      {
        text: '为了近距离感受毛发健康产业的发展，最大限度地保障消费者的合法权益，5月12日护士节当日，碧莲盛同期举办了媒体开放日活动——走进广州分院，在线下面向媒体展示自身业务品牌，主动接受社会监督。多位主流媒体记者参加本次活动，多方位了解毛发专业知识，切身体验头皮养护服务。',
      },
      {
        text: '碧莲盛广州分院建于2012年，为了让广大消费者体验到更好的诊疗服务，近日广州分院喜迎乔迁，全新升级、隆重开业！碧莲盛广州分院负责人何丽华院长建议广大消费者要学会保护自己，选择有医疗许可的正规医疗美容机构做植发，选择专业的医生，用合规合格的产品，同时碧莲盛也将不断加强发友的科普教育。',
      },
      {
        Imag: `${url}/JournalismInfo/17/4.png`
      },
      {
        text: '作为医疗健康行业的一份子，碧莲盛深知医护人员的艰辛。今后，碧莲盛将持续加大对医护群体的关爱，彰显医疗工作者的初心与本色，鼎力支持医护群体的工作，与他们携手助力人们的健康生活。通过源源不断地为社会贡献力量、传播正能量，不断为行业及社会发展赋能。',
      }
    ]
  },
  {
    title: '碧莲盛董事长尤丽娜荣获“2023中国十大品牌女性”',
    date: '发布时间：2023-04-10',
    list: [{
        text: '最是一年春好处，人间四月“郑”当时。以“绽放你的美”为主题的2023中国品牌节第十六届女性论坛于4月7日至9日在郑州国际会展中心圆满落幕。',
      },
      {
        text: '活动中，2023年中国品牌女性榜单隆重揭晓。中国整形美容协会毛发分会副会长、碧莲盛医疗集团董事长尤丽娜凭借其突出的个人表现以及正向的行业影响力荣膺“2023中国十大品牌女性”、“2023中国品牌女性500强”。',
      },
      {
        Imag: `${url}/JournalismInfo/sixteen/1.png`
      },
      {
        text: '据了解，品牌女性的评判标准十分严格，要求候选人在行业中做出过杰出贡献、具有一定行业影响力或引领作用，获得重大荣誉；具有优秀的个人品质、正确积极的社会价值观取向；在本职工作中有突出业绩、做出重大贡献以及个人具有很强的行业知名度等。',
      },
      {
        Imag: `${url}/JournalismInfo/sixteen/2.png`
      },
      {
        text: '值得特别关注的是，作为碧莲盛医疗集团创始人，尤丽娜带领碧莲盛开展、参与多种形式的公益事业，积极参与抗疫行动、振兴乡村教育、建立烧烫伤患者植发公益基金等，为社会送去温暖和希望。',
      },
      {
        Imag: `${url}/JournalismInfo/sixteen/3.png`
      },
      {
        text: '碧莲盛是全国连锁植发品牌，自2005年成立至今，在全国已开设43家直营机构，拥有100余位执业医生，700余位执业护士，其中，主任/副主任医师20余位，为至少35万发友解决了脱发困扰。在为国内消费者提供优质服务的同时，碧莲盛也将我国领先的植发技术推向世界，尤丽娜董事长作为世界植发大会资深会员，已连续13年受邀出席世界植发大会，向世界展示中国植发技术。',
      },
      {
        Imag: `${url}/JournalismInfo/sixteen/4.png`
      },
      {
        text: '植发是医美行业的一个重要分支，作为植发行业的头部企业，碧莲盛受邀参加2023中国品牌节女性论坛平行论坛之一—生物医药论坛。尤丽娜董事长发表了关于《坚守医疗本质 助推行业发展》的主题演讲。她表示，随着脱发人群的增加，近几年中国的植发市场井喷式发展。国家卫健委发布的脱发人群调查数据，中国已经有超过2.5亿人正饱受脱发的摧残，平均每6人里就有1人脱发，且趋势愈发年轻化，和上一代人相比，现在中国的脱发人群年龄提前了20年。',
      },
      {
        text: '随着市场需求越来越大，对技术和服务的体验要求越来越高，碧莲盛在技术上不断创新提高，与时俱进。2020年，碧莲盛率先将不剃发植发技术落地，被主流央级媒体评价为是植发业带来跨越式发展的“新基建”。截至目前，碧莲盛不剃发植发手术量已突破50000台。',
      },
      {
        Imag: `${url}/JournalismInfo/sixteen/5.png`
      },
      {
        text: '为了营造诚信放心的消费环境，2021年，植发行业也出了《毛发移植规范》团体标准。整个植发行业需要找准自身定位，严格进行自我监管，加强植发行业手术操作流程的规范、管理，进一步降低手术风险，使消费者获得诚实、安全的服务。尤丽娜董事长强调，植发从业者必须从消费者的利益角度出发，对安全、专业持之以恒，从根源上保障消费者权益。植发机构更应坚守医疗本质，不断提升自身医疗水平，创新和完善植发技术，满足植发市场所需，为更多的脱发人群带来毛发新生。碧莲盛不断前行，与植发行业共进步，引领行业规范运营，倡导透明消费，共同打造安全有序的市场环境，共同敦促植发行业持续健康发展。',
      }
    ]
  },
  {
    title: '估值再创新高，“头部”企业碧莲盛如何实现逆势增长？',
    date: '发布时间：2022-06-15',
    list: [{
        text: '2022年6月15日，“2022未来医疗100强主榜”发布。我们惊喜地发现，碧莲盛已连续数年闯进“创新医疗服务榜“，并创下估值新高。入选2020届“未来医疗100强”时，其估值仅为20亿元。两年时间，碧莲盛已完成估值翻倍，如今估值达45亿元。',
      },
      {
        Imag: `${url}/JournalismInfo/1/%E5%9B%BE%E7%89%871.png`
      },
      {
        text: '“头部”企业碧莲盛如何实现连年估值攀升？经济大环境趋冷之下，又如何做到营收逆势增长？',
      },
      {
        Imag: `${url}/JournalismInfo/1/%E5%9B%BE%E7%89%872.png`
      },
      {
        smallTile: '不剃发植发技术，市占率超九成',
      },
      {
        text: '根据艾媒咨询的统计，2019年我国约有2.5亿脱发人群。随着生活习惯和压力的增加，脱发已出现明显的年轻化趋势，约60%的人在25岁就已经出现脱发现象，年轻群体已经成为植发的主要消费群体。'
      },
      {
        text: '随着脱发困扰人群的不断壮大，植发市场也迎来广阔的成长空间。2016年至 2020 年，我国植发医疗服务市场规模从 58亿元提升至134亿元，复合增速达23.3%，实现快速增长。另根据沙利文的预测，2030年我国植发医疗服务市场规模有望达到756亿元。'
      },
      {
        text: '市场虽大，但就植发本身而言，还面临一些痛难点。首先就是消费者隐私。如何保证隐私不受侵犯，不被看出已经植发，是年轻群体对植发项目一个重要需求。同时，植发手术的安全性风险、植发后的毛囊存活率问题，也是行业发展的一大难点。'
      },
      {
        text: '针对此，碧莲盛首先从技术上做了创新与保障。2020年8月，碧莲盛首次推出不剃发植发技术。该技术真正做到了长发取、长发种，最长可提取8公分的原生长发，提取、种植全程不剃发，手术当天就能看到最终的植发效果，避免了剃发植发对脱发患者个人形象的短期影响，满足了消费者保护隐私、快速变美的需求。'
      },
      {
        text: '据碧莲盛介绍，目前碧莲盛已完成逾2万台不剃发植发手术，在整个行业不剃发植发手术数量的占比超过了90%。NHT不剃发植发已成为碧莲盛在行业内品牌优势的护城河。'
      },
      {
        smallTile: '百位医生、千位医护，打造优势植发团队'
      },
      {
        text: '其实，不剃发植发技术诞生已久，但目前国内植发行业能够真正熟练应用的植发机构少之又少，其壁垒事实上并不在技术本身，而在于是否拥有大量具备成熟操作经验的手术医生。'
      },
      {
        text: '目前，碧莲盛组建了一支经验丰富的医护团队，拥有100余位实战派植发医生，800多名专业医护人员。其中，主治医师占比达超六成，10位为主任/副主任医师，这在植发行业，甚至医美行业都较为少见。'
      },
      {
        text: '在人才吸引、选择与培养方面，碧莲盛也下了不少功夫。首先，碧莲盛建立了严格的选聘制度和专业考核制度，确保每一位医生都有国家颁发的合规资质证书。'
      },
      {
        text: '其次，碧莲盛建立了成熟的培训体系，比如医生带教模式，碧莲盛在7大区域均配备了医疗带教官，他们都是资历身后、经验丰富的医生，新医生入职之后，都会在他们的言传身教之下提升技术。最后，碧莲盛打造了完善的医生成长体系，通过专利申报、学术论文发表、媒体报道、新媒体推广等方式，帮助医生们打造个人IP品牌。'
      },
      {
        text: '在技术研发方面，碧莲盛也正在不断增加研发费用，鼓励医生不断去创新技术，特别是不剃发植发技术相关的研发，是碧莲盛目前在技术研发方面的重点。其次，碧莲盛选择通过制度创新保证技术研发的推进，碧莲盛的7大区域都设立了主任/副主任医师级别的医疗技术带头人，并以他们为核心组建了研发团队。最后，碧莲盛也在知识产权和专利申报方面着力，进一步强化不剃发植发领域的技术优势。'
      },
      {
        smallTile: '拓展养发新蓝海，满足毛发产业链多重需求'
      },
      {
        text: '2021-2022年，我国护发市场成交额以28.7%的速度增长，护发及相关产业达到400亿规模。由于植发业务本身复购率较低，加之消费者对毛发养护的需求水平不断提升，近年来医学养护业务在植发行业得到发展。'
      },
      {
        text: '为此，碧莲盛于2020年年底，碧莲盛正式推出养护市场全资独立品牌——莲主任，而“医学养发”就是莲主任与传统养发的最大区别。传统养发是通过“以养代疗”的方式改善毛发健康，重点在养护，并不能从医学层面解决问题。'
      },
      {
        text: '莲主任则在重视养护的同时，更加强调医学治疗的严谨性，倡导养护与治疗相结合、药物与仪器相结合，头皮治疗与真皮层治疗向相结合，由表及里、由浅入深，为客户提供多层次、多维度、全周期的医学生发管理方案。'
      },
      {
        text: '在治疗方法层面，传统养发机构的重点在于养护头皮，清洁表皮层头皮环境，只解决表层及表象问题，莲主任则是将重点放在治疗毛囊，通过医学的方式，解决毛发健康的深层问题。'
      },
      {
        text: '在服务人员层面，传统养发机构一般由养发师提供服务，养发馆对养发师进行短期的培训即可上岗，对医疗资质并没有要求，莲主任则组建了一支经验丰富的护师团队，人人持有合规的医疗资质，为用户提供的是医疗服务。'
      },
      {
        text: '自从“莲主任”品牌投入运营以来，其专业服务也得到了消费者的广泛认可，很多植发用户正在转化为养护用户。通过私域运营以及口口相传，已经产生传播的裂变效果。'
      },
      {
        text: '相比2020年，“莲主任”2021年的营收增速达到了100%。同时，“莲主任”营收在碧莲盛总营收的占比也在逐年提升，目前已经达到了20%。未来，“莲主任”在碧莲盛的业务比重将会进一步提升，市场潜力巨大。'
      },
      {
        smallTile: '行业集中度提升，坚持合规发展与标准化运营'
      },
      {
        text: '2021年6月10日,国家卫健委等八部委联合印发《打击非法医疗美容服务专项整治工作方案》，表示将加大力度整治不正规的医疗机构以及行业乱象。市场的选择也将向头部植发机构靠拢，行业集中度有望进一步提升。'
      },
      {
        text: '作为行业内公认的头部企业，碧莲盛认为内部管理有效有序、服务精细精准是合规经营的保障。经营模式上，碧莲盛采用的是“一城一店”的直营模式，目前已经在全国38个城市开设了直营机构。碧莲盛的所有分院，从院部选址、院部装修开始，均由集团总部统一负责。'
      },
      {
        text: '目前，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国院部保持统一的视觉形象输出，同时对前台、顾问、医护团队进行统一的培训，全国所有家分院遵循统一的管理标准，以确保全国用户都能享受到品质如一的服务。'
      },
      {
        text: '植发行业全产业链的市场需求很大。未来，碧莲盛将继续加大研发投入，完善服务体系，扩展直营医院，研发自有品牌产品，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集植发、养发、固发于一体的毛发健康产业生态闭环。'
      },
      {
        text: '另外，碧莲盛将进一步下沉市场到三四线城市，完成百城百店的战略布局。同时加大在养发领域的投入，将“莲主任”打造成在养发行业极具影响力的品牌，建立起可复制的养发连锁门店。'
      },
      {
        smallTile: '写在最后'
      },
      {
        text: '碧莲盛创始人尤丽娜表示：“疫情给碧莲盛的发展带来了许多不确定性。不过，疫情的爆发让我们可以停下来静心思考，这是弥足珍贵的。这段时间，我们进行了企业的又一次改革，做出了重要的组织架构调整，创新院长责任制，数智化。同时，碧莲盛成立运营中心，做到院部发力、数据驱动、总部赋能三管齐下，这次改革推动了后续碧莲盛在管理上、业绩上、数字化上的前行。”'
      },
      {
        text: '植发行业从最初的“默默无闻”，到如今“骤热”成为舆论焦点话题，离不开全行业的共同努力与突破。作为头部企业，碧莲盛将继续发挥自身优势引领行业发展。具体来说，公司将把合规经营作为底线，完成技术的护城河，探索可复制的发展模式，完成渠道继续下沉的战略布局，稳步推进上市计划，助力植发行业早日攻破千亿市值大关。'
      },


    ]
  },
  {
    title: '播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行',
    date: '发布时间：2022-06-01',
    list: [{
        text: '6月1日，在第72个国际儿童节到来之际，碧莲盛发起了六一爱心助学公益行活动，碧莲盛太原分院院长李丽华等6名爱心人士代表走进河津市柴家镇上市小学，为同学们送上了一份珍贵而有意义的礼物，陪伴孩子们度过了一个专属于他们的六一儿童节。',
      },
      {
        Imag: `${url}/JournalismInfo/2/1.png`
      },
      {
        text: '“少年强，则国强，青少年是国家和民族的未来，希望在全社会的关心关爱下，每一个孩子都能在阳光下健康成长。”李丽华表示，“作为一家用技术为人民创造美好的医疗机构，碧莲盛希望未来能够有更多的机会积极回报社会。”',
      },
      {
        text: '活动现场，碧莲盛为上市学校里的每个学生捐赠了一个“爱的四季包”，书包中还有电动削笔机、电动橡皮擦、电动吸尘器等电动文具套装，每一件都有精致可爱的包装，在提升学习积极性的同时，还省时省力让同学们学习更加高效。',
      },
      {
        text: '据悉，去年的六一儿童节，碧莲盛就来到了上市小学，为同学们捐赠了一座“爱的图书馆”，包括书架、阅读桌、椅子以及各类型的图书。',
      },
      {
        text: '今年，碧莲盛又带来了一批新的图书，碧莲盛的爱心人士代表不仅当场写下爱的寄语，还与同学们一同阅读新书，在体验阅读学习的乐趣的同时，帮助同学们养成良好的阅读习惯。',
      },
      {
        Imag: `${url}/JournalismInfo/2/2.png`
      },
      {
        text: '上市小学樊校长深情地说：“非常感谢碧莲盛的爱心人士，去年为同学们捐赠了一所图书馆，让同学们有了阅读课外书籍的地方。这次又为同学们带来了一批新书、精致的新书包和可爱的电动文具，让学生们更加快乐地学习，真是帮了学校的大忙。”',
      },
      {
        text: '多年来，碧莲盛一直牢记公益初心，努力践行公益使命，积极开展乐学书包捐赠、一对一助学、爱的图书馆、同走上学路等多种形式的爱心助学活动，及时向困难学生伸出援手，不断向留守儿童送出关爱，像呵护树苗一样呵护少年儿童，让他们健康快乐成长。',
      },
      {
        Imag: `${url}/JournalismInfo/2/3.png`
      },
      {
        text: '未来，碧莲盛将继续关注贫困地区的留守儿童，除了捐赠学龄阶段儿童需要的学习物资之外，还会捐赠更多的爱心图书馆，并为爱心图书馆配备更全面的设施，让孩子们接触更精彩的外部世界，为他们梦想的实现提供助力。',
      },

    ]
  },
  {
    title: '碧莲盛荣获胡润百富“最青睐的高端植发品牌”',
    date: '发布时间：2022-01-20',
    list: [{
        text: '1月20日，第十八届胡润百富至尚优品颁奖盛典在上海隆重举行，数百位行业精英、商界领袖和社会名流齐聚盛会，共同见证中国精英群体的品牌倾向及消费风尚。中国植发行业头部品牌碧莲盛荣获“最青睐的高端植发品牌”大奖，以创新科技和高端品质，引领时尚健康生活新格调。',
      },
      {
        text: '据悉，“胡润百富至尚优品颁奖盛典”旨在表彰过去一年被行业所公认、发展成果最显著的品牌。碧莲盛凭借其良好的信誉口碑、卓越的技术创新能力以及不剃发植发技术对植发行业发展的突出贡献，成为唯一获得2021至尚优品奖项的植发品牌，这代表着高端消费市场对碧莲盛的高度认可。',
      },
      {
        text: '作为“还原中国高净值人群生活方式”的最权威榜单之一，胡润研究院连续第十八年发布《至尚优品—中国千万富豪品牌倾向报告》，旨在揭示中国高净值人群的生活方式、消费习惯以及品牌认知的转变与偏好。这份报告被广泛认为是面向高净值人群品牌的ISO，是国内最具影响力的财经榜单之一。',
      },
      {
        text: '碧莲盛华北地区总经理苏林女士在颁奖典礼上表示，“脱发年轻化的趋势目前十分明显，年轻人对自己的形象要求更高，对植发有更清楚的认知，更愿意尝试植发这种新生事物。碧莲盛深耕植发行业17年，就是想通过自己的努力，帮助广大脱发患者找回自信，满足人民对美好生活的向往。”',
      },
      {
        Imag: `${url}/JournalismInfo/5/1.png`
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。',
      },
      {
        text: '活动现场，上海碧莲盛技术院长、副主任医师周宗贵出席同期举办的胡润百富奢侈品领袖论坛，并围绕“主流消费品和高端消费”的主题进行了分享。周宗贵指出，“中国已经进入了新消费时代，年轻人群体有很多新消费需求，我们不仅要通过植发满足他们的爱美之心，还要在个性化、隐私化等方面用功。所以，碧莲盛自主研发了不剃发植发技术，来更好的满足年轻人提出来的新需求，不仅保证植发效果，还全方位提升服务体验。”',
      },
      {
        Imag: `${url}/JournalismInfo/5/2.png`
      },
      {
        text: '据介绍，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，碧莲盛正是起草单位之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '目前，碧莲盛旗下34家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        Imag: `${url}/JournalismInfo/5/3.png`
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，植发并不能满足脱发患者日益多元化的需求，养发、护发的需求也将催生巨大的市场。对此，周宗贵认为，“未来对于脱发的治疗，手术和非手术一定是并驾齐驱的，甚至非手术还要超过手术，因为有很多人对手术有恐惧心理，非手术相对更简单，更容易接受，市场也更广阔。”'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，植发并不能满足脱发患者日益多元化的需求，养发、护发的需求也将催生巨大的市场。对此，周宗贵认为，“未来对于脱发的治疗，手术和非手术一定是并驾齐驱的，甚至非手术还要超过手术，因为有很多人对手术有恐惧心理，非手术相对更简单，更容易接受，市场也更广阔。”'
      },
      {
        text: '苏林表示，此次获奖是对碧莲盛品牌实力的认可，未来，不剃发植发、高品质服务、优秀的医护团队，仍然是碧莲盛的底气所在。同时，碧莲盛也将再接再厉，不断积极进取、开拓创新，用不断升级的技术，为用户提供更加专业的医疗服务。'
      },
    ]
  },
  {
    title: '不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖',
    date: '发布时间：2022-01-14',
    list: [{
        text: '1月14日，由数央公益、数央网联合众多媒体共同主办的第十一届中国公益节在上海圆满落幕。碧莲盛植发凭借在履行企业社会责任方面的杰出表现，以及多年来助力公益事业获得的良好公众评价，赢得第十一届中国公益节评委会一致认可，荣获“2021年度企业社会责任行业典范奖”。',
      },
      {
        Imag: `${url}/JournalismInfo/4/1.png`
      },
      {
        text: '中国公益节设立于2011年，是国内首个由大众媒体联袂发起的以“公益”命名的节日。活动旨在弘扬公益精神，倡导公益行为，搭建多方深度对话、合作沟通的平台。历经十一年的探索和实践，中国公益节已经成为中国公益慈善领域最具影响力的年度盛事。作为植发行业最具影响力的头部品牌，碧莲盛在不断聚焦公益讲座、免费毛囊检测、专家免费问诊、定期赠送养护产品等公益板块的同时，还积极探索企业社会责任未来发展的新方向，并取得了良好的成效。此次在中国公益节斩获大奖，与碧莲盛强大的品牌责任理念和坚持不懈的公益实践探索有着必然的联系，是社会各界对碧莲盛的高度认可与嘉奖。'
      },
      {
        Imag: `${url}/JournalismInfo/4/2.png`
      },
      {
        text: '“在做企业的同时，我认为自己承担着很大的社会责任。企业的发展壮大，离不开社会各界的认可和支持，我会坚持社会效益与企业效益齐头并进，坚持诚信为本、造福社会，做一名有爱心的企业家。”尤丽娜董事长是这样想的，也是这样做的，在经营企业的过程中，她时刻不忘关心弱势群体，常怀感恩之心，倾心回报社会。'
      },
      {
        text: '新冠疫情已经爆发两年，虽然已经得到了有效控制，却仍在不少地方出现反复，在此期间，碧莲盛勇担社会责任，迅速驰援，为抗击疫情做出努力。湖北疫情，碧莲盛第一时间响应国家号召，成立百万专项基金，向疫情前线捐赠口罩、防护服和专业医疗设备；河北疫情，碧莲盛第一时间采购专业防疫物质，驰援南宫疫情前线；西安疫情，碧莲盛迅速组织专业医护团队加入核酸采样队，协助居委会帮市民进行核酸检测。'
      },
      {
        text: '我国经济虽发展迅速，大部分地区的学生都能拥有一个美好的校园生活，但仍有贫困地区的学校里还没有设立专门的图书馆，学生们缺少查阅资料、阅读课外书、丰富知识的途径。据了解，2021年儿童节，碧莲盛携手鸿基金共同举办山西公益行活动，为河津县上市小学捐赠了“爱的图书馆”，为每个学生捐赠一个“爱的四季包”，并认领5名品学兼优的学生进行一对一帮扶资助。'
      },
      {
        text: '之后，碧莲盛又联合腾讯公益、鸿基金共同发起了“携手碧莲盛，一起做好事”公益活动，号召每一位员工和就诊顾客奉献爱心共同帮助贫困地区的困境留守儿童，为其捐款，保障生活贫苦的留守儿童的物质生活。同时，在北京、南昌、太原等地开展“99公益日”社区毛发健康义诊活动，帮助社区居民全面了解自己头发的健康状况，并针对每位居民的头发情况给出专属的建议和解决方案，获得了社区居民的一致好评。'
      },
      {
        text: '为了帮助更多的脱发患者，碧莲盛成立了千万植发公益基金，免费帮助生活上有困难的脱发患者植发。来自西部贫困地区的农村发友白龙，曾经因热水造成1/2头皮损伤并伴随大面积脱发，多年来对自己形象极不自信，后来碧莲盛免费为他植发，帮助他重拾对生活的信心，这一案例也曾经在世界植发大会上展示，瘢痕性秃发领域的临床应用效果得到了与会专家的一致认可，并入选世界植发大会案例中心。'
      },
      {
        text: '据了解，碧莲盛在2020年推出了不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，彻底改变了植发行业先剃发后植发的传统，直接提取原生长发种植，实现了毛囊提取、种植技术的双重革新，避免了长发剃短的痛苦，完美保护个人隐私，恢复周期大大缩短，在保证术后效果的同时，满足立即变美的愿望，开创了植发技术的全新篇章，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '“不剃发植发技术的成熟，对于疤痕植发也有十分重大的意义。对于疤痕脱发患者来说，传统植发仍然需要等待一年，才能看到最终的效果，这对他们来说，还是有些慢，不剃发植发的出现，则满足了他们快速恢复美好形象的需求。”尤丽娜告诉记者，碧莲盛近期将推出新的公益计划，在全国范围内征集疤痕脱发患者，用最好的不剃发植发技术，最好的医护团队以及最优惠的政策，帮助他们快速恢复美好的形象，重拾对生活的自信与向往。'
      }
    ]
  },
  {
    title: '不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业',
    date: '发布时间：2022-01-09',
    list: [{
        text: '2021年是“十四五”规划的开局之年，是向第二个百年奋斗目标进军的开启之年，为更好地推动中国经济高质量发展，1月8日至9日，由《环球时报》社和中国企业网联合主办的“第五届博鳌企业峰会暨2021亚洲经济大会”在海南博鳌亚洲论坛国际会议中心隆重举行。'
      },
      {
        Imag: `${url}/JournalismInfo/6/1.png`
      },
      {
        text: '本届论坛以“中国企业变与新”为主题，邀请国家领导人、有关部委领导、专家学者、500强企业领袖和各行业优秀代表等出席，旨在助力中国企业向好、向上发展，凝聚新时期发展共识，搭建交流与合作平台，探寻未来发展新路径。碧莲盛作为植发行业的杰出代表受邀参会，并获得组委会一致提名当选“2021年度植发行业领军企业”，同时凭借不剃发植发技术创新的行业影响力，荣获“2021年度医美行业科技创新奖”。'
      },
      {
        text: '本次大会邀请有关部委领导、经济界知名人士、知名研究机构、专家学者、媒体领袖组成评审委员会，并围绕“创新力、影响力、贡献力、发展力、经营力”五大评选标准进行候选人的资质考核，着力挖掘并表彰了一批新经济时代各个行业的优秀企业代表。'
      },
      {
        text: '自2005年成立以来，碧莲盛在植发技术创新领域积极探索多年，自主研发的不剃发植发实现了整个行业的突破，满足了新经济时代新消费群体的新需求，给评审委员们留下了深刻印象，被一致赞为“在植发行业有引领作用的企业”，并授予“2021年度植发行业领军企业”、“2021年度医美行业科技创新奖”两项大奖。'
      },
      {
        text: '活动现场，碧莲盛董事长尤丽娜女士在接受采访时表示，“口碑积累对于企业发展至关重要，好口碑能赢得消费者的信任，而好口碑更来自于消费者的认可。碧莲盛的技术和服务换来了口口相传的有效传播，也提升了品牌价值。”'
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '此前，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，尤丽娜女士正是起草人之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，对质量的要求越来越高，他们不仅要通过植发满足爱美之心，也在个性化、定制化、隐私化等方面提出了更高的要求。不剃发植发的出现，无疑满足了这些新需求，也必将引领起植发行业新的发展趋势。'
      },
      {
        text: '尤丽娜告诉记者，“研发不剃发技术的首要原因就是满足更多新消费人群对美的追求。与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。我认为，通过满足需求侧的要求，做好供给侧的不断完善和进化是行业头部企业责任的体现。”'
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },

      {
        text: '“回顾2021年，我们也遇到了很多挑战，但是碧莲盛坚持创新、坚持改革，战胜了挑战，取得了业绩的稳步增长。放眼“十四五”，展望新征程，挑战前所未有，时势总体有利，机遇依然在我。不剃发植发、高品质服务、优秀的医护团队，仍然是碧莲盛的底气所在。”尤丽娜如是说。'
      },

    ]
  },
  {
    title: '碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性',
    date: '发布时间：2021-12-28',
    list: [{
        text: '2021年是“十四五”规划的开局之年，是向第二个百年奋斗目标进军的开启之年，为更好地推动中国经济高质量发展，12月28日，由中国亚洲经济发展协会、《环球时报》社和中国经济新闻联播网联合主办的“2021中国经济高峰论坛暨第十九届中国经济人物年会”在上海隆重举行。'
      },
      {
        Imag: `${url}/JournalismInfo/7/4.png`
      },
      {
        text: '本届论坛以“新经济、新格局、新征程”为主题，邀请了诸多政商领袖、专家学者与主流媒体参会，旨在全面探讨和解读中国企业创新驱动战略，助推中国经济转型升级。作为植发行业的杰出代表，碧莲盛董事长尤丽娜女士受邀参会，并获得组委会一致提名当选“2021中国经济十大杰出女性”。'
      },
      {
        text: '本次大会邀请国务院国资委、中国社科院、中共中央政策研究室等有关部门领导和经济界知名人士担任评审委员会委员，并围绕“创新力、影响力、贡献力、发展力、经营力”五大评选标准进行候选人的资质考核，着力挖掘并表彰了一批新经济时代各个行业的优秀企业家代表。'
      },
      {
        text: '尤丽娜女士自2005年创立碧莲盛以来，在植发技术创新领域积极探索多年，自主研发的不剃发植发实现了整个行业的突破，满足了新经济时代新消费群体的新需求，给评审委员们留下了深刻印象，被一致赞为“植发技术创新的行业天花板”！'
      },
      {
        text: '为了表彰尤丽娜女士多年来辛苦耕耘、自主创新，为植发行业健康发展作出的卓越贡献，本次大会授予她“2021中国经济十大杰出女性”称号。值得一提的是，此次共同当选的企业家还有长城汽车总经理王凤英、碧桂园联席主席杨惠妍、海尔集团执行副总裁谭丽霞、立讯精密董事长王来春等知名品牌的杰出代表。'
      },
      {
        Imag: `${url}/JournalismInfo/7/1.png`
      },
      {
        text: '活动现场，尤丽娜女士受邀出席了“中国经济企业家创新论坛”，围绕“新征程上的管理创新之路”进行了主题演讲。尤丽娜指出，“口碑积累对于企业发展至关重要，好口碑能赢得消费者的信任，而好口碑更来自于消费者的认可。碧莲盛的技术和服务换来了口口相传的有效传播，也提升了品牌价值。”'
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '此前，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，尤丽娜女士正是起草人之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，对质量的要求越来越高，他们不仅要通过植发满足爱美之心，也在个性化、定制化、隐私化等方面提出了更高的要求。不剃发植发的出现，无疑满足了这些新需求，也必将引领起植发行业新的发展趋势。'
      },
      {
        Imag: `${url}/JournalismInfo/7/2.png`
      },
      {
        text: '尤丽娜告诉记者，“研发不剃发技术的首要原因就是满足更多新消费人群对美的追求。与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。我认为，通过满足需求侧的要求，做好供给侧的不断完善和进化是行业头部企业责任的体现。”'
      },
      {
        Imag: `${url}/JournalismInfo/7/3.png`
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
    ]
  },
  {
    title: '碧莲盛携手鸿基金启动“爱的翅膀·助力成才计划',
    date: '发布时间：2021-03-20',
    list: [{
        text: '3月20日，第七届中国品牌创新发展论坛在北京会议中心隆重召开。此届论坛站在2021年全球政治经济格局下，围绕中国企业现状与前景等诸多议题，进行分析探讨，为新时代的企业发展提供有价值的品牌思路和变革方向。'
      },
      {
        text: '作为植发行业的唯一企业代表，碧莲盛受邀出席本次论坛。凭借良好的信誉口碑、对植发行业技术发展的突出贡献，碧莲盛荣获“2020中国品牌榜·诚信品牌奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/8/1.png`
      },
      {
        text: '论坛现场鸿基金公益环节，碧莲盛董事长尤丽娜作为公益爱心人士，受邀上台与陈伟鸿和众企业家共同启动了“爱的翅膀——助力成才计划”，跟随鸿基金一起努力践行公益力量，帮助更多的人！未来碧莲盛尤丽娜女士会进入鸿基金公益，用满满的爱心帮助贫困地区留守儿童健康成长。据了解，鸿基金由央视主持人陈伟鸿发起，此次与碧莲盛的强强合作，将会持续关注关爱贫困留守儿童，为他们送去希望，助力他们梦想的实现。'
      },
      {
        Imag: `${url}/JournalismInfo/8/2.png`
      },
      {
        text: '碧莲盛植发成立于2005年，是一家致力于毛发健康产业的全国直营连锁机构。十七年来精钻植发技术，是国内唯一一家连续十三年参加世界植发大会，向世界展示中国植发技术的企业。目前，碧莲盛已在全国32座城市开设了直营分院，拥有100余位植发领域知名专家，400多位专业医护团队，为近35万发友解决脱发困扰。'
      },
      {
        text: '据了解，碧莲盛董事长尤丽娜是我国植发行业中少数具有行医资格和临床经验的创始人，在“医生”和“企业家”两个身份上完美切换。她凭借自己的专业素养和管理能力，带领碧莲盛稳步向前，发展成为全行业当之无愧的“头部”企业。同时，医生的身份让她更懂患者的困难和需求，更好地肩负起她和碧莲盛的社会责任。'
      },
      {
        Imag: `${url}/JournalismInfo/8/3.png`
      },
      {
        text: '为了帮助更多的脱发患者，碧莲盛成立了“千万植发公益基金”，通过举办公益讲座、开展大型公益活动、提供免费毛囊检测、专家免费问诊服务、定期赠送养护产品，无偿为发友提供医疗援助，帮助众多贫困发友重拾信心，为更多求美者提供科学、安全、诚信、专业的医疗服务，不断推动中国植发行业的健康发展。'
      },
      {
        text: '“在做企业的同时，我认为自己承担着很大的社会责任。企业的发展壮大，离不开社会各界的认可和支持，我会坚持社会效益与企业效益齐头并进，坚持诚信为本、造福社会，做一名有爱心的企业家。”尤丽娜董事长是这样想的，也是这样做的，在经营企业的过程中，时刻铭记着“安全”二字，同时她非常的关心弱势群体，常怀感恩之心，倾心回报社会。'
      },


    ]

  }, {
    title: '专家热议“新消费”：多措并举促进新业态新模式健康发展',
    date: '发布时间：2021-03-10',
    list: [

      {
        text: '近日，在人民网《人民会客厅》两会特别版——《两会时刻》系列访谈栏目上，中国消费者协会副秘书长王振宇，十三届全国人大代表、内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波，商务部国际贸易经济合作研究院流通与消费研究所博士梁威，碧莲盛集团董事长尤丽娜，锅圈食汇联合创始人李欣华，淘宝主播、美one合伙人李佳琦，58同城副总裁李子健围绕“新业态新模式引领‘新消费’”主题展开讨论，共同解读新业态新模式下的新型消费现阶段的特点，以及未来的机遇与挑战。'
      },
      {
        Imag: `${url}/JournalismInfo/9/1.png`
      },
      {
        tilte: '嘉宾做客《人民会客厅》 热议“新消费”'
      },
      {
        text: '节目嘉宾一致认为，随着互联网经济的兴起，数字化浪潮正在带动新业态经济蓬勃发展。以网络购物、新零售等为代表的新型消费规模不断扩大，不仅有效保障了居民日常生活需要，同时也推动了国内消费恢复，促进了经济企稳回升。各类新业态新模式的涌现，在不断改变着国民消费习惯的同时，需及时关注侵害消费者权益的新问题。嘉宾建议继续加强“新消费”领域相关法律法规和制度建设，深化包容审慎和协同监管，健全服务标准体系，规范市场秩序。'
      },
      {
        smallTile: '聚焦“新消费”趋势 “互联网+”助力新业态新模式发展'
      },
      {
        text: '2020年9月，国务院办公厅印发《关于以新业态新模式引领新型消费加快发展的意见》，并作出一系列重要部署。今年政府工作报告也重点提出，要运用好“互联网+”，推进线上线下更广更深融合，发展新业态新模式，为消费者提供更多便捷舒心的服务和产品。'
      },
      {
        Imag: `${url}/JournalismInfo/9/2.png`
      },
      {
        tilte: '中国消费者协会副秘书长王振宇'
      },
      {
        text: '不论对我国经济的内部大循环，还是对百姓自身的消费体验而言，新业态新模式引领新消费都有着重大意义。访谈中，中国消费者协会副秘书长王振宇表示：“以‘互联网+’模式为引导的线上线下共同发展新业态模式，是高科技在消费领域的进一步体现。消费不仅是科技进步的成果检验，也会对科技和生产做进一步的指导。”除此之外，新业态新模式可以更加凸显以消费者为中心的理念。王振宇表示，从消费者的角度而言，新消费领域在传统领域之上，更加关注消费者的新需求及个性化需求，给消费者提供更加精准，更加便利的服务。'
      },
      {
        Imag: `${url}/JournalismInfo/9/3.png`
      },
      {
        tilte: '十三届全国人大代表、内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波'
      },

      {
        text: '实际上，新业态新模式不仅在推动城市的快速发展，也在广大的农村落地生根，推动了县乡消费潜力。内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波表示：“随着消费水平、人民收入的提高及社会的进步，县乡线上消费能力也在提升。在电商平台、互联网、大数据的作用下，县乡消费能力能够快速就进入到新模式、新连接当中。这对实现乡村振兴，挖掘线上消费有很大的帮助。”'
      },
      {
        smallTile: '跟紧“新消费”节奏服务业纷纷“上线”开启新实践'
      },
      {
        Imag: `${url}/JournalismInfo/9/4.png`
      },
      {
        tilte: '淘宝主播、美one合伙人李佳琦'
      },
      {
        text: '作为互联网经济的代表，直播电商在2020年蓬勃发展，规模不断扩大，引领了互联网经济新潮流。访谈中，知名主播李佳琦称：“在行业愈加激烈的竞争下，大众对直播带货的新鲜感有限，长久的流量只能以品质为内核，通过持续不断的内容创新，提升内容品质，提供给用户最新的内容和最有趣的体验。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/5.png`
      },
      {
        tilte: '58同城副总裁李子健'
      },
      {
        text: '除了直播电商，各界企业也在积极探索如何在新业态新模式的大背景下获得持续性发展。58同城副总裁李子健表示：“我们线上的订单去年一年有将近10倍的增长，这表明整个社会对服务线上化的接受程度在飞速增加。”谈及企业近年为实践新业态新模式发展而做出的改变，李子健称：“当前，消费者的家政服务需求在不断升级，且不断地倒推着供给侧的改革。在这个大背景下，58同城在两年前推出了‘到家精选’项目，旨在为消费者提供更高品质的服务。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/6.png`
      },
      {
        tilte: '锅圈食汇联合创始人李欣华'
      },
      {
        text: '事实上，新业态经济带来的契机并不限于新兴行业。许多传统行业也在互联网经济的推动下，探索发展机遇，力求更高质量地满足消费者对线上消费、“到家消费”的需求。对此，锅圈食汇联合创始人李欣华表示：“火锅是餐饮行业里最易标准化和零售化的产品，因此催生了很多企业对火锅食材零售化的探索。除了持续为消费者提供高性价比的食材之外，我们将发力物流、仓储等核心的中枢板块，也将加大数据化和智能化的投入水平，引进高端人才，对整个供应链进行全链条的管理，提高效率并降低成本。”'
      },
      {
        smallTile: '构建“新消费”环境各方协力用“新监管”助力新业态'
      },
      {
        text: '在新业态新模式的簇拥下，经济发展不断彰显着新的活力。但与此同时，诸多新的挑战也随之涌现。在新消费环境下，政府监管部门和各界企业仍需相互配合，完善行业标准，共创更加优质的市场环境。'
      },
      {
        Imag: `${url}/JournalismInfo/9/7.png`
      },
      {
        tilte: '碧莲盛集团董事长尤丽娜'
      },
      {
        text: '针对近年快速发展但乱象丛生的医美行业，碧莲盛集团董事长尤丽娜称：“目前，医美行业三非乱象依然存在，即非医疗场所、非专业医生和非合格产品。在政府加大监管力度的同时，建立专业的人才培养体系，用专业的医生、过硬的技术，保障消费者的安全权益，让我们的手术患者安心变美、安全变美。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/8.png`
      },
      {
        tilte: '商务部国际贸易经济合作研究院流通与消费研究所博士梁威'
      },
      {
        text: '由于经营生态的良莠不齐，新业态经济未来的健康发展要建立在完善的监管体系的基础上。而监督、监管，以及良好业态的形成，不仅仅需要监管部门的严格规范，更需要企业高度自律，以及社会各界的力量的共同监督。'
      },
      {
        text: '展望未来新业态新模式的发展，商务部国际贸易经济合作研究院流通与消费研究所博士梁威称：“新消费后续发展应该是一个创新发展和融合发展相结合的图景。是市场主导、政府促进的新的发展愿望。”'
      },
    ]
  },

  {
    title: '潮流大秀视听盛宴隆重开幕 碧莲盛时尚盛典名流汇聚尽显奢华',
    date: '发布时间：2020-11-26',
    list: [{
        text: '11月26日，中国知名时尚医美品牌碧莲盛在杭州国大雷迪森广场酒店莲花厅隆重举办西湖时尚盛典。来自全国的时尚潮流达人、文化娱乐行业名人、时尚相关行业的意见领袖以及知名行业专家共约一百五十人盛装出席，宾朋满坐，高尚典雅。丰富的视听盛宴及热烈的社交媒体互动传播，在疫情减缓经济复苏之际，给与会者以及追求时尚潮流的爱美人士带来了全新的时尚体验和激情。'
      },
      {
        Imag: `${url}/JournalismInfo/10/1.png`
      },
      {
        tilte: '杭州西湖盛典现场'
      },
      {
        text: '西湖时尚盛典，通过美仑美奂的经典弦乐、芭蕾、歌剧以及时装秀等高雅艺术形式，激发与唤起人们对美的理解与追求，倡导更健康、更安全的时尚生活方式和医美技术。'

      },

      {
        text: '本次盛典由碧莲盛董事长尤丽娜女士隆重开启。活动现场，有三十余位来自各个领域的意见领袖、知名艺人、时尚达人盛装出场，通过红毯走秀、时尚拍摄、高级酒会、精美晚宴等形式进行了交流与互动，分享与呈现时尚、美丽、健康、自信的生活方式。'
      },
      {
        Imag: `${url}/JournalismInfo/10/2.png`
      },
      {
        tilte: '碧莲盛集团董事长尤丽娜'
      },
      {
        text: '西湖时尚盛典之际，杭州武林广场国大广场的楼群齐齐点亮，通过霓虹灯、大幅面LED屏幕及楼群的灯光组合，演绎碧莲盛NHT超级时空时尚灯光秀，精彩纷呈，为盛典喝彩，为美丽加油。'
      },
      {
        Imag: `${url}/JournalismInfo/10/3.png`
      },
      {
        tilte: '杭州国大城市广场'
      },
      {
        text: '在感恩节之际，尤丽娜女士率领碧莲盛的高管团队，与全国32家医院的院长以及20名顶级植发专家悉数亲临现场，济济一堂，与时尚潮流达人一道，感恩彼此一路同行，共同努力用技术创造对美的欣赏、诠释与追求。'
      },
      {
        text: '“今天，我们在杭州举办西湖时尚盛典是碧莲盛品牌发展与业务成长的又一个重要里程碑。”碧莲盛董事长尤丽娜女士表示，“杭州是中国经济和文化最发达的地区之一，是互联网经济和人才最活跃的地方，也是生活方式及健康时尚的代表性领军市场。我们在这里开设直营分院整整五年了，一直不遗余力地为客户提供高品质、安全、便捷的植发服务。中国市场有近2亿的脱发患者或潜在需求，更有数亿爱美爱时尚的潮流年轻人群，我们一直致力于技术创新，通过领先的NHT（No Haircut Technology）技术升级，和更多时尚内容的打造，实现品牌升级，为更多爱美的人带来惊喜，让更多人享受时尚和品质生活。疫情终将过去，未来更加美好。”'
      },
      {
        Imag: `${url}/JournalismInfo/10/4.png`
      },
      {
        tilte: '碧莲盛集团副总经理师晓炯'
      },
      {
        text: '著名电影人李海华说，对于碧莲盛呈现的时尚艺术与生活方式结合的盛典印象非常深刻，令人惊叹！对于美的欣赏和追求是人类永无止境的刚需，也是快乐的源泉和生活的根本。'
      },
      {
        text: '演员葛恒瑞、美妆达人景力、时尚达人金洋Jyan、陶陶星仔，美妆博主王子ssoa、快手视频红人怪兽，抖音视频红人查查和张张等数十位艺人和KOL，皆盛妆出席，风采云集，炫酷劲飒。'
      },
      {
        text: '时尚源自美好 倡导健康美丽'
      },
      {
        text: '随着中国成为世界第二大经济体，物质生活水平快速稳步提升的中国人，对美好生活的向往和需求，比以往任何时候都更加迫切。据国家卫健委发布的调查显示，我国脱发人群已超过2.5亿，平均每6个人中就有1个人有脱发症状，脱发人群逐年增多，并呈现年轻化的趋势。'
      },
      {
        Imag: `${url}/JournalismInfo/10/5.png`
      },
      {
        tilte: '碧莲盛集团coo张琦'
      },
      {
        text: '爱美之心，人皆有之。越来越多的人渴望拥有完美的形象，并有能力去追求和提升。碧莲盛的初衷就是创造美好，期待美好，“满足人民对美好生活的向往、对美好形象的追求”，尤丽娜女士认为，所有人都有追求“美”的权利，希望可以通过技术创造美，帮助到更多的脱发人群，让他们因为形象的美好而有更高的幸福感。'
      },
      {
        text: '同时，消费者变美的过程，一定要基于安全的基础之上。目前的植发市场，在医疗器械、合规规范以及操作资质等方面，有着诸多的监管困难与品质风险，消费者面临选择的时候，一定要选择安全值得信赖的技术和品牌。'
      },
      {
        Imag: `${url}/JournalismInfo/10/6.png`
      },
      {
        Imag: `${url}/JournalismInfo/10/7.png`
      },
      {
        text: '持续创新突破 引领技术风潮'
      },
      {
        text: '今年8月，碧莲盛率先推出备受行业关注的NHT不剃发（No haircut technology）植发技术，该技术所具备的优势、所带来的便利，得到了行业专家与广大消费者的一致认可。相比传统植发技术需要大面积剃发的现状而言，NHT不剃发技术可以真正做到提取、种植全程不剃发，完美避免了传统植发过程中剃发对消费者个人形象的短期影响。'
      },
      {
        text: '16年来，碧莲盛经历了从技术追随者到自主创新技术引领者的角色转变，在植发行业凭借创新的技术、精细的器械和优质的服务脱颖而出，体现了中国充满活力与创新的民营企业的强大生命力和勃勃生机。'
      },
      {
        Imag: `${url}/JournalismInfo/10/8.png`
      },
      {
        text: '追求精致完美 实现品牌升级'
      },
      {
        text: '继高速和辉煌的16年发展，碧莲盛迎来了新的业务发展阶段。碧莲盛医院网络已经全面覆盖中国的一二线城市，并且还在迅速扩张。随着医院、医生和专业医护人员的迅速拓展，高效的管理团队和服务体系对碧莲盛的长远来说显得尤为重要。'
      },
      {
        Imag: `${url}/JournalismInfo/10/9.png`
      },
      {
        Imag: `${url}/JournalismInfo/10/10.png`
      },
      {
        text: '尤丽娜女士表示，“碧莲盛在专业领域注重不断技术创新的同时，更会在医院品牌体系、客户服务体系、品质管理体系和人材培训体系等方面，不断深耕细作强化内功，在推动业务快速成长和市场的纵深开拓的同时，进一步夯实碧莲盛品牌的未来发展之路。”'
      },
      {
        text: '据悉，未来几年碧莲盛将投入巨额资金用于技术研发和品牌升级，精益求精，实现产品的更新迭代与技术创新，为市场提供更加丰富的方案选择。同时，碧莲盛也会在人才引进、人才培养和国际技术交流方面，进一步提升总体运营和管理水平，让消费者享受更时尚、更安全、更便捷的健康服务与体验。'
      },
      {
        text: ''
      },

    ]
  },


  {
    title: '斩获两项大奖，碧莲盛成为第七届中国行业影响力品牌峰会焦点',
    date: '发布时间：2020-08-30',
    list: [{
        text: '8月30日，由中国品牌创新发展工程主办的第七届中国行业影响力品牌峰会，在北京会议中心圆满落幕，央视著名主持人陈伟鸿主持了本次峰会。'
      },
      {
        text: '作为植发行业的唯一代表，碧莲盛受邀出席本次峰会。凭借良好的信誉口碑、对植发行业技术发展的突出贡献，碧莲盛入围2020行业影响力品牌榜，并荣获植发行业“诚信品牌奖”，创始人兼董事长尤丽娜荣获植发行业“领军人物奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/11/1.png`
      },
      {
        tilte: '碧莲盛董事长尤丽娜巅峰对话发言，左二'
      },
      {
        smallTile: '规范透明发展，塑造诚信品牌'
      },
      {
        text: '近年来，颜值消费成为很多年轻人追求的消费热潮，这也催生了植发市场的爆发。但是目前的植发市场，仍然存在着低价诱导、虚报毛囊数量、收费标准不透明等问题，“植发三天速成班”等行业乱象频频登上热搜，这让很多脱发患者望而却步。'
      },
      {
        Imag: `${url}/JournalismInfo/11/2.png`
      },
      {
        tilte: '碧莲盛coo张琦领奖，右一'
      },
      {
        text: '在此背景下，碧莲盛植发始终坚守合规底线，多年来一直坚持签约植发的“透明医疗”模式，术前与发友签订协议，公开价格、明确收费项目和标准、明确毛囊数量，通过法律的形式，保障发友的手术安全、毛囊成活率、术后效果，保障发友手术前、手术中、手术后的合法权益，“以身作则”引领行业合规发展。'
      },
      {
        text: '碧莲盛创始人尤丽娜告诉记者，“企业的发展要有底线思维，不能违规经营，不能假大空，要务实，从实际出发，什么能做什么不能做一定要清晰，牢牢把握住安全第一，只有这样，方能谋得企业的行稳致远”。            '
      },
      {
        smallTile: '专业品质服务，打造优质口碑	'
      },
      {
        text: '“发友至上，以人为本，诚信医疗”是碧莲盛植发从创立之初便定下的发展理念，一切从发友的需求出发，围绕“专属、贴心、主动”三大核心诉求，从术前问诊到方案设计，再到手术过程和术后服务，制定了统一规范的就诊流程和服务标准，确保全国32家分院都能让发友享受到统一标准、高品质的服务。'
      },
      {
        text: '在不断完善服务体系的同时，碧莲盛还建立了发友权益保障体系，术前签订协议、术中全程监控、术后追踪服务，实现了价格无忧、安全无忧、术后无忧、诚信无忧、隐私无忧。同时，碧莲盛先后与中国人保(PICC）、中国平安保险达成了战略合作，更进一步保障发友的合法权益。'
      },
      {
        text: '与其他行业不同，在植发这一重度垂直服务领域，发友选择植发机构非常严谨，口碑的好坏直接影响发友的决策。据相关统计，碧莲盛植发大部分的客户，来自于发友之间的口碑传播，这与碧莲盛不断完善服务体系、发友权益保障体系，在发友间积累了强大的口碑是分不开的。'
      },
      {
        smallTile: '坚守医疗本质，成就领军人物	'
      },
      {
        text: '据了解，碧莲盛创始人尤丽娜不仅是一名具有执业资格证书的植发医生，还是中国民营连锁植发行业中，参加ISHRS世界植发大会次数最多的会员，并于2017年成为中国第一个入驻世界植发大会案例中心的医生。此外，碧莲盛还连续12年出席世界植发大会，并多次受邀发表植发技术演讲。'
      },
      {
        text: '得注意的是，尤丽娜的医生出身为碧莲盛植发带来了不一样的医疗理念，碧莲盛发展的每一步都始终坚守医疗本质。尤丽娜对医生团队要求极高，从综合素质、责任心、毕业院校、从业经历等多维度考察，通过特有的传帮带模式培训，以及长期的实践，在专业的考核合格之后方能持证上岗。同时，已在行业领跑的碧莲盛仍然不断完善革新技术，在技术和人才方面的投入从不吝啬。随着最新NHT不剃发技术的诞生，更是将世界植发技术标准推向一个全新的高度。'
      },
      {
        Imag: `${url}/JournalismInfo/11/3.png`
      },
      {
        tilte: '碧莲盛副总经理师晓炯，左一'
      },
      {
        text: '对于履行企业社会责任，尤丽娜十分重视，成立了“植发公益基金”，通过举办公益讲座、开展大型公益活动、提供免费毛囊检测、专家免费问诊服务、定期赠送养护产品，无偿为发友提供医疗援助，帮助众多贫困发友重拾信心。'
      },
      {
        text: '突发疫情给整个中国环境尤其是企业带来不少影响，碧莲盛也受到冲击。“我们在全国的32家直营分院不能开业，给企业确实带来压力。但是，国家给了民营企业最大的帮助，补贴社保、减免税收等政策，支持我们专心工作、专研技术，留住更多人才，有序复工复产。同时，国家遇到困难，碧莲盛也义不容辞。疫情爆发后，我们快速成立专项基金，采购专用医疗设备、口罩、防护服等，分期分批赠送给疫情严重区域。”尤丽娜介绍说。'
      },
      {
        text: '疫情是把双刃剑，给企业带来压力的同时，也激发企业潜能。尤丽娜指出，在互联网高度发展的时代，线上线下融合仍然是大趋势，开展业务一定要线上线下联动，提升企业抵挡抗风险能力。同时，企业的发展要有底线思维，在医疗行业更要规范严谨，认认真真做好技术和服务，在国家大力推动双循环的背景下，企业要认真做好区域精细化运营，从大而全模式转变为精耕区域用户市场的差异化运营模式，通过技术的不断革新，渠道下沉，让植发像理发一样安全便捷。'
      },
      {
        text: '日后，碧莲盛也将继续秉承“回归医疗，以人为本”的理念，坚持“发友至上，诚信为本”的原则，为更多发友带来科学、安全、诚信、专业的医疗服务，不断推动中国植发行业的健康发展。'
      },

    ]
  },



  {
    title: '创新 匠心 仁心，植发行业赋能美好生活',
    date: '发布时间：2020-08-18',
    list: [{
        Imag: `${url}/JournalismInfo/12/1.png`
      },
      {
        text: '随着“颜值时代”的来临，追求美丽和展示美丽的效应使得医美行业实现快速发展。作为医美行业的细分产业，植发业兼具医疗的刚需和医美的消费升级属性，正迎来黄金发展时期。'
      },
      {
        text: '有数据显示，我国植发业2016年的市场规模约为57亿元，到2019年时已增长到约163亿元，增幅约为186%，预计到2020年底，市场规模将突破200亿元。'
      },
      {
        text: '面对巨大市场，植发行业的头部企业如何实现创新驱动、助力行业高质量发展？近日，碧莲盛植发技术研究院（以下简称碧莲盛）董事长尤丽娜携植发业最新前沿技术亮相“创新引领变革 科技赋能美好生活——NHT植发技术发布会”。在采访中，尤丽娜表示，植发行业应秉持创新驱动、匠心筑梦、仁心惠民的初心，助力健康中国、赋能美好生活。'
      },
      {
        smallTile: '创新驱动，技术变革引领行业前行'
      },
      {
        text: '“近几年，我国植发行业的用户保持60%-80%的增幅，市场很大。但要想真正引领行业发展，需要依靠技术和服务的‘双轮驱动’。”在这场由中国改革报•政企智库支持、碧莲盛举办的发布会上，尤丽娜介绍了碧莲盛16年来从追随到自主创新植发技术的发展过程。'
      },
      {
        text: '据介绍，碧莲盛在技术迭代的路上已走过五个技术代级，这五个技术分别是SHT无痕微针、BHT超精细多维高密、AFHT美学培固、PLCHT超精定位毛囊焕活，NHT（No haircut technology）微针不剃发技术。此次发布会向消费者推出的，正是碧莲盛最新的植发技术——NHT微针无痕不剃发技术。'
      },
      {
        text: '相比传统植发技术需要大面积剃发的现状而言，NHT不剃发技术可以真正做到提取种植全程不剃发，完美避免剃发对发友形象的短期影响，真正做到了即刻美丽无痕。据碧莲盛临床医疗数据显示，来到碧莲盛咨询、植发的多为20-30岁之间的年轻人，其中26岁左右的人群植发需求最为强烈，他们面临着就业、交友等多个人生重要时刻，对不剃发的技术需求更为迫切。'
      },
      {
        text: '娜介绍，NHT微针不剃发技术拥有三个优势，一是在植发时移植区发型不变，仅对毛囊供区进行均匀分散式提取，无需剃发即可实现隐形植发过程，彻底告别以往“先剃发再植发”的传统方式；二是采用碧莲盛最新技术提取，毛囊选取更精准、更均匀。整个过程微创无痛, 提取的毛囊完整度更高、无损伤，使术后恢复快不留痕迹；三是可根据毛发生长的最终形态选取合适的细软或粗壮毛发移植，术后效果更加自然，保留原有头发的长度和浓密,不影响个人形象。'
      },
      {
        text: '对于碧莲盛勇于突破、不断进取的精神，业界一直给予高度评价。复旦大学附属华山医院皮肤科手术室主任、植发中心主任吴文育表示，多年来碧莲盛一直用技术创新给行业带来惊喜，推动植发行业不断前行。我非常看好NHT不剃发植发技术，它让发友能够更快回归工作和生活。'
      },
      {
        text: '尤丽娜深信，服务为本、技术为根，只有掌握核心技术才能根深蒂固，才是对发友最好的回馈，也才能在激烈的竞争中立于不败之地。'
      },
      {
        smallTile: '匠心筑梦，植发从来没有捷径可走'
      },
      {
        text: '头发的缺失，影响的不仅仅是容貌，对患者的自信心更是一种巨大伤害。而植发是目前解决脱发问题最直接、最有效的方式。对于患者而言，医疗机构是否具备合法合规资质、医护团队是否具有相应医疗技能是首要关心的问题。'
      },
      {
        text: '据了解，一名患者一般需要移植上千个毛囊单位，整个手术过程中需要多名医护人员相互配合，用时少则四五个小时，多则十几个小时。尤丽娜说，植发从来没有捷径可走，不存在速成。植发手术必须由正规执业医师才可以操作，而一名正规专业的植发医师需要数年去培养，需要多年经验才能主刀。'
      },
      {
        text: '为此，碧莲盛建立了严格的选聘考核制度，对医护人员进行长期专业的培训，组建了一支技术精湛的医护团队，目前已有超过100位实战派植发医生，专业医护团队更是超过400人，其中有行业内唯一一位享受国务院特殊津贴的专家。'
      },
      {
        text: '为了把技术提上来、把速度练起来，碧莲盛的医护人员都经历了非常艰苦的学习过程。尤丽娜表示，一个优秀的植发医生至少需要500台手术的经验积累。而如今，经过大量的实践积累，碧莲盛医生们在手术过程中的速度、流畅度、美感度令人震撼。采植疾如风，精妙夺天功，很难想象是人手工操作完成的，这就是匠心精神的体现。'
      },
      {
        text: '精心的治疗、显而易见的术后效果在发友之间口口相传。随着时间的推移,碧莲盛实实在在的植发技术让咨询电话及上门求助的患者络绎不绝。'
      },
      {
        text: '“我觉得碧莲盛每一步都走得很实，这么多年来我始终对团队强调，我们夯实技术，把精力都用在练‘内功’上，‘好’字要让发友说出来。”尤丽娜说。'
      },
      {
        smallTile: '仁心惠民，赋能美好生活需要'
      },
      {
        text: '庞大的市场需求造就了快速发展的植发市场，同时也引发了诸多行业乱像，产品以次充好、无从医资格、收费乱定价、虚假宣传、隐患操作等现象突出，这些都对市场产生了不良影响。为了抢夺市场，有些企业展开了激烈的广告战和价格战。据统计，国内植发行业广告投入常年居高不下，每年广告投入高达30多亿元。'
      },
      {
        text: '在尤丽娜看来，靠广告和价格不能赢得真正的口碑。在发展过程中，碧莲盛稳扎稳打，在开店策略上，采用了“一城一店”直营连锁模式，把资源精力都投入到满足用户需求上，通过标准化、规范化、诚信化服务运营体系，不断强化在市场的核心竞争力。目前，碧莲盛拥有32家直营连锁机构，并在持续布局中。'
      },
      {
        text: '2018年，碧莲盛获华盖资本5亿元战略投资。投资方表示，碧莲盛提供的植发服务在精细度和专业性方面具有极好的口碑，未来碧莲盛完全有实力将业务进一步扩展到国内更多的城市乃至国际市场。'
      },
      {
        text: '在资金使用方面，碧莲盛目前研发投入占到营收的1/4，医生专业技术学习培训、考察、锻造再提升等方面投入非常大。在尤丽娜看来，将资金更多用在技术研发、对患者关心、给患者创造良好医疗条件，才是真正地为患者服务，才是真正的医者仁心。'
      },
      {
        text: '近年来，碧莲盛积极参与各项社会公益事业，定期举办公益讲座等活动为广大发友提供免费咨询和养护指导。与此同时，碧莲盛还成立了“碧莲盛千万植发基金”，持续帮助贫困脱发人群重拾信心，并建立完善的信息反馈系统。截至目前，“碧莲盛千万植发基金”已帮助千余人。'
      },
      {
        text: '“植发行业之所以存在并发展，就是因为有一部分人渴望拥有完美的形象。”尤丽娜最后说，我提倡美好、期待美好，而且我创办碧莲盛的初衷就是满足人民对美好生活的向往、对美好形象的追求，我认为所有人都有追求美的权利，我希望可以帮助到更多不富裕的脱发人群，让他们因为形象的美好而有更高的幸福感。'
      },

    ]
  },

  {
    title: '2020国际质造节圆满落幕，碧莲盛植发荣获两大奖项',
    date: '发布时间：2020-07-29',
    list: [{
        text: '7月29日，以“质·创未来”为主题的2020国际质造节在北京圆满落幕。碧莲盛植发凭借强大的技术创新能力，良好的公众综合评价和品牌影响力，与TCL、科大讯飞、飞鹤、浪潮信息、西门子医疗、碧水源、红豆集团、德力西电气等公司，一起荣获“2020匠心质造奖-杰出企业奖”，碧莲盛植发创始人尤丽娜荣获“2020匠心质造奖-杰出人物奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/13/1.png`
      },
      {
        text: '如今，“质造”的含义正在发生深刻的变化，已经不单纯代表着质量的好坏，更是技术服务、品牌创意、智能创新、产品功能的全方位升级。在此背景下，2020国际质造节邀请了具有全球视野的专家学者、商业领袖、创新经营者等“质造”品牌头部力量，多种形式展示质造成果，传递质造精神，探寻企业高品质成长路径。'
      },
      {
        smallTile: '垂直深耕16年，微针技术领跑行业前沿'
      },
      {
        text: '作为我国最早成立的植发机构之一，碧莲盛植发早在2005年就成立了第一家植发医院。在创始人尤丽娜的带领下，碧莲盛的技术团队将研究重心放在了FUE植发技术上，经过深入了解，尤丽娜认为当时流行的种植器械宝石刀和种植笔，都存在着本身固有的一些弊端和缺陷。'
      },
      {
        text: '2007年，碧莲盛摒弃传统种植器械（宝石刀、种植笔），颠覆性创新以微针为载体器械。之后十余年，碧莲盛一直致力于微针植发技术的研发和升级，先后推出了SHT无痕微针植发技术、BHT2.0超精细多维高密微针植发技术、AFHT美学培固微针植发技术、PLCHT超精定位毛囊焕活微针植发技术，可以满足发友的不同需求，并根据发友的不同特点制定精细化的毛发健康方案。'
      },
      {
        text: '对于植发手术而言，具备合法合规资质的医生同样关键，手术时长、毛囊成活率、植发效果都和操作者的熟练程度息息相关。据悉，碧莲盛建立了严格的选聘考核制度，对医护人员进行长期专业的培训，组建了一支技术精湛的医护团队，目前已有超过100位实战派植发医生，专业医护团队更是超过400人，其中有行业内唯一一位享受国务院特殊津贴的专家。'
      },
      {
        text: '尤丽娜表示，“企业只有紧跟世界先进技术水平，在研发上不断投入，才可以拥有核心技术，才能保证自己的技术具有世界一流水平。”在竞争激烈的市场中，碧莲盛正是凭借长久技术积累，才能在中国乃至全球植发技术领域取得领先地位。'
      },
      {
        text: '据了解，碧莲盛是行业唯一一家代表中国连续12年受邀参加世界植发大会，展示中国植发技术的植发医院。如今，碧莲盛已经在全国32个城市开设了直营连锁分院，形成了以省会为中心辐射全国的植发医疗体系，成为植发行业最具影响力的民族品牌。'
      },
      {
        smallTile: '以发友利益为中心，不断推动中国植发技术发展'
      },
      {
        text: '对于脱发患者而言，最关心的莫过于植发效果，而种植器械对植发效果有着很大的影响。随着植发技术的发展，种植器械也不断升级，传统植发一般采用宝石刀打孔种植，口径一般为0.8mm、1.0mm、1.2mm、1.3mm。碧莲盛微针采用全进口定制专用微针，其硬度、长度、精细度最适合亚洲人头皮及毛囊结构，口径极小（将口径进一步升级到0.6mm-0.8mm），穿透性强，创面极小，出血量极少，恢复极快，存活率更高，效果更自然。'
      },
      {
        text: '据介绍，碧莲盛目前正在申请微针实用新型专利、国家医疗器械证书，该技术碧莲盛率先掌握，并即将成为全行业唯一双认证专用种植微针。'
      },
      {
        text: '传统植发一般为多人协作种植，存在着多次接触污染毛囊、不协调、不统一、不专注等问题，影响最终的植发效果。为此，碧莲盛自主研发了单人种植-即插即种技术，手术全程由一名种植师一步精准完成，精准掌握头皮弹性以及种植深浅程度，保证种植的疏密度、深浅度、协调度、美感度。值得注意的是，碧莲盛还是中国植发行业唯一一家采用单人种植技术的植发机构。'
      },
      {
        text: '未来，碧莲盛将继续加大研发投入，在技术、硬件、标准三个领域，紧紧围绕广大发友的服务需求，始终坚持“诚信为本、发友至上”核心理念，不断为患者带来最好的植发服务和体验，推动中国植发行业的健康可持续发展。'
      }
    ]
  },

]
