<template>
    <div>
        <div class="header">
            <img :src="imgUrl[selectTab].img" alt="" />
            <div style="
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 35px 0;
        ">
                <div class="body">
                    <div class="headerAnd">
                        <img :src="`${$url}/logo.png`" alt="" class="logo" />

                        <ul class="tab">
                            <li :class="{ selectTab: selectTab == 0 }" @mouseenter="left = 0.3" @mouseleave="leaveTab"
                                @click="
                                    selectTab = 0;
                                $router.push(`/home`);
                                ">
                                {{ $t("home.title") }}
                            </li>
                            <li :class="{ selectTab: selectTab == 6 }" @mouseenter="left = 13" @mouseleave="leaveTab"
                                @click="
                                    selectTab = 6;
                                $router.push(`/aboutUs`);
                                ">
                                关于我们
                            </li>
                            <li :class="{ selectTab: selectTab == 1 }" @mouseleave="leaveTab" @click="
                                selectTab = 1;
                            $router.push(`/journalism`);
                            " @mouseenter="left = 28.2">
                                新闻动态
                            </li>
                            <li :class="{ selectTab: selectTab == 2 }" @mouseleave="leaveTab" @click="
                                selectTab = 2;
                            $router.push(`/responsibility`);
                            " @mouseenter="left = 43.2">
                                社会责任
                            </li>
                            <li :class="{ selectTab: selectTab == 3 }" @mouseleave="leaveTab" @click="
                                selectTab = 3;
                            $router.push(`/Investor`);
                            " @mouseenter="left = 59.5">
                                投资者关系
                            </li>
                            <li :class="{ selectTab: selectTab == 4 }" @mouseleave="leaveTab" @click="
                                selectTab = 4;
                            $router.push(`/business`);
                            " @mouseenter="left = 75.8">
                                业务模块
                            </li>
                            <li :class="{ selectTab: selectTab == 5 }" @mouseleave="leaveTab" @click="
                                selectTab = 5;
                            $router.push('/contactUs');
                            " @mouseenter="left = 91">
                                联系我们
                            </li>
                            <div class="buoy" :style="{ left: left + '%' }">
                                <div class="sds"></div>
                            </div>
                        </ul>
                        <ul class="language">
                            <li :class="{ 'select-language': selectLanguage == 0 }" @click="seletLang(0, 'zh-CN')">
                                简体
                            </li>
                            <li :class="{ 'select-language': selectLanguage == 1 }" @click="seletLang(1, 'sc-CN')">
                                繁体
                            </li>
                            <li :class="{ 'select-language': selectLanguage == 2 }" @click="seletLang(2, 'en-US')">
                                &nbsp;EN&nbsp;
                            </li>
                        </ul>
                    </div>

                    <div class="body wow fadeInUp text-en" style="font-size: 18px; position: absolute; bottom: 40%">
                        <div class="header-text">
                            {{ imgUrl[selectTab].title }}
                        </div>
                        {{ imgUrl[selectTab].text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
      left: 0.39,
      isEixed: false,
      selectTab: 0,
      selectLanguage: 0,
      imgUrl: [
        {
          img: `${this.$url}banner/%E9%A6%96%E9%A1%B5BANNER-3.jpg`,
          title: "技术创造美好 品质引领未来",
          text: "Technology creates beauty , Quality leads the future",
        },
        {
          img: `${this.$url}banner/%E6%96%B0%E9%97%BB%E5%8A%A8%E6%80%81BANNER.jpg`,
          title: "新闻动态",
          text: "Press Release",
        },
        {
          img: `${this.$url}banner/%E7%A4%BE%E4%BC%9A%E8%B4%A3%E4%BB%BBBANNER.jpg`,
          title: "社会责任",
          text: "EPIDEMIC PUBLIC WELFARE",
        },
        {
          img: `${this.$url}banner/%E6%8A%95%E8%B5%84%E8%80%85%E5%85%B3%E7%B3%BBBANNER.jpg`,
          title: "投资者关系",
          text: "Investor relations",
        },
        {
          img: `${this.$url}banner/%E4%B8%9A%E5%8A%A1%E6%A8%A1%E5%9D%97BANNER.jpg`,
          title: "业务模块",
          text: "Business",
        },
        {
          img: `${this.$url}banner/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%ACBANNER.jpg`,
          title: "联系我们",
          text: "Contact us",
        },
        {
          img: `${this.$url}banner/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%ACBANNER2.jpg`,
          title: "植发不剃发 就到碧莲盛",
          text: "Lotus No Haircut Transplant",
        },
      ],
    };
  },
  watch: {
    "$route.path": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        switch (this.$route.path) {
          case "/home":
            this.selectTab = 0;
            break;
          case "/journalism":
            this.selectTab = 1;
            break;
          case "/responsibility":
            this.selectTab = 2;
            break;
          case "/Investor":
            this.selectTab = 3;
            break;
          case "/business":
            this.selectTab = 4;
            break;
          case "/contactUs":
            this.selectTab = 5;
            break;
          case "/aboutUs":
            this.selectTab = 6;
            break;
          case "/journalismInfo":
            this.selectTab = 1;
            break;
        }
        this.leaveTab();
      },
      deep: true,
    },
  },

  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll, false)
  },
  mounted() {
    // 监听视口判断是否需要固定头部导航
    // window.addEventListener('scroll', this.handleScroll, true)

    // 解决页面刷新，标题选中错乱问题

    new this.$wow.WOW({ live: false }).init();
    switch (this.$route.path) {
      case "/home":
        this.selectTab = 0;
        break;
      case "/journalism":
        this.selectTab = 1;
        break;
      case "/responsibility":
        this.selectTab = 2;
        break;
      case "/Investor":
        this.selectTab = 3;
        break;
      case "/business":
        this.selectTab = 4;
        break;
      case "/contactUs":
        this.selectTab = 5;
        break;
      case "/aboutUs":
        this.selectTab = 6;
        break;
      case "/journalismInfo":
        this.selectTab = 1;
        break;
    }
    this.leaveTab();
  },
  methods: {
    leaveTab() {
      switch (this.selectTab) {
        case 0:
          this.left = 0.3;
          break;
        case 6:
          this.left = 13;
          break;
        case 1:
          this.left = 28.2;
          break;
        case 2:
          this.left = 43.2;
          break;
        case 3:
          this.left = 59.5;
          break;
        case 4:
          this.left = 75.8;
          break;
        case 5:
          this.left = 91;
          break;
      }
    },
    seletLang(idnex, data) {
      this.selectLanguage = idnex;
    //   setI18nLanguage(data);
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100 && this.isEixed !== true) {
        this.isEixed = true;
      } else if (scrollTop <= 100 && this.isEixed !== false) {
        this.isEixed = false;
      }
    },
  },
};
</script>

<style lang="scss">
@keyframes isFixed {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    100% {
        transform: none;
    }
}

.header {
    >img {
        width: 100%;
        z-index: -1;
    }

    overflow: hidden;
    position: relative;
    margin: 0 auto;
    background-size: cover;
    color: #ffffff;
    background-size: 100%;
    background-repeat: no-repeat;
}

.logo {
    width: 100px;
    margin-top: -4px;
}

.buoy {
    position: absolute;
    width: 100%;
    top: 25px;
    transition: left 0.5s;

    .sds {
        height: 2px;
        background-color: #ffffff;
        width: 4%;
    }
}

.tab {
    display: flex;
    // width: 667px;
    position: relative;
    justify-content: space-between;
    margin-top: 7px;
    font-size: 16px;
    color: #cfdae2;

    li {
        padding: 0 25px;

        cursor: pointer;
    }

    :first-child {
        padding-left: 0;
    }

    li:hover {
        color: #ffffff;
        font-weight: 700;
        // border-bottom: 2px solid #ffffff !important;
    }
}

.selectTab {
    color: #ffffff;
    font-weight: 700;
}

.language {
    margin-top: 2px;
    cursor: pointer;
    height: 26px !important;
    border-radius: 15px;
    border: solid 1px #cfdae2;
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;

    li {
        padding: 6px 15px;
        text-align: center;
        border-radius: 15px;
        line-height: 15px;
        font-size: 14px;
        color: #cfdae2;
    }
}

.select-language {
    background-color: #cfdae2;
    transform: scale(1.05);
    color: #2e59a7 !important;
}

.header-text {
    font-size: 46px;
    line-height: 96px;
}

.headerAnd {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    // position: fixed;
    // top: 0;
}
</style>