import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './zh/index'
import enUS from './en/index'
import scCN from './sc/index'



Vue.use(VueI18n)
Vue.locale = () => { }

var locale = localStorage.getItem('lang')
// || 'zh-CN'
export const i18n = new VueI18n({
    locale: 'zh-CN',
    fallbackLocale: "zh-CN",
    messages: {
        'zh-CN': Object.assign(zhCN),
        'en-US': Object.assign(enUS),
        'sc-CN': Object.assign(scCN),
    },
})



// 切换中英文调用该方法
export function setI18nLanguage(lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    // localStorage.setItem('lang', lang)
    return lang
}
