<template>
    <div class="header-d">
        <div class="body">
            <div class="headerAnd">
                <img :src="`${$url}/Logo.png`" alt="" class="logo" />
                <ul class="tab">
                    <li :class="{ 'selectTab': selectTab == 0 }" @mouseenter="left = 0.3" @mouseleave="leaveTab"
                        @click="selectTab = 0; $router.push(`/home`)">
                        {{ $t('home.title') }}
                    </li>
                    <li :class="{ 'selectTab': selectTab == 6 }" @mouseenter="left = 13" @mouseleave="leaveTab"
                        @click="selectTab = 6; $router.push(`/aboutUs`)">
                        关于我们
                    </li>
                    <li :class="{ 'selectTab': selectTab == 1 }" @mouseleave="leaveTab"
                        @click="selectTab = 1; $router.push(`/journalism`)" @mouseenter="left = 28.2">
                        新闻动态
                    </li>
                    <li :class="{ 'selectTab': selectTab == 2 }" @mouseleave="leaveTab"
                        @click="selectTab = 2; $router.push(`/responsibility`)" @mouseenter="left = 43.2">
                        社会责任</li>
                    <li :class="{ 'selectTab': selectTab == 3 }" @mouseleave="leaveTab"
                        @click="selectTab = 3; $router.push(`/Investor`)" @mouseenter="left = 59.5">
                        投资者关系
                    </li>
                    <li :class="{ 'selectTab': selectTab == 4 }" @mouseleave="leaveTab"
                        @click="selectTab = 4; $router.push(`/business`)" @mouseenter="left = 75.8">
                        业务模块
                    </li>
                    <li :class="{ 'selectTab': selectTab == 5 }" @mouseleave="leaveTab"
                        @click="selectTab = 5; $router.push('/contactUs')" @mouseenter="left = 91">
                        联系我们
                    </li>
                    <div class="buoy" :style="{ left: left + '%' }">
                        <div class="sds">

                        </div>
                    </div>
                </ul>
                <ul class="language">
                    <li :class="{ 'select-language': selectLanguage == 0 }" @click="seletLang(0, 'zh-CN')">简体</li>
                    <li :class="{ 'select-language': selectLanguage == 1 }" @click="seletLang(1, 'sc-CN')"> 繁体</li>
                    <li :class="{ 'select-language': selectLanguage == 2 }" @click="seletLang(2, 'en-US')">
                        &nbsp;EN&nbsp;
                    </li>

                </ul>
            </div>


        </div>


    </div>
</template>

<script>
import { setI18nLanguage } from '@/utils/lang/index'
export default {
    data() {
        return {
            left: 0.39,
            selectTab: 0,
            selectLanguage: 0,

        }
    },
    methods: {
        leaveTab() {
            switch (this.selectTab) {
                case 0:
                    this.left = 0.3
                    break;
                case 6:
                    this.left = 13
                    break;
                case 1:
                    this.left = 28.2
                    break;
                case 2:
                    this.left = 43.2
                    break;
                case 3:
                    this.left = 59.5
                    break;
                case 4:
                    this.left = 75.8
                    break;
                case 5:
                    this.left = 91
                    break;
            }
        },
        seletLang(idnex,data) {

            this.selectLanguage = idnex

            setI18nLanguage(data)
        },
    },
    mounted() {
        switch (this.$route.path) {
            case '/home':
                this.selectTab = 0
                break;
            case '/journalismInfo':
                this.selectTab = 1
                break;
            case '/journalism':
                this.selectTab = 1
                break;
            case '/responsibility':
                this.selectTab = 2
                break;
            case '/Investor':
                this.selectTab = 3
                break;
            case '/business':
                this.selectTab = 4
                break;
            case '/contactUs':
                this.selectTab = 5
                break;
            case '/aboutUs':
                this.selectTab = 6
                break;

        }
        this.leaveTab()
    }
}
</script>

<style lang="scss" scoped>
.header-d {

    margin: 0 auto;
    height: 100px;
    background-color: #2e59a7;

    color: #ffffff;
    position: fixed;
    top: 0;
    width: 100vw;
    padding-top: 35px;
    z-index: 99;
}

.logo {
    width: 152px;
    height: 32px;
}

.tab {
    display: flex;
    // width: 667px;
    position: relative;
    justify-content: space-between;
    margin-top: 7px;
    font-size: 16px;
    color: #cfdae2;


    li {
        padding: 0 18px;

        cursor: pointer
    }

    :first-child {
        padding-left: 0;
    }
}

.selectTab {
    color: #ffffff;

}

.language {
    cursor: pointer;

    border-radius: 15px;
    border: solid 1px #cfdae2;
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;

    li {

        padding: 6px 15px;
        text-align: center;


        font-size: 16px;
        color: #cfdae2;
    }
}

.select-language {
    background-color: #cfdae2;
    border-radius: 15px;
    color: #2e59a7 !important;
}

.header-text {
    font-size: 46px;
    line-height: 96px;
}

.headerAnd {
    width: 1400px;
    display: flex;
    justify-content: space-between;

}

.buoy {
    position: absolute;
    width: 100%;
    top: 25px;
    transition: left 0.5s;


    .sds {
        height: 2px;
        background-color: #ffffff;
        width: 4%;
    }
}
</style>

