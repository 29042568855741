<template>
    <div class="Investor publicTop" id="Investors">
        <div class="body wow fadeInUp">
            <div class="date-line">
                <div :class="{ 'select-line': selectLine == 1 }" @mouseenter="selectLine = 1">
                    战略融资
                </div>
                <div :class="{ 'select-line': selectLine == 2 }" @mouseenter="selectLine = 2">
                    动脉网估值
                </div>
                <div :class="{ 'select-line': selectLine == 3 }" @mouseenter="selectLine = 3">
                    公司治理
                </div>
                <div :class="{ 'select-line': selectLine == 4 }" @mouseenter="selectLine = 4">
                    联系方式
                </div>

            </div>
            <div>
                <div class="item" v-show="selectLine == 1">
                    <div>
                        <div style="margin-bottom: 23px;" class="left-cn">战略融资</div>
                        <div class="left-ch" style="color: #707070;margin-bottom: 62px;">STRATEGIC FINANCING</div>
                    </div>
                    <div class="right">
                        <div>
                            2018年1月，华盖资本旗下华盖医疗健康基金牵头组成的投资联合体完成了对中国医美行业植发企业碧莲盛的战略控股投资，总投资金额5亿元。据了解，这是目前华盖资本对医美细分领域最大的一笔战略投资。
                        </div>
                        <div class="right-p">
                            作为中国毛发健康领域的高品质企业，在华盖资本的强力加持下，碧莲盛继续在在植发技术研发、直营医院建设、服务体系升级、人才吸纳培养等方面并线发展，实现了从传统医院运营模式到毛发健康生态链模式的升级转变，为发友提供先进的毛发技术解决方案。
                        </div>
                        <img :src="`${$url}Investor/Investor1.jpg`" alt="" style="width: 100%;">
                    </div>
                </div>
                <div class="item" v-show="selectLine == 2">
                    <div>
                        <div style="margin-bottom: 23px;" class="left-cn">动脉网估值</div>
                        <div style="color: #707070;margin-bottom: 62px;" class="left-ch">SCHOLARLY LOTUS</div>
                    </div>
                    <div class="right">
                        <div style="margin-bottom: 40px;">
                            2022年6月15日，“2022未来医疗100强主榜”发布。我们惊喜地发现，碧莲盛已连续数年闯进“创新医疗服务榜”，并创下估值新高。入选2020届“未来医疗100强”时，其估值仅为20亿元。两年时间，碧莲盛已完成估值翻倍，如今估值达45亿元。
                        </div>

                        <img :src="`${$url}Investor/Investor2.jpg`" alt="" style="width: 100%;">
                    </div>
                </div>
                <div class="item" v-show="selectLine == 3">
                    <div>
                        <div style="margin-bottom: 23px;" class="left-cn">公司治理</div>
                        <div style="color: #707070;margin-bottom: 62px;" class="left-ch">MANAGEMENT</div>
                    </div>
                    <div class="right-Tree">
                        <div class="you">
                            <div class="name">尤丽娜</div>
                            <div class="EnName">YOU LINA</div>
                            <div class="right-line"></div>
                            <div class="post">董事长、创始人</div>
                        </div>
                        <div class="liu">
                            <div class="name">刘 争</div>
                            <div class="EnName">LIU ZHENG</div>
                            <div class="right-line"></div>
                            <div class="post">总经理</div>
                        </div>
                        <div class="shi">
                            <div class="name">师晓炯</div>
                            <div class="EnName">SHI XIAOJIONG</div>
                            <div class="right-line"></div>
                            <div class="post">董事长助理兼副总经理</div>
                        </div>
                        <div class="shi">
                            <div class="name">张 琦</div>
                            <div class="EnName">ZHANG QI</div>
                            <div class="right-line"></div>
                            <div class="post">副总经理</div>
                        </div>
                        <div class="shi">
                            <div class="name">吴文跃</div>
                            <div class="EnName">WU WENYUE</div>
                            <div class="right-line"></div>
                            <div class="post">副总经理</div>
                        </div>


                    </div>
                </div>
                <div class="item" v-show="selectLine == 4">
                    <div>
                        <div style="margin-bottom: 23px" class="left-cn">联系方式</div>
                        <div style="color: #707070;margin-bottom: 62px;" class="left-ch">CONTACT INFORMATION</div>
                    </div>
                    <div class="right-floow">
                        <div class="wx" style="margin-top: 0;">微信公众号</div>
                        <img :src="`${$url}contactUs/wx.png`" alt="" class="wx-img">
                        <div class="wx">公司地址</div>
                        <div class="item-wx" style="color: #3b3b3b;">北京市大兴区西红门鸿坤广场B座3号楼5层</div>
                        <div class="wx">邮箱</div>
                        <div class="item-wx" style="color: #3b3b3b;">pr@blsgroup.cn</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wow: null,
            selectLine: 1,
        }
    },

    beforeDestroy() {
        this.wow.stop()
    },
    mounted() {
        document.title = '投资者关系-碧莲盛医疗'
        if (this.$route.query.id) {

            document.querySelector(`#Investors`).scrollIntoView(true)
            this.selectLine = this.$route.query.id
        }
        if (this.$route.params.name) {
            document.documentElement.scrollTop = 0
        }
        this.wow = new this.$wow.WOW({ live: false })
        this.wow.init()


    }
}
</script>

<style  scoped lang="scss">
.Investor {

    background-color: #f9fafd;

}

.date-line {
    width: 1400px;
    margin-bottom: 80px;
    display: flex;

    >div {
        border-top: 2px solid #c8c8c8;
        padding-top: 13px;
        padding-left: 30px;
        font-size: 22px;
        width: 25%;
        color: #363636;
        cursor: pointer;
    }

    >div:nth-child(1) {
        padding-left: 0px !important;
    }
}

.select-line {

    border-top: 2px solid #2e59a7 !important;
    color: #2e59a7 !important;
}

.right {
    width: 750px;

    div {
        width: 100% !important;
    }
}

.right-Tree {
    display: flex;
    flex-wrap: wrap;
    width: 750px;

    .name {
        font-size: 25px;
        font-weight: bold;
        color: #363636;
    }

    .EnName {
        font-size: 16px;
        margin: 15px 0 27px 0;
        color: #707070;
    }

    .post {
        font-size: 16px;
        color: #707070;
    }

    .right-line {
        width: 17px;
        height: 1px;
        background-color: #cdcdcd;
        margin-bottom: 23px;
    }

    >div {
        padding: 51px 0 50px 29px;
        margin-bottom: 27px;
        margin-right: 27px;
    }

    >div:nth-child(2),
    >div:nth-child(5) {
        margin-right: 0
    }

    >div:hover {
        background-color: #f1f5f9;
    }

    .you {
        width: 360px;
        height: 220px;

        background-color: #ffffff;
    }

    .liu {
        width: 360px;
        height: 220px;
        background-color: #ffffff;
    }

    .shi {
        width: 230px;
        height: 220px;
        background-color: #ffffff;
    }
}

.right div {
    color: #707070;
    line-height: 30px;
    width: 716px;
    font-size: 16px;
}

.item {
    display: flex;
    justify-content: space-between;
    animation: scaleItem 1s linear both;
    transition: animation 1s;
}

@keyframes scaleItem {
    0% {
        opacity: 0;
    }

    100% {

        opacity: 1;


    }
}

.wx {
    color: #707070;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.right-p {
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 40px;
}

.right-floow {
    width: 670px;
}

.item-wx {
    font-size: 20px;
}

.wx-img {
    width: 140px;
    height: 140px;
}
</style>






