<template>
  <div class="contactUs publicTop">
    <div class="body" style="display: flex; justify-content: space-between">
      <div class="left">
        <div class="top">碧莲盛医疗集团总部</div>
        <div class="item">电话：<span>400-822-1660</span></div>
        <div class="item">
          地址：<span>北京市大兴区西红门鸿坤广场B座3号楼5层</span>
        </div>
        <div class="item">邮编：<span>100162</span></div>
        <div class="wx">微信公众号</div>
        <img :src="`${$url}/contactUs/wx.png`" alt="" class="wx-img" />
      </div>
      <div class="right">
        <!-- <div id="container" style="height: 100%; width: 100%"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      wow: null,
      markerArr: [
        {
          lable: '北京市大兴区西红门鸿坤广场B座3号楼5层',
          point: [116.353269, 39.796477]
        }
      ]
    }
  },
  beforeDestroy () {
    this.wow.stop()
  },
  mounted () {
    document.title = '联系我们-碧莲盛医疗'
    document.documentElement.scrollTop = 0
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
  }
}
</script>

<style scoped lang="scss">
.contactUs {
  background-color: #f9fafd;
}

.right {
  width: 750px;
  height: 528px;
  background: url("@/assets/map.png") no-repeat center center;
  background-size: cover;
}

.left {
  width: 429px;

  .top {
    font-size: 25px;
    color: #3b3b3b;
    padding-bottom: 37px;
    border-bottom: solid 2px #c8c8c8;
    margin-bottom: 37px;
  }

  .item {
    font-size: 16px;
    color: #707070;
    line-height: 40px;

    span {
      vertical-align: bottom;
      line-height: 40px;
      color: #3b3b3b;
    }
  }
}

.wx {
  color: #707070;
  font-size: 16px;
  margin-top: 56px;
  margin-bottom: 10px;
}

.wx-img {
  width: 140px;
  height: 140px;
}
</style>
