<template>
  <div>
    <div class="home publicTop" id="brief">
      <div class="body" style="display: flex; justify-content: space-between">
        <div class="left">
          <div class="left-cn">企业简介</div>
          <div class="left-ch">BRAND INTRODUCTION</div>
        </div>
        <div class="right">
          碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。19年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。
          <div>
            在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。
          </div>
          <div>
            目前，碧莲盛在全国开设了42家直营机构，拥有100余位执业医生，700余位执业护士，其中，18位主任/副主任医师，为49.8万发友解决了脱发困扰。
          </div>
        </div>
      </div>
    </div>
    <div class="layout publicTop" id="layout">
      <div class="body">
        <div class="wow fadeInUp" data-wow-duration="1s">
          <div class="left-cn">战略布局</div>
          <div class="left-ch">BRAND LAYOUT</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div class="layout-left wow fadeInUp">
            <div style="">
              碧莲盛是一家辐射全国的大型连锁植发机构，自成立以来，致力于为全国发友带来无差异的、高标准的植发服务。凭借先进的植发技术、安全贴心的医疗服务、深厚的用户口碑，碧莲盛先后在全国开设了40余家直营机构，不断推进实体旗舰医院的战略布局。
            </div>
            <div style="">
              目前，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国院部保持统一的视觉形象输出，同时对前台、顾问、医护团队进行统一的培训，全国所有家分院遵循统一的管理标准，以确保全国用户都能享受到品质如一的服务。
            </div>
          </div>
          <div class="layout-map">
            <div
              class="map"
              :style="{
                backgroundImage: `url(https://static.drlianzhuren.com/HairTransplant/home/strategicLayout.jpg?v=2)`,
              }"
            ></div>
            <!-- <div
              class="map"
              :style="{
                backgroundImage: `url(https://static.drlianzhuren.com/HairTransplant/home/map.png)`,
              }"
            >
              <div class="box"></div>
              <div class="box" style="top: 358px; left: 315px"></div>
              <div class="box" style="top: 57px; left: 525px"></div>
              <div class="box" style="top: 86.4px; left: 530px"></div>
              <div class="box" style="top: 86.4px; left: 484px"></div>
              <div class="box" style="top: 80px; left: 419px"></div>
              <div class="box" style="top: 119px; left: 382px"></div>
              <div class="box" style="top: 96px; left: 422px"></div>
              <div class="box" style="top: 61px; left: 460px"></div>
              <div class="box" style="top: 76px; left: 345px"></div>
              <div class="box" style="top: 76px; left: 384px"></div>
              <div class="box" style="top: 82px; left: 403px"></div>
              <div class="box" style="top: 49px; left: 432px"></div>
              <div class="box" style="top: -19px; left: 320px"></div>
              <div class="box" style="top: -28px; left: 295px"></div>
              <div class="box" style="top: 48px; left: 326px"></div>
              <div class="box" style="top: 67px; left: 393px"></div>
              <div class="box" style="top: 26px; left: 432px"></div>
              <div class="box" style="top: -9.6px; left: 451px"></div>
              <div class="box" style="top: -220px; left: 341px"></div>
              <div class="box" style="top: -105px; left: 301px"></div>
              <div class="box" style="top: -38px; left: 263px"></div>
              <div class="box" style="top: -105px; left: 364px"></div>
              <div class="box" style="top: -163px; left: 470px"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="Itsbrands publicTop" id="Itsbrand">
      <div class="body">
        <div class="wow fadeInUp">
          <div class="left-cn">旗下品牌</div>
          <div class="left-ch">OWN BRANDS</div>
        </div>
        <div class="Itsbrands-img wow fadeInUp" data-wow-delay="0.3s">
          <div
            class="Itsbrands-img-left"
            :style="{ backgroundImage: `url(${$url}/home/bls.jpg)` }"
          >
            <div>
              <img
                :src="`${$url}/home/ItsBrands1.png`"
                alt=""
                style="width: 372px"
              />

              <div class="Understand">
                <a href="https://zhifa.blsgroup.cn"> 了解品牌</a>
              </div>
            </div>
          </div>
          <div
            class="Itsbrands-img-right"
            :style="{ backgroundImage: `url(${$url}/home/lzr.jpg)` }"
          >
            <div>
              <img
                :src="`${$url}/home/yfLOGO.png`"
                alt=""
                style="width: 320px"
              />
              <div class="Understand">
                <a href="https://drlian.blsgroup.cn">了解品牌</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news publicTop" id="news">
      <div class="body">
        <div class="wow fadeInUp" style="text-align: center">
          <div class="left-cn">新闻动态</div>
          <div class="left-ch">PRESS RELEASE</div>
        </div>
        <div class="new-ms">
          <div class="news-left">
            <router-link :to="{ path: '/journalismInfo1', query: { id: 0 } }">
              <img
                :src="`${$url}aboutUs/%E9%A6%96%E9%A1%B5-%E6%96%B0%E9%97%BB%E5%8A%A8%E6%80%81%E5%A4%A7%E5%9B%BE.jpg`"
                alt=""
              />
              <div class="img-ms">
                估值再创新高，“头部”企业碧莲盛如何实现逆势增长？
              </div>
            </router-link>
          </div>
          <div class="news-right">
            <vueSeamlessScroll
              :data="listData"
              class="seamless-warp"
              :class-option="classOption"
            >
              <div v-for="(item, index) in listData" :key="index">
                <router-link
                  :to="{ path: '/journalismInfo1', query: { id: item.route } }"
                >
                  <div class="news-item">
                    <img :src="item.src" alt="" class="news-img" />
                    <div class="news-item-right">
                      {{ item.text }}
                      <div class="date">{{ item.date }}</div>
                    </div>
                  </div>
                </router-link>
              </div>
            </vueSeamlessScroll>
          </div>
        </div>
        <div
          class="lookMore"
          @click="
            $router.push({ name: 'Journalism', params: { name: 'Journalism' } })
          "
        >
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Milepost from "swiper";
// import "swiper/css/swiper.css";
// import Swiper from "./swiper.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },

  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: this.listLenght, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },

  mounted() {
    document.title = "碧莲盛医疗";
    if (this.$route.query.id) {
      document.querySelector(`#${this.$route.query.id}`).scrollIntoView(true);
    }
    if (this.$route.params.name) {
      document.documentElement.scrollTop = 0;
    }
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },

  methods: {
    startCountTo() {},
  },
  data() {
    return {
      isEixed: false,
      listData: [
        {
          text: "播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行",
          src: `${this.$url}aboutUs/3.jpg`,
          date: "2022-06-01",
          route: "1",
        },
        {
          text: "碧莲盛荣获胡润百富“最青睐的高端植发品牌”",
          src: `${this.$url}aboutUs/2.jpg`,
          date: "2022-01-20",
          route: "2",
        },
        {
          text: "不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖",
          src: `${this.$url}aboutUs/5.jpg`,
          date: "2022-01-14",
          route: "3",
        },
        {
          text: "不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业",
          src: `${this.$url}aboutUs/4.jpg`,
          date: "2022-01-09",
          route: "4",
        },
        {
          text: "碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性",
          src: `${this.$url}aboutUs/1.jpg`,
          date: "2021-12-28",
          route: "5",
        },
      ],
      wow: null,
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #f9fafd;

  width: 100%;
  // justify-content: space-between;
  color: #000000;
}

.milepost {
  overflow: hidden;
  background-color: #f9fafd;
  padding: 130px 0 0 0;
}

.corporateCulture {
  padding: 130px 0 0 0;
  height: 904px;
  margin-bottom: 12px;
  background-color: #ffffff;
}

.left {
  animation: ani 1s linear both;
}

.right {
  width: 750px;
  line-height: 30px;
  opacity: 0.6;

  font-size: 16px;
  animation: ani 1s linear both;

  div {
    line-height: 30px;
    margin-top: 30px;
  }
}

@keyframes ani {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: none;
  }
}

.list {
  margin-top: 92px;

  display: flex;
  justify-content: space-between;
  color: #2e59a7;
  font-size: 50px;

  > div {
    text-align: center;
    width: 180px;
    height: 180px;
    border-radius: 30px;
    border: solid 1px #2e59a7;
    padding-top: 50px;
  }
}

.circleLoad {
  position: absolute;
  width: 150px;
  height: 150px;

  top: -35px;

  border: solid 1px #2e59a7;
  border-radius: 30px;
  animation: circleScale 3s forwards;
  -webkit-animation: circleScale 3s forwards;
  opacity: 0;
}

@keyframes circleScale {
  0% {
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes circleScale {
  0% {
    -webkit-transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.date {
  animation-delay: 0.5s;
  overflow: hidden;
}

.date-line {
  width: 1220px;
  margin-top: 60px;
  display: flex;

  > div {
    border-top: 4px solid #c8c8c8;
    padding-top: 13px;
    font-size: 22px;
    width: 25%;
    color: #363636;
    cursor: pointer;
  }
}

.select-line {
  border-top: 4px solid #2e59a7 !important;
  color: #2e59a7 !important;
}

.date-item {
  width: 270px;
  margin-right: 40px;
  margin-top: 80px;
}

.date-top-text {
  font-size: 24px;
  color: #363636;
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: solid 2px #c8c8c8;
}

.date-top-body {
  width: 270px;

  img {
    width: 100%;
    border-radius: 6px;
    height: 150px;
    margin-bottom: 10px;
  }

  color: #707070;

  > div {
    line-height: 27px;
    margin-bottom: 30px;
  }
}

.Culture-line {
  width: 720px;
  display: flex;
  justify-content: space-between;

  font-size: 22px;
  color: #707070;

  > div {
    cursor: pointer;
    padding-top: 13px;
    width: 100%;
    border-top: solid 4px #c8c8c8;
  }
}

.select-Culture {
  color: #2e59a7;
  border-top: solid 4px #2e59a7 !important;
}

.Culture-item {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
}

.HonoraryAwards {
  height: 1033px;
  background-color: #f9fafd;
  text-align: center;
  padding-top: 122px;
}

.awards-img {
  display: flex;
  flex-wrap: wrap;

  margin-top: 72px;
  width: 1220px;

  img:nth-child(4n) {
    margin-right: 0;
  }

  > div {
    width: 272px;
    height: 272px;
    transition: all 0.3s;
    // box-shadow: 8px 8px 20px 0px rgba(183, 182, 182, 0.42);

    margin-right: 43px;
    margin-bottom: 43px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .poab {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      transform: scale(0);
      display: flex;
      justify-content: center;
      align-items: center;
      // animation: nopoabanimate 0.3s linear both;

      > div {
        > div {
          line-height: 20px;
        }
      }

      background-color: #2e59a7;
    }
  }

  > div:hover {
    transform: scale(1.05);
    transform-origin: center;
    z-index: 99;
    animation: poabshadow 0.3s linear both;

    .poab {
      animation: poabanimate 0.3s linear both;
    }
  }
}

@keyframes poabanimate {
  0% {
    // transform: scale(0);
    // border-radius: 50%;
    // opacity: 0;
    transform: scale(1.5);
    opacity: 1;
    border-radius: 50%;
  }

  100% {
    transform: scale(1.5);
    opacity: 1;
    border-radius: 50%;
  }
}

.Itsbrands {
  background-color: #f9fafd;
  text-align: center;
}

.Itsbrands-img {
  margin-top: 74px;

  display: flex;
  justify-content: space-between;

  .Itsbrands-img-left {
    border-radius: 5px;

    width: 680px;
    height: 400px;

    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .Itsbrands-img-left:hover {
    .Understand {
      transform: scale(1.05);
      transform-origin: center;
    }
  }

  .Itsbrands-img-right {
    border-radius: 5px;
    transition: all 0.5s;
    width: 700px;
    height: 400px;

    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .Itsbrands-img-right:hover {
    .Understand {
      transform: scale(1.05);
      transform-origin: center;
    }
  }

  .Understand {
    margin: 32px auto;
    width: 157px;
    transition: all 0.5s;
    height: 38px;
    background-color: #ffffff;
    border-radius: 19px;
    line-height: 38px;
    font-size: 22px;
  }
}

.date-fl {
  display: flex;
  flex-wrap: wrap;
  width: 4000px;
  user-select: none;
  cursor: pointer;

  transition: transform 1s;
}

.Culture-item-left {
  min-width: 300px;
}

.layout {
  background-color: #ffffff;

  overflow: hidden;

  .layout-left {
    width: 480px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    margin-top: 79px;
    letter-spacing: 0px;
    color: #707070;

    div {
      line-height: 35px;
      margin-bottom: 30px;
    }
  }
}

.layout-Top {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 96px;
  letter-spacing: 2px;
  color: #acacac;
}

.layout-title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 96px;
  letter-spacing: 0px;
  color: #363636;
}

.layout-line {
  width: 60px;
  height: 4px;
  background-color: #383838;
}

.box {
  position: relative;

  top: 130px;
  left: 133px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #76b8ff;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  animation: animate 1.6s infinite linear;
  animation-delay: -0.8s !important;
}

.box::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #76b8ff;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  animation: animate 1.6s infinite linear;
}

@keyframes animate {
  0% {
    background: #18b6ff;
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(0.3);
    background: rgb(133, 206, 252);
    // opacity: 1;
  }

  100% {
    transform: scale(1);
    background: #ffffff;
    // opacity: 0;
  }
}

// .map {
//   position: relative;
//   background-size: 100% 100%;
//   width: 631px;
//   height: 475px;
//   transform: scale(1.1);
//   transform-origin: left;
// }

.map {
  position: relative;
  top: 12px;
  left: 20px;
  background-size: 100% 100%;
  width: 610px;
  height: 430px;
  transform: scale(1.1);
  transform-origin: left;
}

.layout-map {
  margin-top: -50px;
  width: 700px;
}

@keyframes findleft {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.layout-textfind {
  transition: transform 1s;
  animation: findleft 1s linear both;
}

.isrespon {
  display: inline-block;

  color: #ffffff !important;
  background-color: #2e59a7;
  border-radius: 14px;
  padding: 4px 12px;
  margin-left: -12px !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.reson {
  line-height: 45px;
  color: #707070;
}

.mouseMark {
  cursor: pointer;
}

.my-swiper-slide {
  margin-bottom: 60px;
}

.news {
  .news-left {
    width: 680px;
    cursor: pointer;

    img {
      width: 100%;
      // height: 355px;
      vertical-align: middle;
    }

    div {
      width: 100%;
      height: 64px;
      border: solid 1px #cccccc;
      line-height: 64px;
      text-align: center;
      font-size: 20px;
      color: #363636;
    }
  }

  .news-right {
    height: 480px;
    overflow: hidden;

    .news-item {
      cursor: pointer;
      width: 680px;
      height: 146px;
      padding-top: 18px;
      border-bottom: solid 1px #cccccc;
    }
  }
}

.news-right-fixed {
  transition: animation 3s;
  // infinite
  animation: poabanimate 3s linear infinite;
}

@keyframes poabanimate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -58%, 0);
  }
}

.new-ms {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  .news-item {
    display: flex;
    justify-content: space-between;

    img {
      width: 173px;
      height: 111px;
    }

    .news-item-right {
      width: 490px;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0px;
      color: #363636;
      padding: 5px 0 20px 0;

      .date {
        font-weight: normal;
        font-size: 16px;
        line-height: 100px;
        letter-spacing: 0px;
        color: #707070;
      }
    }
  }
}

.news-img:hover {
  transition: transform 0.1s;
  transform: scale(1.05);
}

.lookMore {
  cursor: pointer;
  width: 140px;
  height: 31px;
  border-radius: 14px;
  border: solid 1px #707070;
  line-height: 30px;
  display: flex;
  justify-content: center;
  margin: 80px auto 0;
  color: #707070;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #000000;
}
</style>
