<template>
  <div class="business">
    <div class="top publicTop" id="noShaving">
      <div class="body">
        <div
          style="display: flex; justify-content: space-between"
          class="wow fadeInUp"
        >
          <div class="left">
            <div class="left-cn">植发</div>
            <div style="color: #707070; margin-bottom: 62px" class="left-ch">
              HAIR TRANSPLANT
            </div>
          </div>
          <div class="right">
            <div>
              根据中国整形美容协会发布的《毛发移植规范》团体标准，植发主要分为毛囊提取和种植两个维度：毛囊提取主要是毛囊单位头皮条切取术（FUT）和毛囊单位钻取术（FUE），毛囊种植主要是镊子种植、种植笔和即插即种。
            </div>

            <div style="margin-top: 30px">
              以上技术均需在手术前剃发，对工作、生活有一定的影响。现在行业最前沿的技术是不剃发植发，实现了毛囊提取、种植技术的双重革新。目前，碧莲盛采用的是毛囊单位钻取术、即插即种和不剃发植发。
            </div>
          </div>
        </div>
        <div class="business-img wow fadeInUp" data-wow-delay="0.3s">
          <div>
            <div
              class="business1"
              :style="{ backgroundImage: `url(${$url}/business/bu1.jpg)` }"
            >
              <div class="business-top">剃发</div>
              <div class="business-body">
                <div style="font-weight: 600">加密</div>
                即插即种
              </div>
              <div class="business-line"></div>
            </div>
          </div>
          <div>
            <div
              class="business2"
              :style="{ backgroundImage: `url(${$url}/business/bu2.jpg)` }"
            >
              <div class="business-top">剃发</div>
              <div class="business-body">
                <div style="font-weight: 600">精密</div>
                即插即种
              </div>
              <div class="business-line"></div>
            </div>
          </div>
          <div>
            <div
              class="business3"
              :style="{ backgroundImage: `url(${$url}/business/bu3.jpg)` }"
            >
              <div class="business-top">不剃发</div>
              <div class="business-body">
                <div style="font-weight: 600">加密</div>
                不剃发植发
              </div>
              <div class="business-line"></div>
            </div>
          </div>
          <div>
            <div
              class="business4"
              :style="{ backgroundImage: `url(${$url}/business/bu4.jpg)` }"
            >
              <div class="business-top">不剃发</div>
              <div class="business-body">
                <div style="font-weight: 600">精密</div>
                不剃发植发
              </div>
              <div class="business-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="system publicTop" id="system">
      <div class="body">
        <div class="left-cn wow fadeInUp">服务保障体系</div>
        <div
          class="wow fadeInUp left-ch system-en"
          style="color: #707070; margin-bottom: 62px"
        >
          SERVICE SYSTEM
        </div>
        <img
          class="systemselect-img wow fadeInUp"
          ref="gImg"
          data-wow-delay="0.3s"
          :src="`${this.$url}/business/system1.jpg`"
          alt=""
          v-if="selectSystem == 0"
        />
        <img
          class="systemselect-img wow fadeInUp"
          ref="gImg"
          data-wow-delay="0.3s"
          :src="`${this.$url}/business/system2.jpg`"
          alt=""
          v-if="selectSystem == 1"
        />
        <img
          class="systemselect-img wow fadeInUp"
          ref="gImg"
          data-wow-delay="0.3s"
          :src="`${this.$url}/business/system3.jpg`"
          alt=""
          v-if="selectSystem == 2"
        />
        <img
          class="systemselect-img wow fadeInUp"
          ref="gImg"
          data-wow-delay="0.3s"
          :src="`${this.$url}/business/system4.jpg`"
          alt=""
          v-if="selectSystem == 3"
        />
        <div class="system-img wow fadeInUp" data-wow-delay="0.5s">
          <div
            class="system-img-Item"
            :class="{ 'select-system': selectSystem == 0 }"
            @mouseenter="mouseenterImg(0)"
            @mouseleave="mouseenterLeave"
          >
            <div class="top-text">精准定制</div>
            <div>
              建立术前沟通机制，根据发友年龄、性别、五官比例及需求量身定制植发方案。
            </div>
          </div>
          <div
            class="system-img-Item"
            :class="{ 'select-system': selectSystem == 1 }"
            @mouseenter="mouseenterImg(1)"
            @mouseleave="mouseenterLeave"
          >
            <div class="top-text">无时无刻</div>
            <div>
              7*24小时，全年365天，全天侯面向发友提供专业毛发健康咨询与服务。
            </div>
          </div>
          <div
            class="system-img-Item"
            :class="{ 'select-system': selectSystem == 2 }"
            @mouseenter="mouseenterImg(2)"
            @mouseleave="mouseenterLeave"
          >
            <div class="top-text">合同保障</div>
            <div>
              术前签订八大服务承诺协议，从法律效益上，明确了发友在植发术前、术中和术后的权益保障。
            </div>
          </div>
          <div
            class="system-img-Item"
            style="padding-right: 25px"
            :class="{ 'select-system': selectSystem == 3 }"
            @mouseleave="mouseenterLeave"
            @mouseenter="mouseenterImg(3)"
          >
            <div class="top-text">保险保障</div>
            <div>
              同中国平安保险达成战略合作，为医护人员提供百万医疗责任险，让发友放心植发，安心变美。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="development publicTop" id="development">
      <div class="body">
        <div style="display: flex; justify-content: space-between">
          <div class="left">
            <div class="wow fadeInUp left-cn">养发</div>
            <div
              class="wow fadeInUp"
              style="font-size: 31px; color: #707070; margin-bottom: 62px"
            >
              HAIR CARE
            </div>
          </div>
          <div class="right wow fadeInUp">
            <div>
              碧莲盛养发，始于2001年，新华网国潮优选品牌，碧莲盛医疗旗下专业养发品牌。21年来，碧莲盛始终专注于毛发健康领域的研究，在深耕植发的同时，积累了丰富的非手术治疗脱发经验和成功案例，帮助近百万客户解决了脱发问题。
            </div>
            <div style="margin-top: 30px">
              碧莲盛养发坚守医疗本质，以客户为中心，独家首创6R头皮管理体系，科学制定7步头皮管理流程，为每一个客户量身定制毛发健康管理方案，提供专业分析和养护指导，由内而外地改善毛发健康，一站式解决毛发健康问题。
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      class="wow fadeInUp"
      :src="`${$url}/business/2.jpg`"
      alt=""
      style="width: 100%; vertical-align: middle"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      wow: null,
      selectSystem: 0,
      timer: null,
    };
  },

  beforeDestroy() {
    this.wow.stop();
    this.stop();
  },
  mounted() {
    document.title = "业务模块-碧莲盛医疗";
    if (this.$route.query.id) {
      document.querySelector(`#${this.$route.query.id}`).scrollIntoView(true);
    }
    if (this.$route.params.name) {
      document.documentElement.scrollTop = 0;
    }
    let that = this;
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    let thst = this;
    this.timer = setInterval(() => {
      thst.selectSystem++;
      if (thst.selectSystem == 4) {
        thst.selectSystem = 0;
      }
    }, 3000);
  },

  methods: {
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseenterImg(index) {
      this.selectSystem = index;
      this.stop();
    },
    mouseenterLeave() {
      let thst = this;

      this.timer = setInterval(() => {
        this.selectSystem++;
        if (thst.selectSystem == 4) {
          thst.selectSystem = 0;
        }
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  background-color: #ffffff;
}

.system {
  background-color: #f9fafd;
}

.right {
  width: 60%;
}

.right div {
  color: #707070;
  line-height: 30px;
  width: 100%;
  font-size: 16px;
}

@keyframes scaleHover {
  0% {
    transform: scale(1);
    // background-size: 100%;
  }

  100% {
    transform: scale(1.1);
    // background-size: 120%;
  }
}

.business-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;

  > div {
    width: 330px;
    height: 330px;
    overflow: hidden;

    > div {
      padding: 46px 0 0 41px;
      background-position: center;
      width: 330px;
      height: 330px;
      margin-bottom: 22px;
      background-size: 100% 100%;
    }

    > div:hover {
      transition: animation 0.3s;
      animation: scaleHover 0.3s linear both;
    }
  }
}

.business-top {
  width: fit-content;
  font-size: 16px;
  padding: 2px 9px;
  transition: all 0.3s;
  line-height: 24px;
  border: solid 1px #ffffff;
  text-align: center;
  color: #ffffff;
}

.business-body {
  transition: all 0.3s;
  font-size: 30px;
  line-height: 56px;
  color: #ffffff;
  margin-top: 50px;
}

.business-line {
  width: 29px;
  height: 2px;
  background-color: #ffffff;
  margin-top: 50px;
}

.system-img-Item {
  width: 25%;
  height: 225px;
  background-color: #ffffff;
  padding: 46px 29px 0 37px;
  font-size: 16px;
  cursor: pointer;
  color: #707070;

  div {
    line-height: 28px;
  }
}

@keyframes system {
  0% {
    width: 305px;
  }

  100% {
    width: 0px;
  }
}

@keyframes systemImg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.systemselect-img {
  width: 100%;
  opacity: 0;
  vertical-align: middle;
  transition: 2s;
  animation: systemImg 2s linear both;
}

.system-img {
  display: flex;
}

.top-text {
  font-size: 25px;
  font-weight: bold;
  color: #363636;
  margin-bottom: 20px;
}

.select-system {
  position: relative;

  background-color: #f1f5f9;
  border-bottom: solid 2px #2e59a7;
}

// .select-system::before {
//     content: "";
//     display: block;
//     position: absolute;
//     height: 2px;
//     width: 100%;
//     background-color: #2e59a7;
//     transition: 3s;
//     animation: system 3s linear both;
//     bottom: 0;
//     right: 1px;
// }

.development {
  background-color: #ffffff;
  overflow: hidden;
}

.snow-container {
  opacity: 0;
}
</style>