<template>
  <div class="Journalism">

    <div class="Journalism-item publicTop" id="vido" style="background-color: #ffffff;">
      <div>
        <div class="wow fadeInUp left-cn">视频报道</div>
        <div class=" left-ch wow fadeInUp">TV
          REPORTS</div>

        <div class="body wow fadeInUp Journalism-item-line" style="display:flex ;flex-wrap: wrap;justify-content: space-between;" data-wow-delay="0.3s">

          <div class="item" style="line-height: 70px;background-color: #f9fafd;" v-for="(item, index) in imgList" :key="index" @click="showT(index)">
            <div class="item-img">

              <div class="item-bofang">
                <img :src="`${$url}Journalism/bf.png`" alt="" style="width: 40%;">
              </div>
              <img :src="item.img" alt="">
            </div>
            {{ item.text }}
          </div>
          <div class="lookMore" @click="lookMore" v-if="isNoAdd" style="margin-top: 20px;">
            查看更多
            <div class="out-top"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="Journalism-item publicTop" id="dynamic">
      <div>
        <div class="wow fadeInUp left-cn">企业动态</div>
        <div class="left-ch wow fadeInUp">COMPANY
          NEWS</div>
        <div class="body wow fadeInUp Journalism-item-line" data-wow-delay="0.3s" style="display:flex ;flex-wrap: wrap;">

          <div class="item" v-for="(item, index) in newsPageList" :key="index + 'a'">
            <div v-if="item.NewsType == 'news'">
              <router-link :to="{ path: '/journalismInfo', query: { id: item.id } }">
                <div class="item-img item-imgT">
                  <img :src="item.pcImgUrl" alt="" style="  height: 280px;">
                </div>
                <div class="item-bottom">
                  <div style="text-align: left;line-height: 25px;">
                    {{ item.title }}
                  </div>
                  <div class="date">
                    {{ item.publishTime }}
                  </div>
                </div>
              </router-link>
            </div>
            <div v-else-if="item.NewsType == 'video'">
              <div class="item-img item-imgT" @click="show(item.id)">
                <div class="item-bofang">
                  <img :src="`${$url}Journalism/bf.png`" alt="" style="width: 40% ;">
                </div>
                <img :src="item.pcImgUrl" alt="" style="  height: 280px;">
              </div>

              <div class="item-bottom">
                <div style="text-align: left;line-height: 25px;">
                  {{ item.title }}
                </div>
                <div class="date">
                  {{ item.publishTime }}
                </div>
              </div>
            </div>
            <div v-else>
              <router-link :to="{ path: '/journalismInfo1', query: { id: index } }">
                <div class="item-img item-imgT">
                  <img :src="item.img" alt="" style="  height: 280px;">
                </div>
                <div class="item-bottom">
                  <div style="text-align: left;line-height: 25px;">
                    {{ item.text }}
                  </div>
                  <div class="date">
                    {{ item.date }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="lookMore" @click="lookMoreT" v-if="isNoAddT" style="margin-top: 20px;">
          查看更多
          <div class="out-top"></div>
        </div>
      </div>
    </div>
    <div id="container" class="video-wrapper"></div>
    <prop @cancel="close" v-show="bol" :url="url" :img="img" :title="title" :propList="propList"></prop>
    <template v-if="bol1">
      <prop1 @cancel="close1" :isSelect="isSelect"></prop1>
    </template>

  </div>
</template>


<script>
import prop from '../components/prop.vue'
import prop1 from '../components/prop1.vue'
export default {
  components: {
    prop,
    prop1
  },
  computed: {
    newsPageList: function () {
      let end = Math.min((this.pageNum + 1) * 6,this.newsList.length)
      return this.newsList.slice(0,end)
    },
    // videoPageList: function() {
    //     let start = (this.pageNum1 - 1) * 6;
    //     let end = start + parseInt(6);
    //     return this.videoList.slice(start, end);
    // },
    // isnewsAdd: function(){
    //     if(this.newsPageList.legnth ==this.newsList.length){
    //        return false
    //     }
    //     return true
    // },
    // isvideoAdd: function(){
    //     let start = (this.pageNum1 - 1) * 6;
    //     let end = start + parseInt(6);
    //     let list = this.videoList.slice(start, end);
    //     console.log(list.legnth,this.videoList.length);
    //     if(list.legnth ==this.videoList.length){
    //        return false
    //     }
    //     return true
    // }
  },

  methods: {
    getUrl(vid) {
      let that = this;
      that.url = vid;
      return false
      $.ajax({
        url: "https://vv.video.qq.com/getinfo?vids=" + vid + "&platform=101001&charge=0&otype=json",
        dataType: 'jsonp',
        jsonp: 'callback',
        success: function (data) {
          let ul = data.vl.vi;
          if (ul.length > 0) {
            let fn = ul[0].fn;
            let vkey = ul[0].fvkey;
            let uis = ul[0].ul.ui;
            that.url = uis[0].url + fn + "?vkey=" + vkey;
          }
        }
      });
    },
    getNewsList() {
      let data = {
        field: "",
        orderByClause: "",
        pageNum: 1,
        pageSize: 9999,
        secondType: 3,
        stairType: "hair_care"
      }
      axios.post(`${this.$request}v1/index/pcNews`,data).then((res) => {
        // let list = res.data.data.data
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        list.forEach(item => item.NewsType = "news")
        this.newsList = [...this.newsList,...list]
      }).catch((err) => {
        console.log(err);
      });
    },
    getVideoList() {
      let data = {
        field: "",
        orderByClause: "",
        pageNum: 1,
        pageSize: 9999,
        secondType: 12,
        stairType: "renowned_clinic"
      }
      axios.post(`${this.$request}v1/index/pcNews`,data).then((res) => {
        // let list = res.data.data.data
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        list.forEach(item => item.NewsType = "video")
        this.newsList = [...this.newsList,...list]
      }).catch((err) => {
        console.log(err);
      });
    },
    close() {
      document.body.style.height = " 100%"
      document.body.style.overflow = "auto";
      this.bol = false;
    },
    close1() {
      document.body.style.height = " 100%"
      document.body.style.overflow = "auto";
      this.bol1 = false;
    },
    show(id) {
      this.title = this.newsList.find(item => item.id == id).title;
      let data = {
        id: id,
        secondType: "12",
        tagId: ""
      }
      axios.post(`${this.$request}v1/info/queryPcVideo`,data).then((res) => {
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        if (list.length < 6) {
          this.propList = list;
        } else {
          this.propList = list.slice(0,6);
        }
        this.getUrl(list[0].content);
        this.img = list[0].imgUrl
      }).catch((err) => {
        console.log(err);
      });
      document.body.style.height = "auto"
      document.body.style.overflow = "hidden";
      this.bol = true;
    },
    showT(index) {
      document.body.style.height = "auto"
      document.body.style.overflow = "hidden";
      this.bol1 = true;
      this.isSelect = index;
    },
    // lookMore() {
    //     this.pageNum1 ++
    // },
    lookMoreT() {
      if (this.newsList.length * this.pageNum >= this.newsPageList.length) {
        this.isNoAddT = false
      }
      this.pageNum = this.pageNum + 1
    },
    lookMore() {
      this.imgList = [
        ...this.imgList,
        ...this.addimgList
      ]

      if (this.imgList.length = 12) {
        this.isNoAdd = false
      }
    },
  },
  data() {
    return {
      pageNum: 0,
      pageNum1: 1,
      bol: false,
      bol1: false,
      isNoAdd: true,
      isNoAddT: true,
      isSelect: 0,
      wow: null,
      propList: [],
      url: "",
      img: "",
      title: "",

      addimgList: [
        {
          img: `${this.$url}Journalism/style7.jpg`,
          text: "CCTV《焦点关注》",
        },
        {
          img: `${this.$url}Journalism/style8.jpg`,
          text: "钛媒体《华楠直播间》",
        },
        {
          img: `${this.$url}Journalism/style9.jpg`,
          text: "山西电视台《黄河新闻》",
        },
        {
          img: `${this.$url}Journalism/style10.jpg`,
          text: "广东电视台《创新广东》",
        },
        {
          img: `${this.$url}Journalism/style11.jpg`,
          text: "甘肃电视台《今日文化君》",
        },
        {
          img: `${this.$url}Journalism/style12.jpg`,
          text: "南昌电视台《新闻说报》",
        },


      ],

      newsList: [
        {
          img: `${this.$url}JournalismInfo/15/6.jpg`,
          text: "碧莲盛植发技术再升级，无痛不剃发植发正式亮相",
          date: '2023-08-28',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/15/7.jpg`,
          text: "碧莲盛发布三大重磅升级，引领植发业高质量发展",
          date: '2023-08-28',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/20/1.png`,
          text: "碧莲盛携手盛美康教育 共促毛发医疗行业升级",
          date: '2023-08-21',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/19/1.png`,
          text: "“睡一觉，头发回来了” 碧莲盛不剃发植发是如何诞生的？",
          date: '2023-08-11',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/18/1.png`,
          text: "积极拥抱年轻消费者 碧莲盛不剃发植发跨界携手国潮音乐嘉年华",
          date: '2023-07-12',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/17/1.png`,
          text: "情暖护士节 碧莲盛礼赞白衣天使",
          date: '2023-05-13',
          NewsType: "old"
        },
        {
          img: `${this.$url}JournalismInfo/sixteen/1.png`,
          text: "碧莲盛董事长尤丽娜荣获“2023中国十大品牌女性”",
          date: '2023-04-10',
          NewsType: "old"
        },

        {
          img: `${this.$url}Journalism/style1.jpg`,
          text: "估值再创新高，“头部”企业碧莲盛如何实现逆势增长？",
          date: '2022-06-15',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style2.jpg`,
          text: "播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行",
          date: '2022-06-01',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style3.jpg`,
          text: "碧莲盛荣获胡润百富“最青睐的高端植发品牌”",
          date: '2022-01-20',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style4.jpg`,
          text: "不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖",
          date: '2022-01-14',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style5.jpg`,
          text: "不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业",
          date: '2022-01-09',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style6.jpg`,
          text: "碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性",
          date: '2021-12-28',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism7.jpg`,
          text: "碧莲盛携手鸿基金启动“爱的翅膀·助力成才计划”",
          date: '2021-03-20',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism8.jpg`,
          text: "专家热议“新消费”：多措并举促进新业态新模式健康发展",
          date: '2021-03-10',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism9.jpg`,
          text: "潮流大秀视听盛宴隆重开幕 碧莲盛时尚盛典名流汇聚尽显奢华",
          date: '2020-11-26',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism10.jpg`,
          text: "斩获两项大奖，碧莲盛成为第七届中国行业影响力品牌峰会焦点",
          date: '2020-08-30',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism11.jpg`,
          text: "创新 匠心 仁心，植发行业赋能美好生活",
          date: '2020-08-18',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism12.jpg`,
          text: "2020国际质造节圆满落幕，碧莲盛植发荣获两大奖项",
          date: '2020-07-29',
          NewsType: "old"
        },

      ],

      imgList: [
        {
          img: `${this.$url}Journalism/Journalism1.jpg`,
          text: "CCTV 《创新之路》",
        },
        {
          img: `${this.$url}Journalism/Journalism2.jpg`,
          text: "CCTV13 《新闻直播间》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism3.jpg`,
          text: "人民网《人民会客厅·两会时刻》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism4.jpg`,
          text: "CCTV1 《生活圈》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism5.jpg`,
          text: "BTV 《品质生活》",
        },
        {
          img: `${this.$url}Journalism/Journalism6.jpg`,
          text: "CCTV 《连线中国》 ",
        },
      ]

    }
  },
  beforeDestroy() {
    this.wow.stop()
  },

  mounted() {
    document.title = '新闻动态-碧莲盛医疗'
    if (this.$route.query.id) {
      document.querySelector(`#${this.$route.query.id}`).scrollIntoView(true)
    }
    if (this.$route.params.name) {
      document.documentElement.scrollTop = 0
    }
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
    this.getVideoList() // 视频
    this.getNewsList()  // 新闻
  }
}
</script>

<style scoped lang="scss">
.Journalism-item {
  text-align: center;
  background-color: #f9fafd;
}

.item {
  width: 450px;

  background-color: #ffffff;
  line-height: 45px;
  font-size: 20px;
  margin-bottom: 20px;
  // color: #a2a2a2;
  cursor: pointer;
  transition: all 0.2s linear;

  .item-img {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;

    .item-bofang {
      opacity: 0;
      position: absolute;
      top: 50%;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .item-imgT {
    width: 100%;
    height: 280px;
  }
}

.item:nth-child(3n) {
  margin-right: 0;
}

.lookMore {
  cursor: pointer;
  width: 140px;
  height: 31px;
  border-radius: 14px;
  border: solid 1px #707070;
  line-height: 30px;
  display: flex;
  justify-content: center;
  margin: 0px auto 0;
  color: #707070;
  font-size: 16px;
}

.out-top {
  width: 10px;
  height: 10px;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.8;
  float: right;
  margin-top: 3px;
  margin-left: 5px;
}

.date {
  text-align: left;
  font-size: 16px;
  margin-top: 28px;
  color: #707070;
}

.item-bottom {
  font-size: 20px;
  padding: 20px 29px 20px 18px;
}

.item:hover {
  img {
    transform: translateZ(0) scale(1.1);
    transition: transform 1s;
  }

  .item-bofang {
    opacity: 0.6;
  }
}

.Journalism-item-line {
  margin-top: 62px;

  > div:nth-child(3n-1) {
    margin: 0 20px;
  }
}

a {
  text-decoration: none;
  color: #000000;
}
</style>