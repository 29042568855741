 <template>
    <div class="responsibility">
        <div class="top publicTop" id="Epidemic">
            <div class="body">
                <div class="left-cn wow fadeInUp">疫情公益</div>
                <div class="wow fadeInUp left-ch Epidemic-top"
                    style="margin-bottom: 62px;display: flex;justify-content: space-between;color: #707070;">
                    EPIDEMIC PUBLIC
                    WELFARE
                    <div class="reTop wow fadeInUp">
                        <div class="reTop-item" :class="{ 'selectItem': selectCulture == 1 }"
                            @mouseenter="selectCulture = 1">武汉疫情</div>
                        <div class="reTop-item" style="text-align: center;"
                            :class="{ 'selectItem': selectCulture == 2 }" @mouseenter="selectCulture = 2">河北疫情</div>
                        <div class="reTop-item" style="text-align: right;" :class="{ 'selectItem': selectCulture == 3 }"
                            @mouseenter="selectCulture = 3">支援核酸</div>
                    </div>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.3s">

                    <div class="resImg" v-show="selectCulture == 1">
                        <img :src="`${$url}/home/milepost/wh.jpg`" alt="">
                        <div class="res-right">
                            <div class="res-title">武汉疫情</div>
                            <p>
                                武汉新冠疫情爆发后，成立100万新型冠状病毒肺炎专项基金，用于采购专用医疗设备、口罩、防护服等，并分批赠送疫情前线。
                            </p>
                            <div class="res-line"></div>
                        </div>
                    </div>
                    <div class="resImg" v-show="selectCulture == 2">
                        <img :src="`${$url}/home/milepost/hb.jpg`" alt="">
                        <div class="res-right">
                            <div class="res-title">河北疫情</div>
                            <p>
                                河北新冠疫情爆发后，迅速采购专业防疫物质，驰援河北疫情前线。
                            </p>
                            <div class="res-line"></div>
                        </div>
                    </div>
                    <div class="resImg" v-show="selectCulture == 3">
                        <img :src="`${$url}/home/milepost/hs.jpg`" alt="">
                        <div class="res-right">
                            <div class="res-title">支援核酸</div>
                            <p>
                                2022年，碧莲盛闻令而动，24城分院积极支援抗疫，先后有218人次医护力量支援抗疫，覆盖核酸检测超过2万人次。 </p>
                            <div class="res-line"></div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
        <div class="responsibility-body publicTop" id="love">
            <div class="body">
                <div style="display: flex; justify-content: space-between;">
                    <div>
                        <div class="left-cn wow fadeInUp">爱心助学</div>
                        <div class="wow fadeInUp left-ch res-text" style="margin-bottom: 62px;">CARING
                            STUDENTS</div>
                    </div>
                    <div>
                        <p class="wow fadeInUp" style="margin-top: 0;">
                            2021年，携手鸿基金启动山西公益行，为山西河津市上市小学捐赠“爱的图书馆”。
                        </p>
                        <p style="margin-bottom: 62px" class="wow fadeInUp" data-wow-delay="0.3s">
                            2022年，发起了六一爱心助学公益行活动，为山西河津市上市小学捐赠爱心助学物质。
                        </p>
                    </div>
                </div>




            </div>
            <div class="wow fadeInUp " data-wow-delay="0.3s" style="width: 100%;overflow: hidden;">

                <responsibilitySwiper></responsibilitySwiper>
            </div>
        </div>
        <div class="Striver publicTop" id="Striver">

            <div class=" body">
                <div style="display: flex; justify-content: space-between;">
                    <div>
                        <div class="left-cn wow fadeInUp">致敬奋斗者</div>
                        <div class="wow fadeInUp left-ch" style="margin-bottom: 32px;">PAY
                            TRIBUTE TO THE STRIVER
                        </div>
                    </div>
                    <div>
                        <p class="wow fadeInUp p-text" data-wow-delay="0.3s">
                            互联网时代，工作压力大，很多年轻人在努力奋斗的时候，头顶一不小心就成了“地中海”。碧莲盛发起的“致敬奋斗者”系列公益行动，为企业员工提供毛发健康义诊，帮助他们全面了解自己的头发健康，增强毛发健康管理意识，树立健康生活理念。
                        </p>

                    </div>
                </div>


                <div class=" image wow fadeInUp" data-wow-delay="0.5s">
                    <img :src='`${$url}responsibility/Striver1.jpg`' alt="">
                    <img :src='`${$url}responsibility/Striver7.jpg`' alt="">
                    <img :src='`${$url}responsibility/Striver3.jpg`' alt="">
                    <img :src='`${$url}responsibility/Striver4.jpg`' alt="">
                    <img :src='`${$url}responsibility/Striver5.jpg`' alt="">
                    <img :src='`${$url}responsibility/Striver6.jpg`' alt="">
                </div>
            </div>
        </div>
        <div class="ScholarlyFragrance publicTop" id="ScholarlyFragrance">
            <div class="body wow fadeInUp" data-wow-delay="0.3s">

                <div style="display:flex ;justify-content:space-between ;">
                    <div>
                        <div class="wow fadeInUp left-cn">书香碧莲盛</div>
                        <div class="wow fadeInUp left-ch" style="margin-bottom: 62px">SCHOLARLY
                            LOTUS</div>
                    </div>
                    <div>
                        <p class="wow fadeInUp p-text" data-wow-delay="0.3s" style="width: 710px;">
                            碧莲盛携手有书开启员工成长计划，共同开启员工共读模式，带领每一位员工一起共读书、读好书，让阅读成为一种生活态度和生活方式。</p>
                        <p class="wow fadeInUp p-text" data-wow-delay="0.3s" style="width: 710px;">
                            碧莲盛将同有书一起积极推动书香企业建设，一起广泛地组织全员读书，将国家倡导的全民阅读活动真正扎实落地，并由企业全员阅读带动影响家庭阅读、社会阅读，以此为建设书香中国贡献企业力量。
                        </p>
                    </div>
                </div>
                <div class="image wow fadeInUp" data-wow-delay="0.5s">

                    <div class="image1"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance2.jpg)` }">
                        <div class="imge-title">
                            碧莲盛员工成长计划启动仪式
                        </div>
                    </div>
                    <div class="image2"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance1.jpg)` }">
                        <div class="imge-title">
                            碧莲盛&有书共读会—北京
                        </div>
                    </div>
                    <div class="image1"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance3.jpg)` }">
                        <div class="imge-title">
                            碧莲盛&有书共读会—广州
                        </div>
                    </div>
                    <!-- <div class="image2"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance1.jpg)` }">
                        <div class="imge-title">
                            碧莲盛&有书共读会—北京
                        </div>
                    </div>
                    <div class="image1"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance2.jpg)` }">
                        <div class="imge-title">
                            碧莲盛员工成长计划启动仪式
                        </div>
                    </div>
                    <div class="image2"
                        :style="{ backgroundImage: `url(${$url}/responsibility/ScholarlyFragrance1.jpg)` }">
                        <div class="imge-title">
                            碧莲盛&有书共读会—北京
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import Milepost from 'swiper'
import "swiper/css/swiper.css";
import responsibilitySwiper from './responsibilitySwiper.vue'
export default {
    components: {
        responsibilitySwiper
    },
    data() {
        return {
            wow: null,
            mySwiper: null,
            selectCulture: 1
        }
    },
    beforeDestroy() {
        this.wow.stop()

    },

    mounted() {
        document.title = '社会责任-碧莲盛医疗'
        if (this.$route.query.id) {
            document.querySelector(`#${this.$route.query.id}`).scrollIntoView(true)
        }
        if (this.$route.params.name) {
            document.documentElement.scrollTop = 0
        }
        this.wow = new this.$wow.WOW({ live: false })
        this.wow.init()
        this.initSwiper()

    },

    methods: {

        initSwiper() {
            let that = this
            this.mySwiper = new Milepost(".swiper-box",{
                direction: 'horizontal',

                slidesPerView: 3,
                spaceBetween: '1%',
                loopFillGroupWithBlank: true,

                autoplay: true,
                observer: true,
                observeParents: true,
                autoplayDisableOnInteraction: false,


            })

        },
    }
}
</script>
    
<style scoped lang="scss">
.Striver {



    background-color: #ffffff;

    overflow: hidden;

    .body {
        p {
            width: 944px;
            color: #707070;
            line-height: 26px;
            font-size: 16px;
        }

        .image {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            transform: all 0.5s;


            img {
                margin-bottom: 30px;
                width: 450px;
                height: 273px;
                transform: animation 0.1s;
                transform-origin: center;
            }

            img:hover {
                animation: scaleImg 0.3s linear both;

            }


        }
    }
}

@keyframes scaleImg {
    0% {

        transform: scale(1);
    }

    100% {
        transform: scale(1.05);



    }
}

.responsibility-body {

    background-color: #f9fafd;




    .body {
        p {
            width: 701px;
            color: #707070;
            line-height: 26px;
            font-size: 16px;
        }
    }
}

.ScholarlyFragrance {

    background-color: #f9fafd;

    overflow: hidden;

    .body {

        .image {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            >div:hover {
                transition: animation 0.3s;
                animation: scaleHover 0.3s linear both;

            }

            .imge-title {
                width: 450px;
                height: 44px;
                background: rgba(47, 47, 47, 0.4);
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                line-height: 44px;
            }

            >div {
                transition: all 0.3s;
                display: flex;
                align-items: flex-end;
                margin-bottom: 30px;
                width: 450px;
                height: 273px;
                background-color: #bfbfbf;
                background-position: center;
            }

            .image1 {

                background-size: 100% 100%;
            }

            .image2 {

                background-size: 100% 100%;
            }
        }

        p {
            width: 976px;
            color: #707070;
            line-height: 26px;
            font-size: 16px;
        }
    }
}

.reTop {
    display: flex;
    width: 587px;
    font-size: 22px;

    color: #000000;

    div {
        width: 195px;
        border-top: solid 2px #c8c8c8;
        padding-top: 13px;
        cursor: pointer;
    }

    .selectItem {
        border-top: solid 2px #2e59a7;
        color: #2e59a7;
    }
}

.title {
    font-size: 30px;
    margin-bottom: 22px;
}

.text {
    width: 633px;
    line-height: 25px;
    font-size: 16px;
}

// .top-image-left {
//     width: 600px;
//     height: 341px;
//     background: url("@/static/responsibility/responsibility1.jpg") no-repeat;
//     background-size: 100% 100%;
//     color: #ffffff;
//     text-align: left;
//     padding-top: 215px;
//     padding-left: 42px;

// }

// .top-image-right {
//     width: 600px;
//     height: 341px;
//     background: url("@/static/responsibility/responsibility3.jpg") no-repeat;
//     background-size: 100% 100%;
//     color: #ffffff;
//     text-align: left;

//     padding-top: 215px;
//     padding-left: 42px;
// }

@keyframes scaleItem {
    0% {
        opacity: 0;
    }

    100% {

        opacity: 1;


    }
}

@keyframes scaleHover {
    0% {
        background-size: 100%;
    }

    100% {

        background-size: 105%;


    }
}

.resImg {
    display: flex;
    transform: animation 1s;
    animation: scaleItem 1s linear both;

    img {
        width: 810px;
        height: 480px;
        background-color: #2e59a7;
    }

    .res-right {
        position: relative;
        height: 480px;
        background-color: #f9fafd;
        padding: 53px 57px 0;

        .res-title {

            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 96px;
            letter-spacing: 0px;
            color: #363636;
        }

        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 30px;
            letter-spacing: 0;
            color: #707070;
        }
    }
}

.top {}



.res-line {
    width: 31px;
    height: 2px;
    background-color: #c8c8c8;
    position: absolute;
    bottom: 20%;
    left: 55px;
}

.p-text {
    width: 701px !important;
    margin-top: 0;
}
</style>