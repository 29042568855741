<template>
  <div>
    <Header></Header>
    <div>
      <router-view :key="$route.fullPath" />
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/views/header.vue'
import Footer from '@/views/footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1281px) and (max-width: 1680px) {
  .body {
    width: 1200px !important;
  }
  .errorDiv {
    right: -5vw !important;
  }
  .logo {
    width: 90px;
    margin-top: -2px;
  }

  .header-d {
    .tab {
      width: 700px !important;
    }
  }

  .headerAnd {
    width: 1200px !important;

    .tab {
      // width: 600px;
      // font-size: 14px;

      li {
        padding: 0 20px;
        cursor: pointer;
      }

      :first-child {
        padding-left: 0;
      }
    }

    .language {
      height: 20px !important;
      margin-top: 5px;
    }

    .language li {
      line-height: 10px;
      padding: 5px 10px !important;
      font-size: 10px !important;
    }
  }

  .header-text {
    font-size: 40px;
    line-height: 70px !important;
  }

  .text-en {
    font-size: 16px !important;
  }

  .left-cn {
    font-size: 40px !important;
  }

  .left-ch {
    font-size: 25px !important;
  }

  #Itsbrand {
    .Itsbrands-img-left {
      width: 590px;
      height: 350px;
    }

    .Itsbrands-img {
      margin-top: 30px;
    }

    .Itsbrands-img-right {
      width: 590px;
      height: 350px;
    }
  }

  #layout {
    .list {
      margin-top: 40px !important;
    }

    .layout-map {
      width: 550px;
    }

    .map {
      transform: scale(0.8);
      transform-origin: left;
    }

    .layout-left {
      margin-top: 50px;
    }
  }

  #brief {
    .right {
      width: 600px;

      line-height: 25px;

      div {
        line-height: 25px;
      }
    }
  }

  #brand {
    img {
      margin-top: 115px !important;
      width: 535px !important;
      height: 300px !important;
    }

    .right {
      margin-top: 30px;
      width: 535px;
      line-height: 25px;

      div {
        line-height: 25px;
      }
    }
  }

  #milepost {
    .swiper-fled {
      .out-top {
        top: 250px;
        left: 80px;
      }

      .out-topTwo {
        top: 250px;
        right: 80px;
      }
    }

    .date {
      margin-top: 60px;
    }

    .date-item {
      width: 250px;

      margin-top: 80px;

      .date-top-body {
        div {
          margin-bottom: 20px;
        }
      }

      img {
        height: 150px;
      }
    }
  }

  #HonoraryAwards {
    .awards-img {
      margin-top: 30px;
    }
  }

  #corporateCulture {
    .Culture-line {
      width: 580px;
    }

    .Culture-item {
      margin-top: 50px;
    }

    .Culture-img {
      width: 580px !important;
      height: 380px !important;
    }

    .Culture-item-left {
      width: 500px;
    }
  }

  #news {
    .lookMore {
      margin-top: 40px;
    }

    .news-left {
      width: 580px;

      div {
        height: 44px;
        line-height: 44px;
        font-size: 16px;
      }
    }

    .new-ms {
      margin-top: 30px;
    }

    .news-right {
      width: 580px;
      height: 395px;
      overflow: hidden;

      .news-item {
        cursor: pointer;
        width: 430px;
        height: 146px;
        padding-top: 18px;
        border-bottom: solid 1px #cccccc;

        .news-item-right {
          width: 240px;
          font-size: 16px;

          .date {
            font-size: 16px;
            line-height: 80px;
          }
        }
      }
    }
  }

  .Culture-size {
    margin-bottom: 40px !important;
  }

  .mark {
    .mark-text {
      font-size: 16px !important;
    }
  }

  .reson {
    line-height: 30px !important;
  }

  .cycle {
    width: 580px !important;
    height: 380px !important;
  }

  .Journalism-item {
    .lookMore {
      margin: 20px auto 0 !important;
    }

    .Journalism-item-line {
      margin-top: 40px !important;
    }

    .item-bottom {
      font-size: 16px !important;
      padding: 15px 10px !important;
    }

    .item {
      width: 380px !important;
      font-size: 16px !important;
      line-height: 45px !important;
      margin-bottom: 16px !important;

      .item-img {
        height: 210px !important;
      }

      .item-imgT {
        height: 245px !important;
      }

      .item-imgT {
        > img {
          width: 380px !important;
          height: 100% !important;
        }
      }
    }
  }

  #Epidemic {
    .Epidemic-top {
      margin-bottom: 30px !important;
    }

    .reTop {
      width: 600px;
    }

    .resImg img {
      width: 800px;
      height: 455px;
    }

    .res-right {
      height: 455px;
      padding-top: 50px;

      .res-title {
        line-height: 36px;
      }
    }
  }

  //   .responsibilitySwiper {
  //     height: 300px !important;

  //     .images {
  //       width: 500px !important;
  //     }

  //     img {
  //       margin: 0 11px !important;
  //       width: 350px !important;
  //       height: 230px !important;
  //     }

  //     .del-img {
  //       width: 350px !important;
  //       height: 230px !important;
  //     }

  //     .select-img {
  //       width: 500px !important;
  //       height: 300px !important;
  //     }
  //   }

  .responsibility-body {
    p {
      width: 620px !important;
    }
  }

  #Striver {
    .p-text {
      width: 620px !important;
    }

    .image {
      margin-top: 20px;
    }

    img {
      width: 380px;
      height: 235px;
    }

    img:nth-child(4),
    img:nth-child(5),
    img:nth-child(6) {
      margin-bottom: 0;
    }
  }

  #ScholarlyFragrance {
    .image {
      margin-top: 20px;
    }

    .p-text {
      width: 620px !important;
    }

    .image {
      > div {
        width: 380px;
        height: 235px;
      }

      > div:nth-child(4),
      > div:nth-child(5),
      > div:nth-child(6) {
        margin-bottom: 0;
      }
    }
  }

  .Investor {
    .date-line {
      width: 1200px !important;
      margin-bottom: 50px !important;
    }

    .item {
      .right {
        width: 700px;

        .right-p {
          margin-top: 10px;
          margin-bottom: 20px;
        }

        div {
          // font-size: 10px;
          width: 700px;
        }
      }

      .right-floow {
        width: 520px;

        .wx {
          font-size: 16px;
          margin-top: 40px;
        }

        .wx-img {
          width: 100px;
          height: 100px;
        }

        .item-wx {
          font-size: 16px;
        }
      }

      .right-Tree {
        width: 700px;

        .post {
          font-size: 16px;
        }

        .you {
          width: 335px;
        }

        .liu {
          width: 335px;
        }

        .shi {
          width: 214px;
        }
      }
    }
  }

  .business {
    .system {
      .system-en {
        margin-bottom: 30px !important;
      }

      .system-img-Item {
        height: 240px !important;
        font-size: 16px !important;

        .top-text {
          font-size: 20px;
        }
      }
    }

    .business-img {
      .business-body {
        margin-top: 40px !important;
        line-height: 45px !important;
        font-size: 25px !important;
      }

      .business-line {
        margin-top: 45px !important;
      }

      > div {
        > div {
          padding: 40px 0 0 41px !important;
        }
      }

      margin-top: 30px !important;
    }

    .business-img > div {
      width: 260px !important;
      height: 260px !important;

      .business-body {
        margin-top: 20px;
        font-size: 20px;
      }

      .business-line {
        margin-top: 20px;
      }
    }

    .right > div {
      // font-size: 10px !important;
    }
  }

  .contactUs {
    .left {
    }

    .right {
      margin-top: 63px;
      width: 600px !important;
      height: 400px !important;
    }
  }

  .footer {
    .footer-sign {
      font-size: 14px;
    }

    .code-text {
      font-size: 14px !important;
    }

    .tab {
      width: 700px;
    }

    .footer-tab {
      width: 800px !important;
    }

    .footer-right > div {
      font-size: 14px !important;
    }

    .footer-top {
      font-size: 14px;
    }

    .selectTab-text {
      font-size: 14px;
    }
  }

  .publicTop {
    padding: 50px 0 50px 0 !important;
  }
}

.publicTop {
  padding: 70px 0 50px 0;
}

@import '../style/index.scss';

.body {
  width: 1400px;
  font-family: 'arial 宋体';
  margin: 0 auto;
}

div,
ul,
li,
span,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

li {
  list-style-type: none;
}

@keyframes findleft {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.PublicAnimation {
  transition: transform 0.5s;
  animation: findleft 0.5s linear both;
}

@keyframes option {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.OptionAni {
  transition: transform 1s;
  animation: option 1s linear both;
}

.left-cn {
  font-size: 44px;
  margin-bottom: 15px;
}

.left-ch {
  font-size: 31px;
  color: rgb(100, 100, 101);
}
</style>
