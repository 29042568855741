import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  i18n
} from '@/utils/lang'
import Router from 'vue-router';
import "animate.css"
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'
import "./permission"

Vue.config.productionTip = false

Vue.prototype.$wow = wow
Vue.prototype.$url = 'https://static.drlianzhuren.com/brandWeb/'
Vue.prototype.$img = 'https://static.drlianzhuren.com/HairTransplant/'
// Vue.prototype.$request = 'https://wxtest.blsgroup.com.cn/'
Vue.prototype.$request = 'https://wx.drlianzhuren.com/'
const originalPush = Router.prototype.push



Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
