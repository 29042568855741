<template>
  <div class="JournalismInfo">
    <isFixedHeader></isFixedHeader>
    <div class="body" style="    padding-bottom: 50px;    padding-top: 153px;">
      <div class="top">
        <span style="cursor: pointer;" @click="$router.push(`/home`)">首页</span> > <span style="cursor: pointer;" @click="$router.push(`/journalism`)">新闻动态</span> > <span style="cursor: pointer;" @click="$router.push(`/journalism?id=dynamic`)">企业动态</span>
      </div>
      <div class="title">{{ title }}</div>
      <div class="date">{{ date }}</div>
      <div class="line"></div>
      <div>
        <div ref="content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!-- smallTile -->
<script>
import infoList from '@/utils/data'
import Footer from '@/views/footer.vue'
import isFixedHeader from '@/views/isFixedHeader.vue'
export default {
  components: {
    isFixedHeader,
    Footer
  },
  data() {
    return {
      title: '',
      date: '',
    }
  },
  methods: {
    getInfo(id) {
      axios.get(`${this.$request}v1/info/queryPcMedicaldata?id=${id}`).then((res) => {
        let content = Base64.decode(res.data.data[0].content).replaceAll(/<img/gi,"<img style='width: 740px;margin: 20px auto;display:block;'")
        this.$refs.content.innerHTML = content;
        this.title = res.data.data[0].title;
        this.date = res.data.data[0].createTime;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getInfo(this.$route.query.id);
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
  }
}
</script>

<style  scoped lang="scss">
.top {
  color: #2e59a7;
  margin-bottom: 40px;
  font-size: 14px;
}

.title {
  font-size: 30px;
  text-align: center;
}

.date {
  color: #707070;
  margin: 40px 0 40px 0;
  font-size: 14px;
  text-align: center;
}

.line {
  height: 1px;
  background-color: #ececec;
  margin: 20px 0 20px 0;
}

.text {
  line-height: 30px;
  font-size: 16px;
  color: #707070;
  margin-bottom: 20px;
  text-indent: 33px;
}
</style>