<template>
    <div>
        <div class="responsibilitySwiper" style="">
            <div @mouseenter="mouseEneter" @mouseleave="mouseLover">
                <div class="images" ref="datefl">
                    <img v-for="(img_url, index) in img_src"
                        :class="[{ 'select-img': index - 1 == mark }, { 'del-img': index == mark }]" :key="index"
                        :src="img_url.url" alt="" :style="{
                            'opacity':
                                index - 1 === mark ? '1' : '0.3'
                        }" />


                </div>
            </div>


        </div>

    </div>
</template>

<script>
export default {
    methods: {
        mouseEneter() {
            this.stop()
        },
        mouseLover() {

            this.start()
        },
        addisMilepost() {
            if (this.mark == 4) {
                return
            }
            this.stop()
            this.start()

        },
        stop() {
            clearInterval(this.timer);
            this.timer = null
        },
        noisMilepost() {
            if (this.mark == 0) {

                return
            }
            clearInterval(this.timer);
            this.mark--
            this.mark--
            this.start()
        },

        recycle() {
            if (this.mark == 4) {
                this.mark = -1
                
            }
            this.mark++

            let px = this.mark * this.step + this.sort
            this.$refs.datefl.setAttribute("style",`transform: translate(${-px}%)`);

        },
        stop() {
            clearInterval(this.timer);
        },
        start() {

            this.timer = setInterval(() => { this.recycle() }
                ,2000);
        },


    },
    mounted() {
        this.start();
        let width = document.body.clientWidth
        // if (1300 < width && width < 1920) {
        //     this.step = 75
        //     this.sort = 30
        // } else {
        //     this.step = 71
        //     this.sort = 5
        // }
    },
    beforeDestroy() {
        this.stop();
    },
    data() {
        return {
            step: 83.5,
            sort: 0,
            img_src: [
                { url: `${this.$url}responsibility/love5.jpg` },
                { url: `${this.$url}responsibility/love1.jpg` },
                { url: `${this.$url}responsibility/love2.jpg` },
                { url: `${this.$url}responsibility/love3.jpg` },
                { url: `${this.$url}responsibility/love4.jpg` },
                { url: `${this.$url}responsibility/love5.jpg` },
                { url: `${this.$url}responsibility/love1.jpg` },
            ],
            img_srcList: [

                { url: `${this.$url}responsibility/love1.jpg` },
                { url: `${this.$url}responsibility/love2.jpg` },
                { url: `${this.$url}responsibility/love3.jpg` },
                { url: `${this.$url}responsibility/love4.jpg` },
                { url: `${this.$url}responsibility/love5.jpg` },

            ],
            mark: 0,//现在是第几张
            timer: ''//设置定时器

        }
    }
}
</script>

<style lang="scss" scoped>
.responsibilitySwiper-bottom span {
    width: .3153vw;
    height: .3153vw;
    border-radius: .1576vw;
    background-color: #000000;
    opacity: 0.2;
    margin: .7357vw;
    display: inline-block;
    /* span是行内元素 */

}



/* 圆圈圈激活后*/
.responsibilitySwiper-active {
    /*数值分别是：水平偏移，上下偏移，模糊，大小，颜色 */


    opacity: 0.7 !important;
}

.images {
    width: 42.0389vw;
    display: flex;
    align-items: center;

    transition: transform 1s;

    img {
        margin: 0 .7882vw;
        width: 33.6311vw;
        height: 19.7583vw;

    }

    .select-img {
        width: 42.0389vw;
        height: 24.6978vw;
        animation: selectAni 1s linear both;


        transform: animation 1s;
    }

    .del-img {

        animation: delAni 1s linear both;


        transform: animation 1s;
    }
}

@keyframes selectAni {
    0% {

        width: 33.6311vw;
        height: 19.7583vw;
    }



    100% {

        width: 42.0389vw;
        height: 24.6978vw;
    }
}

@keyframes delAni {
    0% {

        width: 42.0389vw;
        height: 24.6978vw;
    }

    100% {

        width: 33.6311vw;
        height: 19.7583vw;
    }




}

.responsibilitySwiper {
    overflow-x: hidden;
    height: 26.2743vw;
    margin-left: -6.5vw;
}
</style>