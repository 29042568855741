var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"layout publicTop",attrs:{"id":"layout"}},[_c('div',{staticClass:"body"},[_vm._m(1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._m(2),_c('div',{staticClass:"layout-map"},[_c('div',{staticClass:"map",style:({
              backgroundImage: `url(https://static.drlianzhuren.com/HairTransplant/home/strategicLayout.jpg?v=2)`,
            })})])])])]),_c('div',{staticClass:"Itsbrands publicTop",attrs:{"id":"Itsbrand"}},[_c('div',{staticClass:"body"},[_vm._m(3),_c('div',{staticClass:"Itsbrands-img wow fadeInUp",attrs:{"data-wow-delay":"0.3s"}},[_c('div',{staticClass:"Itsbrands-img-left",style:({ backgroundImage: `url(${_vm.$url}/home/bls.jpg)` })},[_c('div',[_c('img',{staticStyle:{"width":"372px"},attrs:{"src":`${_vm.$url}/home/ItsBrands1.png`,"alt":""}}),_vm._m(4)])]),_c('div',{staticClass:"Itsbrands-img-right",style:({ backgroundImage: `url(${_vm.$url}/home/lzr.jpg)` })},[_c('div',[_c('img',{staticStyle:{"width":"320px"},attrs:{"src":`${_vm.$url}/home/yfLOGO.png`,"alt":""}}),_vm._m(5)])])])])]),_c('div',{staticClass:"news publicTop",attrs:{"id":"news"}},[_c('div',{staticClass:"body"},[_vm._m(6),_c('div',{staticClass:"new-ms"},[_c('div',{staticClass:"news-left"},[_c('router-link',{attrs:{"to":{ path: '/journalismInfo1', query: { id: 0 } }}},[_c('img',{attrs:{"src":`${_vm.$url}aboutUs/%E9%A6%96%E9%A1%B5-%E6%96%B0%E9%97%BB%E5%8A%A8%E6%80%81%E5%A4%A7%E5%9B%BE.jpg`,"alt":""}}),_c('div',{staticClass:"img-ms"},[_vm._v(" 估值再创新高，“头部”企业碧莲盛如何实现逆势增长？ ")])])],1),_c('div',{staticClass:"news-right"},[_c('vueSeamlessScroll',{staticClass:"seamless-warp",attrs:{"data":_vm.listData,"class-option":_vm.classOption}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index},[_c('router-link',{attrs:{"to":{ path: '/journalismInfo1', query: { id: item.route } }}},[_c('div',{staticClass:"news-item"},[_c('img',{staticClass:"news-img",attrs:{"src":item.src,"alt":""}}),_c('div',{staticClass:"news-item-right"},[_vm._v(" "+_vm._s(item.text)+" "),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.date))])])])])],1)}),0)],1)]),_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.$router.push({ name: 'Journalism', params: { name: 'Journalism' } })}}},[_vm._v(" 查看更多 ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home publicTop",attrs:{"id":"brief"}},[_c('div',{staticClass:"body",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-cn"},[_vm._v("企业简介")]),_c('div',{staticClass:"left-ch"},[_vm._v("BRAND INTRODUCTION")])]),_c('div',{staticClass:"right"},[_vm._v(" 碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。19年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。 "),_c('div',[_vm._v(" 在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。 ")]),_c('div',[_vm._v(" 目前，碧莲盛在全国开设了42家直营机构，拥有100余位执业医生，700余位执业护士，其中，18位主任/副主任医师，为49.8万发友解决了脱发困扰。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":"1s"}},[_c('div',{staticClass:"left-cn"},[_vm._v("战略布局")]),_c('div',{staticClass:"left-ch"},[_vm._v("BRAND LAYOUT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-left wow fadeInUp"},[_c('div',{},[_vm._v(" 碧莲盛是一家辐射全国的大型连锁植发机构，自成立以来，致力于为全国发友带来无差异的、高标准的植发服务。凭借先进的植发技术、安全贴心的医疗服务、深厚的用户口碑，碧莲盛先后在全国开设了40余家直营机构，不断推进实体旗舰医院的战略布局。 ")]),_c('div',{},[_vm._v(" 目前，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国院部保持统一的视觉形象输出，同时对前台、顾问、医护团队进行统一的培训，全国所有家分院遵循统一的管理标准，以确保全国用户都能享受到品质如一的服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wow fadeInUp"},[_c('div',{staticClass:"left-cn"},[_vm._v("旗下品牌")]),_c('div',{staticClass:"left-ch"},[_vm._v("OWN BRANDS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Understand"},[_c('a',{attrs:{"href":"https://zhifa.blsgroup.cn"}},[_vm._v(" 了解品牌")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Understand"},[_c('a',{attrs:{"href":"https://drlian.blsgroup.cn"}},[_vm._v("了解品牌")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wow fadeInUp",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"left-cn"},[_vm._v("新闻动态")]),_c('div',{staticClass:"left-ch"},[_vm._v("PRESS RELEASE")])])
}]

export { render, staticRenderFns }