<template>
    <div style="  display: flex;
    justify-content: space-between;width: 100%;">
        <div class="Culture-item-left">

            <div style="font-size: 25px;color: #000000;font-weight: 500;margin-bottom: 42px;" class="Culture-size">
                品牌价值观
            </div>
            <div class="Culture-size">
                <div class="mouseMark" @mouseleave="mouseenter(0)">
                    <div class="reson" @mouseenter="mouserLeae(0)" :class="{ 'isrespon': mark == 0 }">诚信
                    </div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 0">
                        企业立身之本，为人立身之道
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(1)">
                    <div class="reson" @mouseenter="mouserLeae(1)" :class="{ 'isrespon': mark == 1 }">
                        责任</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 1">
                        公司未来的卓越，靠每个人尽职尽责、竭尽全力
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(2)">
                    <div class="reson" @mouseenter="mouserLeae(2)" :class="{ 'isrespon': mark == 2 }">
                        创新</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 2">
                        改革创新有风险，但不改革创新就是最大的风险
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(3)">
                    <div class="reson" @mouseenter="mouserLeae(3)" :class="{ 'isrespon': mark == 3 }">
                        客户至上</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 3">
                        践行以客户为中心，是我们所有工作的出发点
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(4)">
                    <div class="reson" :class="{ 'isrespon': mark == 4 }" @mouseenter="mouserLeae(4)">
                        拼搏</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 4">
                        只有具备燃烧般的热情，付出不亚于任何人的努力，才有可能成功
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(5)">
                    <div class="reson" :class="{ 'isrespon': mark == 5 }" @mouseenter="mouserLeae(5)">
                        学习</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 5">
                        学习不止是学校的事，而是一辈子的事。终身学习，不断进化
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(6)">
                    <div class="reson" :class="{ 'isrespon': mark == 6 }" @mouseenter="mouserLeae(6)">
                        协作</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 6">
                        我们的事业不是靠一个人或一个部门，靠的是所有人的共同努力
                    </div>
                </div>
                <div class="mouseMark" @mouseleave="mouseenter(7)">
                    <div class="reson" :class="{ 'isrespon': mark == 7 }" @mouseenter="mouserLeae(7)">
                        以人为本</div>
                    <div style="line-height: 25px;color: #707070;margin-bottom: 20px;" v-show="mark == 7">
                        我们发自内心的尊重和培养每个员工，员工永远是公司最宝贵的资产
                    </div>
                </div>
            </div>

        </div>

        <div class="cycle">
            <div>
                <div v-for="(img_url, index) in img_src" :key="index" class="cycle-img" v-show="index === mark">
                    <img @mouseenter="stop" @mouseleave="start" :src="img_url.url" alt=""
                        style="width:100% ;height: 100%;" />


                </div>


            </div>

        </div>
    </div>
</template>

<script>
export default {


    methods: {
        mouseenter(index) {

            this.state()

        },
        mouserLeae(index) {



            this.mark = index
            this.stop()

        },
        recycle() {

            if (this.mark === this.img_src.length - 1) {
                this.mark = 0;
            } else {
                this.mark++;
            }
            this.$emit('Markchange',this.mark)
        },
        stop() {
            clearInterval(this.timer);
            this.timer = null
        },
        start() {
            let that = this
            this.recycle();//先调用一次

            that.timer = setInterval(() => { this.recycle() }
                ,2000);

        },
        state() {
            this.stop()
            this.timer = setInterval(() => { this.recycle() }
                ,2000);
        }


    },
    mounted() {
        this.start();
    },
    beforeDestroy() {
        this.stop();
    },
    data() {
        return {

            img_src: [
                { url: `${this.$url}home/Culture1.jpg` },
                { url: `${this.$url}home/Culture8.jpg` },

                { url: `${this.$url}home/Culture2.jpg` },
                { url: `${this.$url}home/Culture3.jpg` },
                { url: `${this.$url}home/Culture4.jpg` },
                { url: `${this.$url}home/Culture6.jpg` },
                { url: `${this.$url}home/Culture5.jpg` },
                { url: `${this.$url}home/Culture7.jpg` },

            ],
            mark: 0,//现在是第几张
            timer: ''//设置定时器

        }
    }
}
</script>

<style>
.cycle {
    width: 716px;
    height: 470px;
    position: relative;

}

.cycle img {
    width: 100%;
    height: 100%;

    position: absolute;
    background-color: gainsboro;
    display: block;
}

.cycle button {
    width: 20px;
    height: 55px;
    position: absolute;
    top: 43%;
    right: 0px;
    opacity: 0.5;
    text-align: center;
}

.cycle .left {
    left: 0px;
}

.cycle .left i {
    margin-left: -10px;
}



.cycle-img {
    width: 100%;
    height: 100%;
    animation: ani 1s linear both;
}

@keyframes ani {
    0% {


        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Culture-item-left {
    width: 500px;
}

.mouseMark {
    cursor: pointer;

}

.Culture-size {
    font-size: 16px;
}

.isrespon {
    display: inline-block;

    color: #ffffff !important;
    background-color: #2e59a7;
    border-radius: 14px;
    padding: 4px 12px;
    margin-left: -12px !important;
    margin-top: 20px !important;


    font-size: 20px;
    line-height: 25px !important;
}

.reson {
    line-height: 45px;
    color: #707070;

}
</style>

