```src/views/footer.vue
<template>
  <div class="footer">
    <div class="body">
      <div
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 38px;
        "
      >
        <img :src="`${$url}/logo.png`" alt="" class="footer-logo" />
      </div>
      <div style="display: flex; justify-content: space-between">
        <ul class="tab footer-tab" style="width: 900px">
          <li class="selectTab">
            <div
              @click="$router.push({ name: 'home', params: { name: 'home' } })"
              class="footer-top"
              style="font-weight: normal"
            >
              首页
            </div>
            <div class="selectTab-text">
              <div @click="$router.push(`/home?id=brief`)">企业简介</div>
              <div @click="$router.push(`/home?id=layout`)">战略布局</div>
              <div @click="$router.push(`/home?id=Itsbrand`)">旗下品牌</div>
              <div @click="$router.push(`/home?id=news`)">新闻动态</div>
            </div>
          </li>
          <li class="selectTab">
            <div
              @click="
                $router.push({ name: 'aboutUs', params: { name: 'aboutUs' } })
              "
              class="footer-top"
              style="font-weight: normal"
            >
              关于我们
            </div>
            <div class="selectTab-text">
              <div @click="$router.push(`/aboutUs?id=brand`)">品牌介绍</div>
              <div @click="$router.push(`/aboutUs?id=milepost`)">
                里程碑事件
              </div>
              <div @click="$router.push(`/aboutUs?id=corporateCulture`)">
                企业文化
              </div>
              <div @click="$router.push(`/aboutUs?id=HonoraryAwards`)">
                荣誉奖项
              </div>
            </div>
          </li>
          <li class="selectTab">
            <div
              @click="
                $router.push({
                  name: 'Journalism',
                  params: { name: 'Journalism' },
                })
              "
              class="footer-top"
              style="font-weight: normal"
            >
              新闻动态
            </div>
            <div class="selectTab-text">
              <div @click="$router.push(`/journalism?id=vido`)">视频报道</div>
              <div @click="$router.push(`/journalism?id=dynamic`)">
                企业动态
              </div>
            </div>
          </li>
          <li class="selectTab">
            <div
              @click="
                $router.push({
                  name: 'responsibility',
                  params: { name: 'responsibility' },
                })
              "
              class="footer-top"
              style="font-weight: normal"
            >
              社会责任
            </div>
            <div class="selectTab-text">
              <div @click="$router.push(`/responsibility?id=Epidemic`)">
                疫情公益
              </div>
              <div @click="$router.push(`/responsibility?id=love`)">
                爱心助学
              </div>
              <div @click="$router.push(`/responsibility?id=Striver`)">
                致敬奋斗者
              </div>
              <div
                @click="$router.push(`/responsibility?id=ScholarlyFragrance`)"
              >
                书香碧莲盛
              </div>
            </div>
          </li>
          <li class="selectTab">
            <div
              @click="
                $router.push({ name: 'Investor', params: { name: 'Investor' } })
              "
              class="footer-top"
              style="font-weight: normal"
            >
              投资者关系
            </div>
            <div class="selectTab-text">
              <div @click="$router.push(`/Investor?id=1`)">战略融资</div>
              <div @click="$router.push(`/Investor?id=2`)">动脉网估值</div>
              <div @click="$router.push(`/Investor?id=3`)">公司治理</div>
              <div @click="$router.push(`/Investor?id=4`)">联系方式</div>
            </div>
          </li>
          <li class="selectTab">
            <div
              @click="$router.push(`/contactUs`)"
              class="footer-top"
              style="font-weight: normal"
            >
              联系我们
            </div>
          </li>
        </ul>
        <div class="footer-right">
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-top: 2px;
            "
          >
            联系电话 400-822-1660
            <div class="icon-bg" style="min-width: 20px; min-height: 20px">
              <i class="iconfont icon-24gf-telephone"></i>
            </div>
          </div>
          <div
            style="
              margin-top: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            北京市 大兴区 西红门鸿坤广场B座3号楼5层
            <div class="icon-bg" style="min-width: 20px; min-height: 20px">
              <i class="iconfont icon-dingweixiao"></i>
            </div>
          </div>
          <div
            style="display: flex; justify-content: flex-end; margin-top: 25px"
          >
            <div>
              <div class="qcCode">
                <img
                  :src="`${$url}contactUs/wx.png`"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div class="code-text">微信公众号</div>
            </div>
            <div style="margin-left: 10%">
              <div class="qcCode">
                <img
                  :src="`${$url}/xcxCode.jpg`"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div>
                <div class="code-text">官方小程序</div>
              </div>
            </div>
            <div class="icon-bg" style="min-width: 20px; min-height: 20px">
              <i class="iconfont icon-weixin"></i>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; margin-top: -20px">
        <div style="margin-right: 16px">
          <img src="../assets/2.jpg" alt="" style="width: 50px" />
          <div
            style="
              color: #ffffff;
              opacity: 0.6;
              font-size: 12px;
              margin-top: 10px;
              text-align: center;
            "
          >
            营业执照
          </div>
        </div>
        <div>
          <img src="../assets/1.jpg" alt="" style="width: 50px; height: 35px" />
          <div
            style="
              color: #ffffff;
              opacity: 0.6;
              font-size: 12px;
              margin-top: 10px;
              text-align: center;
            "
          >
            许可证
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="body">
      <div>
        <div class="footer-sign">
          <span>Copyright</span>
          <span>© 北京碧莲盛医疗美容门诊部有限责任公司</span>
          <a
            style="color: inherit"
            href="http://beian.miit.gov.cn"
            target="_blank"
            >京ICP备12001479号-54</a
          >
          <img
            :src="`${$url}/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png`"
            alt=""
            style="vertical-align: bottom; margin: -3px 5px; margin-right: 2px"
          />
          <a
            target="_blank"
            style="color: inherit"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006011"
            >京公网安备 110115020060011号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const time = ''
export default {
  methods: {
    beforeDestroy () {
      clearInterval(time)
    },
    toTop () {
      let t =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const time = setInterval(() => {
        t -= 40
        document.documentElement.scrollTop = t
        if (t <= 0) {
          clearInterval(time)
        }
      }, 5)
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: #2e59a7;
  padding-top: 42px;
  font-size: 16px;
  color: #ffffff;

  .footer-logo {
    width: 100px;
  }

  .footer-title {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 96px;
    letter-spacing: 0px;
    color: #ffffff;
    margin-left: 30px;
  }

  .footer-right {
    text-align: right;

    div {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }
}

.selectTab {
  font-size: 18px;
}

.selectTab-text {
  margin-top: 20px;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;

  div {
    line-height: 36px;
  }
}

.contactUs {
  margin-top: 65px;
  color: #ffffff;
  font-size: 18px;
}

.contactUsOu {
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}

.qcCode {
  width: 100px;
  height: 100px;

  padding: 5px;

  background-color: #ffffff;
}

.line {
  height: 1px;
  background: #507fd3;
  opacity: 0.5;
  margin-top: 20px;
}

.footer-sign {
  margin-top: 20px;
  font-size: 14px;
  padding-bottom: 20px;
  opacity: 0.5;

  a {
    text-decoration: none;
  }
}

.out-top {
  width: 10px;
  height: 10px;
  border-left: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.8;
  float: right;
  margin-top: 3px;
  margin-left: 5px;
}

.icon-bg {
  margin-left: 10px;
  margin-top: 3px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: solid 1px #ffffff;

  i {
    font-size: 12px;
  }
}

.footer-top {
  font-size: 16px;
}

.tab {
  width: 750px;
}

.code-text {
  opacity: 0.5;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
}
</style>
