<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
  name: "app",
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    // if (this._isMobile()) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
     if (vw <= 900) {
      //   console.log("手机端");
      location.href = "http://m.blsgroup.cn/home";
    } else {
      //   console.log("pc端");
    }
  },
};
</script>
<style lang="scss"></style>