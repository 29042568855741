var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":_vm.imgUrl[_vm.selectTab].img,"alt":""}}),_c('div',{staticStyle:{"position":"absolute","top":"0","width":"100%","height":"100%","padding":"35px 0"}},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"headerAnd"},[_c('img',{staticClass:"logo",attrs:{"src":`${_vm.$url}/logo.png`,"alt":""}}),_c('ul',{staticClass:"tab"},[_c('li',{class:{ selectTab: _vm.selectTab == 0 },on:{"mouseenter":function($event){_vm.left = 0.3},"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 0;
                            _vm.$router.push(`/home`);}}},[_vm._v(" "+_vm._s(_vm.$t("home.title"))+" ")]),_c('li',{class:{ selectTab: _vm.selectTab == 6 },on:{"mouseenter":function($event){_vm.left = 13},"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 6;
                            _vm.$router.push(`/aboutUs`);}}},[_vm._v(" 关于我们 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 1 },on:{"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 1;
                        _vm.$router.push(`/journalism`);},"mouseenter":function($event){_vm.left = 28.2}}},[_vm._v(" 新闻动态 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 2 },on:{"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 2;
                        _vm.$router.push(`/responsibility`);},"mouseenter":function($event){_vm.left = 43.2}}},[_vm._v(" 社会责任 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 3 },on:{"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 3;
                        _vm.$router.push(`/Investor`);},"mouseenter":function($event){_vm.left = 59.5}}},[_vm._v(" 投资者关系 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 4 },on:{"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 4;
                        _vm.$router.push(`/business`);},"mouseenter":function($event){_vm.left = 75.8}}},[_vm._v(" 业务模块 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 5 },on:{"mouseleave":_vm.leaveTab,"click":function($event){_vm.selectTab = 5;
                        _vm.$router.push('/contactUs');},"mouseenter":function($event){_vm.left = 91}}},[_vm._v(" 联系我们 ")]),_c('div',{staticClass:"buoy",style:({ left: _vm.left + '%' })},[_c('div',{staticClass:"sds"})])]),_c('ul',{staticClass:"language"},[_c('li',{class:{ 'select-language': _vm.selectLanguage == 0 },on:{"click":function($event){return _vm.seletLang(0, 'zh-CN')}}},[_vm._v(" 简体 ")]),_c('li',{class:{ 'select-language': _vm.selectLanguage == 1 },on:{"click":function($event){return _vm.seletLang(1, 'sc-CN')}}},[_vm._v(" 繁体 ")]),_c('li',{class:{ 'select-language': _vm.selectLanguage == 2 },on:{"click":function($event){return _vm.seletLang(2, 'en-US')}}},[_vm._v("  EN  ")])])]),_c('div',{staticClass:"body wow fadeInUp text-en",staticStyle:{"font-size":"18px","position":"absolute","bottom":"40%"}},[_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.imgUrl[_vm.selectTab].title)+" ")]),_vm._v(" "+_vm._s(_vm.imgUrl[_vm.selectTab].text)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }