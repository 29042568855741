<template>
  <div class="aboutUs">
    <div class="home publicTop" id="brand">
      <div class="body" style="display: flex; justify-content: space-between">
        <div>
          <div class="left wow fadeInUp">
            <div class="left-cn">品牌介绍</div>
            <div class="left-ch">BRAND INTRODUCTION</div>
          </div>
          <div class="right wow fadeInUp" data-wow-delay="0.3s">
            碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。19年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。
            <div>
              在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。
            </div>
            <div>
              目前，碧莲盛在全国开设了42家直营机构，拥有100余位执业医生，700余位执业护士，其中，18位主任/副主任医师，为49.8万发友解决了脱发困扰。 </div>
          </div>
        </div>

        <img class="wow fadeInUp aboutUsLeftImg" data-wow-delay="0.3s" :src="`${$url}banner/%E5%93%81%E7%89%8C%E4%BB%8B%E7%BB%8D%E5%9B%BE.jpg`" alt="" style="width: 650px; height: 350px; margin-top: 170px" />
      </div>
    </div>
    <div class="milepost publicTop" id="milepost">
      <div class="body">
        <div style="display: flex; justify-content: space-between" class="wow fadeInUp">
          <div>
            <div class="left-cn">里程碑事件</div>
            <div class="left-ch">MILESTONE EVENTS</div>
          </div>
          <!-- <div style="display:flex ;margin-bottom: 80px;">
                        <div style="cursor: pointer;">

                            <i class="iconfont icon-zuojiantou" :style="[{
                                color: (isMilepost == 0 ? '#a7a7a7' : '#000000')
                            }, { fontSize: '40px' }]" @click="noisMilepost"></i>
                        </div>

                        <div style="transform: rotateY(180deg);margin-left: 10px;cursor: pointer;"
                            @click="addisMilepost">
                            <i class="iconfont icon-zuojiantou" :style="[{
                                color: (isMilepost == 11 ? '#a7a7a7' : '#000000')
                            }, { fontSize: '40px' }]"></i>
                        </div>
                    </div> -->
        </div>

        <div class="date wow fadeInUp">
          <div :class="{ 'select-date': isMilepost == 0 }" @click="editisMilepost(0)">
            2022
          </div>
          <div :class="{ 'select-date': isMilepost == 1 }" @click="editisMilepost(1)">
            2021
          </div>
          <div :class="{ 'select-date': isMilepost == 2 }" @click="editisMilepost(2)">
            2020
          </div>
          <div :class="{ 'select-date': isMilepost == 3 }" @click="editisMilepost(3)">
            2019
          </div>
          <div :class="{ 'select-date': isMilepost == 4 }" @click="editisMilepost(4)">
            2018
          </div>
          <div :class="{ 'select-date': isMilepost == 5 }" @click="editisMilepost(5)">
            2017
          </div>
          <div :class="{ 'select-date': isMilepost == 6 }" @click="editisMilepost(6)">
            2015
          </div>
          <div :class="{ 'select-date': isMilepost == 7 }" @click="editisMilepost(7)">
            2013
          </div>
          <div :class="{ 'select-date': isMilepost == 8 }" @click="editisMilepost(8)">
            2012
          </div>
          <div :class="{ 'select-date': isMilepost == 9 }" @click="editisMilepost(9)">
            2010
          </div>
          <div :class="{ 'select-date': isMilepost == 10 }" @click="editisMilepost(10)">
            2008
          </div>
          <div :class="{ 'select-date': isMilepost == 11 }" @click="editisMilepost(11)">
            2005
          </div>
        </div>
      </div>
      <div class="swiper-fled">
        <div class="out-top" @click="noisMilepost"></div>
        <div class="out-topTwo" @click="addisMilepost"></div>
        <div class="wow fadeInUp body" data-wow-delay="0.5s" style="overflow: hidden">
          <div class="swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2022</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2022.jpg`" alt="" />
                    <div>
                      发起了六一爱心助学公益行活动，为山西河津市上市小学捐赠爱心助学物质。
                    </div>

                    <div>
                      荣登第五届未来医疗100强大会中国创新医疗服务榜100强，企业估值45亿。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2021</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2021.jpg`" alt="" />
                    <div>
                      参加第五届中国毛发移植大会，共同起草《毛发移植规范》团体标准。
                    </div>

                    <div>
                      成立碧莲盛名医堂，18位在碧莲盛执业7年以上、主治/主任资质的医生入围首批名单。
                    </div>
                    <!-- <div>携手鸿基金启动“爱的翅膀·助力成才计划”，为山西河津市上市小学捐赠“爱的图书馆”。</div> -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2020</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2020.jpg`" alt="" />
                    <div>
                      武汉新冠疫情爆发后，成立100万专项基金，采购医疗专用防疫物质，并运往疫情前线。
                    </div>

                    <!-- <div>与中国平安保险合作，为发友的植发安全与效果提供有力保障，让发友“放心植发，安全变美”。</div> -->

                    <div>
                      在深圳举办发布会，正式推出不剃发植发技术，将世界植发技术标准推向一个全新的高度。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2019</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2019.jpg`" alt="" />
                    <div>
                      与中国人保（PICC）达成战略合作，保障发友合法权益。
                    </div>

                    <div>
                      参加中国植发大会，董事长尤丽娜发表《植发手术行业规范》重要演讲。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2018</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2018.jpg`" alt="" />
                    <div>
                      华盖资本投资碧莲盛，两大巨头强强联手，促进植发行业健康发展。
                    </div>
                    <div>
                      与阿里健康达成战略合作，加入天猫医美“安心美颜”联盟。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2017</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2017.jpg`" alt="" />
                    <div>
                      董事长尤丽娜成为中国首位入驻世界植发大会案例中心的医生。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2015</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2015.jpg`" alt="" />
                    <div>受邀参加央视315晚会。</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2013</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/20132.jpg`" alt="" />
                    <div>
                      受邀参加第四届国际资本峰会，董事长尤丽娜受到法国总统奥朗德接见。
                    </div>
                    <div>
                      亲赴雅安地震灾区，捐出价值约100万的救灾物资，资助1000多个学生和家庭。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2012</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2012.jpg`" alt="" />
                    <div>
                      自主研发精密即插即种植发技术，再次成为世界植发大会焦点。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2010</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/20102.jpg`" alt="" />
                    <div>
                      自主研发加密即插即种植发技术，并在当年世界植发大会上公开展示。
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2008</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2008.jpg`" alt="" />
                    <div>受邀参加世界植发大会，向世界展示中国植发技术。</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide my-swiper-slide">
                <div class="date-item">
                  <div class="date-top-text">2005</div>
                  <div class="date-top-body">
                    <img :src="`${$url}/home/milepost/2005.jpg`" alt="" />
                    <div>
                      碧莲盛首家植发医院——北京碧莲盛医疗美容门诊部正式成立。
                    </div>
                    <div>成为世界植发协会会员和欧洲植发协会会员。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="corporateCulture publicTop" style="background: #ffffff">
      <div class="body">
        <div class="left-cn" style="text-align:center">企业文化</div>
        <div class="left-ch" style="text-align:center">CORPORATE CULTURE</div>
        <div class="culture">
          <img :src="`${$img}/aboutUs/${Culture}.jpg`" class="culture-left" alt="" :key="+new Date()" />

          <div class="culture-right">
            <div class="culture-item wow fadeInUp" @mouseenter="Culture = 17" data-wow-delay="0.2s">
              <div class="culture-title">品牌愿景</div>
              <div class="culture-subTitle" v-if="Culture == 17">
                成为中国毛发健康服务创领者
              </div>
              <div class="culture-text" v-if="Culture == 17"></div>
            </div>
            <div class="culture-item wow fadeInUp" @mouseenter="Culture = 18" data-wow-delay="0.3s">
              <div class="culture-title">品牌使命</div>
              <div class="culture-subTitle" v-if="Culture == 18">
                医者仁心、医疗为本，为发友的健康美好生活提供终生服务
              </div>
              <div class="culture-text" v-if="Culture == 18"></div>
            </div>
            <div class="culture-item wow fadeInUp" data-wow-delay="0.4s" @mouseenter="Culture = 11" style="border: none">
              <div class="culture-title">品牌价值观</div>
              <div class="BrandValues" v-if="Culture !== 17 && Culture !== 18">
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 16 }" @mouseenter="Culture = 16">
                  客户第一
                </div>
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 11 }" @mouseenter="Culture = 11">
                  至诚守信
                </div>
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 12 }" @mouseenter="Culture = 12">
                  务实进取
                </div>
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 13 }" @mouseenter="Culture = 13">
                  责任担当
                </div>
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 14 }" @mouseenter="Culture = 14">
                  创新求变
                </div>
                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 15 }" @mouseenter="Culture = 15">
                  高效执行
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="HonoraryAwards publicTop" id="HonoraryAwards">
      <div class="body wow fadeInUp">
        <div class="left-cn">荣誉奖项</div>
        <div class="left-ch">HONORARY AWARDS</div>
        <div class="awards-img wow fadeInUp" data-wow-delay="0.3s">
          <div>
            <img :src="`${this.$url}/fbs.jpg`" t="" />

            <div class="poab">
              <div>
                <div>福布斯中国</div>
                <div>植发行业杰出品牌</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${this.$url}/hy.jpg`" t="" />

            <div class="poab">
              <div>
                <div>中国(行业)十大领军品牌</div>
                <div>亚洲品牌十大创新人物</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards1.jpg`" t="" />

            <div class="poab">
              <div>
                <div>中国创新医疗</div>
                <div>服务榜单100强</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards2.jpg`" t="" />
            <div class="poab">
              <div>
                <div>最青睐的</div>
                <div>高端植发品牌</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards3.jpg`" t="" />
            <div class="poab">
              <div>
                <div>植发行业领军企业</div>
                <div>医美行业科技创新奖</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards4.jpg`" t="" />
            <div class="poab">
              <div>
                <div>中国经济</div>
                <div>十大杰出女性</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards5.jpg`" t="" />
            <div class="poab">
              <div>
                <div>21世纪</div>
                <div>全国创新公司50强</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards6.jpg`" t="" />
            <div class="poab">
              <div>
                <div>突破性</div>
                <div>技术创新案例</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Milepost from "swiper";
import "swiper/css/swiper.css";
import Swiper from "./swiper.vue";

export default {
  components: {
    Swiper,
  },

  methods: {
    editisMilepost(a) {
      this.isMilepost = a;
      if (this.isMilepost == 9 || this.isMilepost == 10) {
        this.mySwiper.slideTo(8);
      } else {
        this.mySwiper.slideTo(a);
      }
    },
    addisMilepost() {
      if (this.isMilepost == 11) {
        return;
      }
      this.isMilepost++;
      this.mySwiper.slideTo(this.isMilepost);
    },
    noisMilepost() {
      if (this.isMilepost == 0) {
        return;
      }

      this.isMilepost--;
      this.mySwiper.slideTo(this.isMilepost);
    },
    initSwiper() {
      let that = this;
      this.mySwiper = new Milepost(".swiper-box",{
        direction: "horizontal",

        slidesPerView: 4,
        spaceBetween: 49,
        loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //使用分页器

        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        on: {
          slideChangeTransitionStart: function () {
            that.isMilepost = this.activeIndex;
          },
        },
      });
    },
  },
  data() {
    return {
      wow: null,
      mySwiper: null,
      selectCulture: 1,
      selectLine: 1,
      isMilepost: 0,
      Culture: 17,
    };
  },
  beforeDestroy() {
    this.wow.stop();
  },

  mounted() {
    document.title = "关于我们-碧莲盛医疗";
    if (this.$route.query.id) {
      document.querySelector(`#${this.$route.query.id}`).scrollIntoView(true);
    }
    if (this.$route.params.name) {
      document.documentElement.scrollTop = 0;
    }
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    this.initSwiper();
  },
};
</script>

<style  scoped  lang="scss">
.home {
  background-color: #ffffff;

  width: 100%;

  color: #000000;
}

.right {
  width: 620px;
  margin-top: 70px;
  line-height: 30px;

  font-size: 16px;
  color: #707070;

  div {
    line-height: 30px;
    margin-top: 30px;
  }
}

.milepost {
  overflow: hidden;
  background-color: #f9fafd;
}

.date-top-body {
  width: 100%;

  img {
    width: 100%;
    border-radius: 6px;
    height: 170px;
    margin-bottom: 10px;
  }

  color: #707070;

  > div {
    line-height: 27px;
    margin-bottom: 30px;
  }
}

.date-item {
  width: 310px;
  margin-right: 40px;
  margin-top: 80px;
}

.date-top-text {
  font-size: 24px;
  color: #363636;
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: solid 2px #c8c8c8;
}

.date {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px #c8c8c8;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;

  letter-spacing: 1px;
  color: #707070;

  div {
    cursor: pointer;
    text-align: center;
    position: relative;
    padding-bottom: 26px;
  }

  div::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #c8c8c8;
  }

  .select-date::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    content: '';
    display: block;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    background-color: #2e59a7 !important;
  }

  .select-date {
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 2px;
    color: #2e59a7;
  }
}

.corporateCulture {
  background-color: #ffffff;
}

.Culture-line {
  width: 720px;
  display: flex;
  justify-content: space-between;

  font-size: 22px;
  color: #707070;

  > div {
    cursor: pointer;
    padding-top: 13px;
    width: 100%;
    border-top: solid 4px #c8c8c8;
  }
}

.select-Culture {
  color: #2e59a7;
  border-top: solid 4px #2e59a7 !important;
}

.Culture-item {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
}

.HonoraryAwards {
  background-color: #f9fafd;
  text-align: center;
}

.awards-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 72px;

  img:nth-child(4n) {
    margin-right: 0;
  }

  > div {
    width: 23%;

    transition: all 0.3s;
    // box-shadow: 8px 8px 20px 0px rgba(183, 182, 182, 0.42);

    // margin-right: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .poab {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      transform: scale(0);
      display: flex;
      justify-content: center;
      align-items: center;
      // animation: nopoabanimate 0.3s linear both;

      > div {
        > div {
          line-height: 20px;
        }
      }

      background-color: #2e59a7;
    }
  }

  > div:hover {
    transform: scale(1.05);
    transform-origin: center;
    z-index: 99;
    animation: poabshadow 0.3s linear both;

    .poab {
      animation: poabanimate 0.3s linear both;
    }
  }
}

@keyframes poabanimate {
  0% {
    transform: scale(1.5);
    opacity: 1;
    border-radius: 50%;
  }

  100% {
    transform: scale(1.5);
    opacity: 1;
    border-radius: 50%;
  }
}

.Culture-img {
  width: 716px;
  height: 470px;
}

.out-top {
  position: absolute;
  top: 200px;
  left: 150px;
  width: 2vw;
  height: 2vw;
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-right: 0.5vw;
  cursor: pointer;
}

.out-topTwo {
  position: absolute;
  top: 200px;
  right: 150px;
  cursor: pointer;
  width: 2vw;
  height: 2vw;
  border-right: 2px solid #000000;
  border-top: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-left: 0.5vw;
}

.swiper-fled {
  position: relative;
}

.CultureItem-line {
  height: 2px;
  width: 50px;
  position: absolute;
  left: 0;
  bottom: 15%;
  background-color: #c8c8c8;
}

.culture {
  margin-top: 73px;
  display: flex;
  justify-content: space-between;

  .culture-left {
    width: 585px;
    height: 700px;
    animation: aniOpacity 1s linear both;
  }

  .culture-right {
    .culture-item {
      cursor: pointer;
      border-bottom: solid 1px #b3b3b3;
      padding-bottom: 66px;
      width: 709px;

      .culture-title {
        margin-top: 65px;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #363636;
      }

      .culture-subTitle {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        margin-top: 17px;
        margin-bottom: 18px;
        text-align: justify;
        letter-spacing: 2px;
        color: #363636;
      }

      .culture-text {
        width: 709px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 0px;
        color: #707070;
      }
    }
  }
}

.BrandValues {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 364px;

  .BrandValues-item {
    margin-bottom: 25px;
    font-size: 18px;
    font-stretch: normal;
    letter-spacing: 2px;
    color: #363636;
    margin-right: 26px;
  }

  .select-BrandValues {
    color: #2e59a7;
    position: relative;
  }

  .select-BrandValues::after {
    display: block;
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
    width: 20px;
    height: 2px;
    background-color: #2660ad;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1680px) {
  .aboutUs {
    .culture {
      margin-top: 53px;
      display: flex;
      justify-content: space-between;

      .culture-left {
        width: 477px;
        height: 570px;
        animation: aniOpacity 1s linear both;
      }

      .culture-right {
        .culture-item {
          cursor: pointer;
          border-bottom: solid 1px #b3b3b3;
          padding-bottom: 66px;
          width: 623px;

          .culture-title {
            margin-top: 65px;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 2px;
            color: #363636;
          }

          .culture-subTitle {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            margin-top: 12px;
            margin-bottom: 14px;
            text-align: justify;
            letter-spacing: 2px;
            color: #363636;
          }

          .culture-text {
            width: 623px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #707070;
          }
        }
      }
    }

    .BrandValues {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 364px;

      .BrandValues-item {
        margin-bottom: 25px;
        font-size: 18px;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #363636;
        margin-right: 26px;
      }

      .select-BrandValues {
        color: #2e59a7;
        position: relative;
      }

      .select-BrandValues::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%);
        width: 20px;
        height: 2px;
        background-color: #2660ad;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .culture {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .culture-left {
      width: 407px;
      height: 487px;
      animation: aniOpacity 1s linear both;
    }

    .culture-right {
      .culture-item {
        cursor: pointer;
        border-bottom: solid 1px #b3b3b3;
        padding-bottom: 36px;
        width: 602px;

        .culture-title {
          margin-top: 40px;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 2px;
          color: #363636;
        }

        .culture-subTitle {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          margin-top: 10px;
          margin-bottom: 14px;
          text-align: justify;
          letter-spacing: 2px;
          color: #363636;
        }

        .culture-text {
          width: 602px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 0px;
          color: #707070;
        }
      }
    }
  }

  .BrandValues {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 364px;

    .BrandValues-item {
      margin-bottom: 25px;
      font-size: 18px;
      font-stretch: normal;
      letter-spacing: 2px;
      color: #363636;
      margin-right: 26px;
    }

    .select-BrandValues {
      color: #2e59a7;
      position: relative;
    }

    .select-BrandValues::after {
      display: block;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%);
      width: 20px;
      height: 2px;
      background-color: #2660ad;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .culture {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .culture-left {
      width: 407px;
      height: 487px;
      animation: aniOpacity 1s linear both;
    }

    .culture-right {
      .culture-item {
        cursor: pointer;
        border-bottom: solid 1px #b3b3b3;
        padding-bottom: 36px;
        width: 550px;

        .culture-title {
          margin-top: 40px;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 2px;
          color: #363636;
        }

        .culture-subTitle {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          margin-top: 10px;
          margin-bottom: 14px;
          text-align: justify;
          letter-spacing: 2px;
          color: #363636;
        }

        .culture-text {
          width: 602px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 0px;
          color: #707070;
        }
      }
    }
  }

  .BrandValues {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 364px;

    .BrandValues-item {
      margin-bottom: 25px;
      font-size: 18px;
      font-stretch: normal;
      letter-spacing: 2px;
      color: #363636;
      margin-right: 26px;
    }

    .select-BrandValues {
      color: #2e59a7;
      position: relative;
    }

    .select-BrandValues::after {
      display: block;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%);
      width: 20px;
      height: 2px;
      background-color: #2660ad;
    }
  }
}
</style>