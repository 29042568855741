import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import Home from '@/views/home'
import Journalism from '@/views/Journalism'
import journalismInfo1 from '@/views/journalismInfo1'
import JournalismInfo from '@/views/JournalismInfo'
import contactUs from '@/views/contactUs'
import business from '@/views/business'
import Investor from '@/views/Investor'
import responsibility from '@/views/responsibility'
import aboutUs from '@/views/aboutUs'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    redirect: '/home',
    component: Index,
    children: [{
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/journalism',
        name: 'Journalism',
        component: Journalism
      },

      {
        path: '/contactUs',
        name: 'contactUs',
        component: contactUs
      },
      {
        path: '/business',
        name: 'business',
        component: business
      },
      {
        path: '/Investor',
        name: 'Investor',
        component: Investor
      },
      {
        path: '/responsibility',
        name: 'responsibility',
        component: responsibility
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: aboutUs
      }
    ]

  },
  {
    path: '/journalismInfo',
    name: 'JournalismInfo',
    component: JournalismInfo
  },
  {
    path: '/journalismInfo1',
    name: 'journalismInfo1',
    component: journalismInfo1
  }





]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
